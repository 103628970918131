import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import InventoryItem from './InventoryItem';
import {
  fetchItems,
  getItemsCategories,
  searchItemsByString,
  searchItemsByCategoryId,
  searchItemsByStringAndOrCategoryId
} from '../../utils/GoMarketApi'; // Adjust the path as necessary
import SearchBarComponent from '../../components/Global/SearchBar/SearchBarComponent';
import GoMarketItemManagerScreen from '../../screens/GoMarket/GoMarketItemManagerScreen'; // Import the component
import GoPagination from '../Global/Pagination/GoPagination'; // Import the new pagination component
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function InventoryItemList() {
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Items per page
    const [pagination, setPagination] = useState({});

    const { id_item } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [page, searchTerm, selectedCategory]);

    const fetchData = async () => {
        setLoading(true);
        let data;
        if (searchTerm && selectedCategory) {
            data = await searchItemsByStringAndOrCategoryId(searchTerm, selectedCategory, page, limit);
        } else if (searchTerm) {
            data = await searchItemsByString(searchTerm, page, limit);
        } else if (selectedCategory) {
            data = await searchItemsByCategoryId(selectedCategory, page, limit);
        } else {
            data = await fetchItems(page, limit);
        }

        setItems(data.items);
        setPagination(data.pagination);
        setLoading(false);
    };

    useEffect(() => {
        const fetchCategories = async () => {
            const categoriesData = await getItemsCategories();
            setCategories(categoriesData);
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (!loading && id_item) {
            handleOpenModal(false, id_item);
        }
    }, [loading, id_item]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(1); // Reset to first page on new search
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setPage(1); // Reset to first page on new category
    };

    const handleOpenModal = (isNewItem = false, itemId = null) => {
        setIsNew(isNewItem);
        setSelectedItemId(itemId);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedItemId(null);
        navigate('/admin/gomarket/inventario'); // Navigate back to inventory without item id
    };

    const handleAddItem = () => {
        handleOpenModal(true);
    };

    const handleViewItem = (itemId) => {
        navigate(`/admin/gomarket/inventario/${itemId}`); // Update the URL with the item id
    };

    const updateItemList = (updatedItem) => {
        handleCategoryClick('');
        fetchData();
        toast.success(`Producto actualizado con éxito`, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const deleteItemFromList = (itemId) => {
        setItems(prevItems => prevItems.filter(item => item.id !== itemId));
        fetchData();
        toast.success(`Producto eliminado con éxito`, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const renderSkeletons = () => {
        const skeletons = [];
        for (let i = 0; i < 10; i++) {
          skeletons.push(
            <Col key={i} xs={12} sm={6} md={6} className='pb-3'>
              <Skeleton height={163} className='go-skeleton go-skeleton--card pt-3'/>
            </Col>
          );
        }
        return skeletons;
    };

    return (
        <>
            <Row>
                   <div className='go-page-title pb-4'>
                        <h4 className='go-text'><img src='/assets/icons/menu/box.svg' /> Inventario</h4>
                    </div>
                    <Col md={4} xs={12} className='pb-3 pb-md-4'>
                                <Button className='go-btn go-btn--menu w-100' onClick={handleAddItem} >
                                    <Row>
                                        {/* <Col className='col-md-3 col-12 text-center my-auto'>
                                            <div className='image-div'>
                                                <img src="/assets/icons/box.svg" alt="products" />
                                            </div>
                                        </Col> */}
                                        <Col className='col-md-12 col-12 text-start'>
                                            <h4>Agrega un producto</h4>
                                            <p>Ideal para productos nuevos que quieras vender con <span className='go-link'>GoMarket</span>.</p>
                                        </Col>
                                    </Row>
                                </Button>
                    </Col>
                    <Col md={4} xs={12} className='pb-4'>
                        <Button as={Link} to='/admin/gomarket/inventario/productos' className='go-btn go-btn--menu w-100'>
                            <Row>
                                <Col xs={12} className='text-start'>
                                    <h4>Lista de productos nacionales</h4>
                                    <p>Listado de productos nacionales con atributos ya ingresados, listos para agregar a tu <span className='go-link'>inventario</span>.</p>
                                </Col>
                            </Row>
                        </Button>
                    </Col>
            </Row>

            <Row className="mb-4">
                 
                <Col md={6}>
                    <SearchBarComponent searchTerm={searchTerm} handleSearchChange={handleSearchChange} placeholder="Busca en tu inventario" />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <div className='go-categories'>
                    <Button
                        className={selectedCategory === '' ? 'go-btn go-btn--category selected me-2' : 'go-btn go-btn--category me-2'}
                        onClick={() => handleCategoryClick('')}
                    >
                        Todos
                    </Button>
                    {categories.map(category => (
                        <Button
                            key={category.id}
                            onClick={() => handleCategoryClick(category.id)}
                            className={selectedCategory === category.id ? 'go-btn go-btn--category selected me-2' : 'go-btn go-btn--category me-2'}
                        >
                            {category.item_category_name}
                        </Button>
                    ))}
                    </div>
                </Col>
            </Row>

            {loading ? (
                <Row>
                    {renderSkeletons()}
                </Row>
            ) : (
                <>
                    
                    
                    <Row>
                        {items.map(item => (
                            <InventoryItem key={item.id} item={item} onClick={() => handleViewItem(item.id)} />
                        ))}
                    </Row>
                    <Row className="mt-3">
                        <Col className="">
                                <GoPagination
                                    page={page}
                                    totalPages={pagination.totalPages}
                                    onPageChange={handlePageChange}
                                />
                        </Col>
                    </Row>

                    {/* Modal to add or view an item */}
                    <Modal show={showModal} centered className='go-modal go-modal--edit-product' onHide={handleCloseModal} size='lg'>
                    <Modal.Header closeButton>
                    </Modal.Header>
                        <Modal.Body>
                            <GoMarketItemManagerScreen
                                isNew={isNew}
                                isModal={true}
                                id_item={selectedItemId}
                                handleCloseModal={handleCloseModal}
                                updateItemList={updateItemList}
                                deleteItemFromList={deleteItemFromList}
                            /> {/* Pass necessary props */}
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    );
}

export default InventoryItemList;

