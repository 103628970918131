import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './SpinnerOverlay.scss';

const SpinnerOverlay = () => {
    return (
        <div className="spinner-overlay">
            <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
    );
};

export default SpinnerOverlay;