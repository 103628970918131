import moment from "moment/moment";

export const courtFormatDateUtil = (date) => {
  // return moment(date).format("dddd DD MMMM YYYY"); // miércoles 13 diciembre 2023
  return moment(date).format("DD MMMM");
};

export const addDaysUtil = (date, days) => {
  return moment(date).add(days, "days");
};

export const generateDateArrayUtil = (date, addDays) => {
  const dateArray = [];
  for (let i = 0; i < addDays; i++) {
    dateArray.push({
      date: addDaysUtil(date, i),
      value: courtFormatDateUtil(addDaysUtil(date, i)),
    });
  }
  return dateArray;
};
