import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { getItemById, updateItem, deleteItem, createItem, uploadFile, getItemsCategories } from '../../utils/GoMarketApi'; // Adjust the import path as needed
import ItemForm from '../../components/GoMarket/Forms/ItemForm';
import InventoryItem from '../../components/GoMarket/InventoryItem';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Loader from '../../components/Global/Loader/Loader';


const GoMarketItemManagerScreen = ({ isNew, id_item, isModal, handleCloseModal, updateItemList, deleteItemFromList }) => {
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [formData, setFormData] = useState({
                item_name: '',
                item_brand: '',
                item_description: '',
                item_buy_price: '',
                item_sell_price: '',
                item_barcode: '',
                item_favorite: false,
                item_bulk: false,
                item_image: '',
                item_imag_id: '',
                stock: 0,
                critical_stock: 0,
                //item_category: '',
            });
    const [imageFile, setImageFile] = useState(null); // Add this state
    const [imagePreview, setImagePreview] = useState(''); // Add this state
    const [uploading, setUploading] = useState(false); // Add this state
    const [fileName, setFileName] = useState('Subir imagen'); // Add this state
    const [categories, setCategories] = useState([]); // Add this stat

            const [showConfirmDelete, setShowConfirmDelete] = useState(false);

            useEffect(() => {
                const fetchCategories = async () => {
                    const fetchedCategories = await getItemsCategories();
                    setCategories(fetchedCategories);
                };
        
                fetchCategories();

                if (!isNew && id_item) {
                    const getItem = async () => {
                        try {
                            const fetchedItem = await getItemById(id_item);
                            setItem(fetchedItem);
                            setFormData({
                                item_name: fetchedItem.item_name || '',
                                item_brand: fetchedItem.item_brand || '',
                                item_description: fetchedItem.item_description || '',
                                item_buy_price: fetchedItem.item_buy_price || '',
                                item_sell_price: fetchedItem.item_sell_price || '',
                                item_barcode: fetchedItem.item_barcode || '',
                                item_favorite: fetchedItem.item_favorite || false,
                                item_bulk: fetchedItem.item_bulk || false,
                                item_image: fetchedItem.item_image || '', // Change to item_image
                                item_image_id: fetchedItem.item_image_id || '', // Add item_image_id
                                stock: fetchedItem.stock || 0,
                                critical_stock: fetchedItem.critical_stock || 0,
                                item_category_id: fetchedItem.item_category_id || '', // Set initial category id
                                item_category_name: fetchedItem.item_category_name || '', // Set initial category name
                            });
                        
                            setLoading(false);
                        } catch (error) {
                            setError('Failed to fetch item details: ' + error.message);
                            setLoading(false);
                        }
                    };
                    getItem();
                } else {
                    setLoading(false);
                }

            }, [id_item, isNew]);

          

            const handleCheckboxChange = (e) => {
                const { name, checked } = e.target;
                setFormData({ ...formData, [name]: checked });
            };

            const handleInputChange = (e) => {
                const { name, value } = e.target;
                setFormData({ ...formData, [name]: value });
            };
            
            const handleFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    setImageFile(file);
                    setFileName(file.name); // Set the file name
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImagePreview(reader.result);
                        setFormData((prevData) => ({
                            ...prevData,
                            item_image: reader.result // Update formData with image preview
                        }));
                    };
                    reader.readAsDataURL(file);
                }
            };
            

            const handleSubmit = async (e) => {
                e.preventDefault();
                setSaving(true);
                try {
                    let itemData = {
                        item_name: formData.item_name,
                        item_brand: formData.item_brand,
                        item_description: formData.item_description,
                        item_buy_price: formData.item_buy_price || 0,
                        item_sell_price: formData.item_sell_price || 0,
                        item_barcode: formData.item_barcode,
                        item_favorite: formData.item_favorite,
                        item_bulk: formData.item_bulk,
                        item_category: formData.item_category_id, // Ensure item_category is included
                        item_stock: {
                            item_stock_stock: formData.stock || 0,
                            item_stock_critical_stock: formData.critical_stock || 0,
                        },
                        item_image: formData?.item_image || null,
                        item_image_id: formData?.item_image_id || null,
                    };
            
                    if (imageFile) {
                        setUploading(true);
                        try {
                            const folder = 'item-images';
                            const uploadResponse = await uploadFile(imageFile, folder);
            
                            if (uploadResponse && uploadResponse.length > 0) {
                                const imageId = uploadResponse[0].id;
                                itemData.item_image = imageId;
                            } else {
                                throw new Error('Upload response is empty or invalid');
                            }
                        } catch (uploadError) {
                            setError('Failed to upload image: ' + uploadError.message);
                            setUploading(false);
                            setSaving(false);
                            return;
                        } finally {
                            setUploading(false);
                        }
                    } else {
                        itemData.item_image = itemData.item_image_id;
                    }
            
                    if (isNew) {
                        const newItem = await createItem(itemData);
                        if (!newItem) {
                            throw new Error('Failed to create Item');
                        }
                        updateItemList(newItem);
                        handleCloseModal();
                    } else {
                        const updatedItem = await updateItem(id_item, itemData);
                        if (!updatedItem) {
                            throw new Error('Failed to update Item');
                        }
                        setSuccess('Item updated successfully');
                        updateItemList(updatedItem);
                        handleCloseModal();
                    }
                } catch (error) {
                    setError('Failed to save item: ' + error.message);
                } finally {
                    setSaving(false);
                }
            };
            
    
            const handleDeleteClick = () => {
                setShowConfirmDelete(true);
            };

            const handleDeleteConfirm = async () => {
                    setDeleting(true);
                try {
                    await deleteItem(id_item);
                    deleteItemFromList(id_item); // Remove the item from the list
                    handleCloseModal(); // Close the modal after deleting the item
                } catch (error) {
                    setError('Failed to delete item: ' + error.message);
                } finally {
                    setShowConfirmDelete(false);
                    setDeleting(false);
                }
            };

            const handleDeleteCancel = () => {
                setShowConfirmDelete(false);
            };

            return (
             
                    <Row >
                        {loading ? (
                            <div>
                                <Row>
                                    <Col className='col-md-12 mb-4 py-4'>
                                        <Skeleton height={164} className='mb-4 pt-4 go-skeleton go-skeleton--card' width={`100%`} />
                                        <Skeleton height={400} className='' width={`100%`} />
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <>
                                {showConfirmDelete ? (
                                    <>
                                        <h4 className=' title mb-0'>Eliminar producto</h4>
                                        <p className='pb-3 go-text'>¿Estás seguro que quieres eliminar este producto?</p>
                                        <InventoryItem key={item.id} item={formData} isModal disableClick deleting={deleting} />
                                    </>
                                ) : (
                                    <></>
                                )}

                                {!isNew && !showConfirmDelete && (
                                     <InventoryItem key={item.id} item={formData} isModal disableClick saving={saving}  />
                                )}
                                {deleting && <Loader insideModal={true} />}
                                {success && <Alert variant="success">{success}</Alert>}
                                {isNew && 
                                <div className='pb-3'>
                                <h4 className='title mb-0'>Nuevo producto</h4>
                                <p className='go-text'>Ingrese los datos correspondiente para el nuevo producto</p>
                                </div>
                                }
                                {error && <Alert variant="danger">{error}</Alert>}
                                {showConfirmDelete ? (
                                    <div >
                                        <Row>
                                            <Col className='col-md-4 ms-auto'>
                                                <Button variant="secondary" className="go-btn go-btn--form-cancel" onClick={handleDeleteCancel}>
                                                No, cancelar
                                                </Button>
                                            </Col>
                                            <Col className='col-md-4'>
                                                <Button variant="danger" className="go-btn go-btn--danger" onClick={handleDeleteConfirm}>
                                                    Sí, eliminar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    <ItemForm
                                        formData={formData}
                                        handleDelete={handleDeleteClick}
                                        handleInputChange={handleInputChange}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSubmit={handleSubmit}
                                        handleFileChange={handleFileChange} // Pass the handleFileChange function
                                        handleCloseModal={handleCloseModal} // Pass the handleCloseModal function
                                        fileName={fileName} // Pass the file name
                                        isNew={isNew}
                                        saving={saving}
                                        categories={categories} // Pass categories to ItemForm
                                    />
                                )}
                            </>
                        )}
                    </Row>
            );
        };

        export default GoMarketItemManagerScreen;
