// src/components/GoMarket/InventoryItem.js
import React from 'react';
import { Card, Row, Col, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const InventoryItem = ({ item, disableClick, isModal, saving, deleting }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        if (!disableClick) {
            navigate(`/admin/gomarket/inventario/${item.id}`);
        }
    };

    return (
        <>
            <Col className={isModal ? 'col-md-12 col-12 mb-4' : 'col-md-6 col-12 mb-4'}>
                <div className="gomarket-card gomarket-card--inventory">
                    <Card style={{ width: '100%' }} onClick={handleCardClick} className={deleting || saving ? "go-disabled": ""}>
                        <Row>
                            <Col md={3} xs={4} className='my-auto'>
                                <div className='gomarket-card--inventory-image'>
                                    <LazyLoadImage
                                        alt={item.item_name}
                                        effect="blur"
                                        src={item.item_image}
                                        className="card-img-top gomarket-card__image"
                                        />
                                </div>
                            </Col>
                            <Col md={9} xs={8} className='my-auto'>
                                <Card.Body>
                                    <Row>
                                        <Col className="col-12">
                                            <Card.Title className="gomarket-card--inventory-title">{item.item_name}</Card.Title>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-7 col-12">
                                            <Card.Text className="gomarket-card--inventory-brand">{item.item_brand}</Card.Text>
                                            <Badge className='gomarket-card__badge gomarket-card__badge--inventory'>{item.item_category_name}</Badge>
                                            <Card.Text className="gomarket-card--inventory-barcode">{item.item_barcode}</Card.Text>
                                        </Col>
                                        <Col className="col-md-5 col-12">
                                            <Card.Text className="gomarket-card--inventory-buy-price"> Costo: ${item.item_buy_price}</Card.Text>
                                            <Card.Text className="gomarket-card--inventory-sell-price"> Precio: ${item.item_sell_price}</Card.Text>

                                            <Card.Text className="gomarket-card--inventory-stock">Stock: {item.stock} </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Col>
        </>
    );
};

export default InventoryItem;
