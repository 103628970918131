import FallbackScreen from "../../screens/GoMatch/FallbackScreen";
import FallbackPayments from "../../screens/GoMatch/FallbackPayments";

// Add all fallback routes and components to this array.
// A Fallback is a Component that will be rendered if the user tries to access a route that doesn't exist or doesn't have permission to access.
export const fallbackRoutes = [
  {
    path: "/error",
    element: <FallbackScreen />,
  },
  {
    path: "/pago_no_encontrado",
    element: <FallbackPayments />,
  },
];
