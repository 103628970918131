import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

const PriceModal = ({ show, onHide, selectedPrice, handleButtonClick }) => {
    const [customPrice, setCustomPrice] = useState('');

    const handleCustomPriceChange = (e) => {
        setCustomPrice(e.target.value);
    };

    const handleCustomPriceClick = () => {
        handleButtonClick(customPrice);
    };

    return (
        <Modal show={show} onHide={onHide} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Seleccionar precio</h4>

                <Row className="pb-4 px-2">
                    {['18000', '19000', '20000', '22000', '23000', '30000'].map((price, index) => (
                        <Col xs={6} md={4} className='px-1 py-0' key={index}>
                            <Button
                                className={`go-btn go-btn-select--price ${selectedPrice === price ? 'selected' : ''}`}
                                onClick={() => handleButtonClick(price)}
                            >
                                ${price}
                            </Button>
                        </Col>
                    ))}
                </Row>

                <Row>
                    <Col xs={12}>
                    <Form.Group className="go-match-settings-input" controlId="schedulePriceModal">
                        <Form.Label>Ajustar precio manualmente</Form.Label>
                        <Form.Control
                            type="text"
                            value={customPrice}
                            onChange={handleCustomPriceChange}
                            placeholder="Definir precio"
                            className="mb-2 schedule-price"
                        />
                    </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-4'>
                        <Col xs={12} md={4} className='mb-md-0 mb-3'>
                            <Button
                                className="go-btn go-btn--close"
                                onClick={onHide}
                            >
                                Cancelar
                            </Button>
                       </Col>
                       <Col xs={12} md={8}>
                        <Button
                                className="go-btn go-btn--add"
                                onClick={handleCustomPriceClick}
                            >
                                Seleccionar precio
                            </Button>
                       </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default PriceModal;
