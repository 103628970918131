import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import { fetchUserSchedulesByDate } from "../../../utils/GoMatchApi";
import { generateDateArrayUtil } from "../../../utils/DatetimeUtils";
import { useNavigate } from "react-router-dom";

const Court = () => {
  const [schedules, setSchedules] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedScheduleId, setSelectedScheduleId] = useState(-1);
  const [selectedScheduleSlug, setSelectedScheduleSlug] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const addDays = 7;
    const generateDateArray = generateDateArrayUtil(today, addDays); // [{date: Moment(fx), value: '15 diciembre'}, ...]
    setDateArray(generateDateArray);
    setSelectedDate(generateDateArray[0]);
  }, []);

  useEffect(() => {
    const fetchSchedules = async () => {
      const userSchedules = await fetchUserSchedulesByDate(selectedDate?.date);
      setSchedules(userSchedules.data);
      setSelectedScheduleId(-1);
    };
    fetchSchedules();
  }, [selectedDate]);

  const handleClickDate = (date) => {
    setSelectedDate(date);
    setSelectedScheduleId(-1);
  };

  const handleSelectSchedule = (scheduleId, scheduleSlug) => {
    if (selectedScheduleId !== scheduleId) {
      setSelectedScheduleId(scheduleId);
      setSelectedScheduleSlug(scheduleSlug);
    } else {
      setSelectedScheduleId(-1);
    }
  };

  const handleConfirmSchedule = () => {
    console.log("selectedScheduleId", selectedScheduleId);
    navigate("/match/"+selectedScheduleSlug, { state: { selectedScheduleId, selectedScheduleSlug } });
  };

  return (
    <div>
      <div>
        <h5>¡Bienvenid@ {localStorage.getItem("name")}!</h5>
        <h5>Seleccionar día</h5>
        <Dropdown className="w-100">
          <Dropdown.Toggle
          className="w-100"
            variant="success"
            id="dropdown-basic"
            disabled={dateArray?.length > 0 ? false : true}
          >
            {selectedDate?.value}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">

            {dateArray &&
              dateArray.map((dateArray) => {
                return (
                  <Dropdown.Item
                  className="w-100"
                    onClick={() => handleClickDate(dateArray)}
                    key={dateArray.date}
                  >
                    {dateArray.value}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>
        <h5 className="py-2">Horarios</h5>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!schedules.length ? (
            <h3>No hay canchas disponibles</h3>
          ) : (
            schedules?.map((club) => {
              return (
                <div key={club.id}>
                  <h3>{club.name}</h3>
                  <div
                  >
                    {club.schedules.map((schedule) => {
                      return (
                        <Card
                        className="w-100 mb-2"
                          style={{
                            minWidth: "200px",
                            flexWrap: "wrap",
                          }}
                          bg={selectedScheduleId === schedule.id && "success"}
                          text={selectedScheduleId === schedule.id && "white"}
                          key={schedule.id}
                          onClick={() => handleSelectSchedule(schedule.id, schedule.slug)}
                        >
                          <Card.Body>
                            <Card.Title>{schedule.court_name ?? "Generic Schedule 2"}</Card.Title>
                            <Card.Text className="m-0">
                              {schedule.start_time} - {schedule.end_time}
                            </Card.Text>
                            <Card.Text>{schedule.duration}</Card.Text>
                          </Card.Body>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <button
        className="btn btn-primary w-100 mt-2"
        onClick={handleConfirmSchedule}
        disabled={selectedScheduleId === -1}
      >
        Confirmar
      </button>
    </div>
  );
};

export default Court;
