import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../../components/Global/Loader/Loader";
import { Container } from 'react-bootstrap';


export function CheckPrivateRoute({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Validate if the user has a JWT token in localStorage (atm could be any token/string)
  useEffect(() => {
    const token = localStorage.getItem("userToken");
    setIsLoggedIn(!!token);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
    <Container>
      <Loader />
    </Container>
    );
  }

  if (!isLoggedIn) {
    console.log("User is not logged in");
    return <Navigate to="/admin_login" />;
  }
  return children;
}
export default CheckPrivateRoute;
