import LoginScreen from "../../screens/GoMatch/LoginScreen";
import HomeScreen from "../../screens/GoMatch/HomeScreen";
import FeedScreen from "../../screens/GoMatch/FeedScreen";
import CourtScreen from "../../screens/GoMatch/CourtScreen";
import RegisterScreen from "../../screens/GoMatch/RegisterScreen";
import BuildingMatchScreen from "../../screens/GoMatch/BuildingMatchScreen";
import ProfileScreen from "../../screens/GoMatch/ProfileScreen";
import BookingsScreen from "../../screens/GoMatch/BookingScreen";
import PaymentScreen from "../../screens/GoMatch/PaymentScreen";
import AdminLoginScreen from "../../screens/Admin/AdminLoginScreen";
import AdminDashboardScreen from "../../screens/Admin/AdminDashboardScreen";
import AdminUserList from "../../screens/Admin/AdminUserListScreen";
import AdminConfigurationScreen from "../../screens/Admin/AdminConfigurationScreen";
import AdminEditClubScreen from "../../screens/Admin/AdminEditClubScreen";
import AdminSchedulesScreen from "../../screens/Admin/AdminSchedulesScreen";
import AdminPaymentScreen from "../../screens/Admin/AdminPaymentScreen";
import AdminStatisticsScreen from "../../screens/Admin/AdminStatisticsScreen";
import GoMarketHomeScreen from "../../screens/GoMarket/GoMarketHomeScreen";
import GoMarketInventoryScreen from "../../screens/GoMarket/GoMarketInventoryScreen";
import GoMarketProductsScreen from "../../screens/GoMarket/GoMarketProductsScreen";
import GoMarketItemManagerScreen from "../../screens/GoMarket/GoMarketItemManagerScreen"; // Import the new component
import GoMarketOrdersScreen from "../../screens/GoMarket/GoMarketOrdersScreen";
import GoMarketOrdersPendingScreen from "../../screens/GoMarket/GoMarketOrdersPendingScreen";
import GoMarketCashRegisterRecordsScreen from "../../screens/GoMarket/GoMarketCashRegisterRecordsScreen";
import AdminSchedulesNewScreen from "../../screens/Admin/AdminSchedulesNewScreen";
import AdminSchedulesScreenv2 from "../../screens/Admin/AdminSchedulesScreenv2";
import AdminScheduleSettingsScreen from "../../screens/Admin/AdminScheduleSettingsScreen";


// Add all the paths and components that are private (need authentication) to this array
export const privateAdminRoutes = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/register",
    element: <RegisterScreen />,
  },
  {
    path: "/match/:slug",
    element: <BuildingMatchScreen />,
  },
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/feed",
    element: <FeedScreen />,
  },
  {
    path: "/court",
    element: <CourtScreen />,
  },
  {
    path: "/reservas",
    element: <BookingsScreen />,
  },
  {
    path: "/payment/:slug",
    element: <PaymentScreen />,
  },
  {
    path: "/admin_login",
    element: <AdminLoginScreen />,
  },
  {
    path: "/admin_dashboard",
    element: <AdminDashboardScreen />,
  },
  {
    path: "/admin/lista_usuarios",
    element: <AdminUserList />,
  },
  {
    path: "/admin/configuracion",
    element: <AdminConfigurationScreen />,
  },
  {
    path: "/admin/configuracion/editar_club",
    element: <AdminEditClubScreen />,
  },
  // {
  //   path: "/admin/reservas",
  //   element: <AdminSchedulesScreen />,
  // },
  // {
  //   path: "/admin/reservas",
  //   element: <AdminSchedulesScreen />,
  // },
  {
    path: "/admin/gocalendar",
    element: <AdminSchedulesScreenv2 />,
  },
  {
    path: "/admin/gocalendar/:id",
    element: <AdminScheduleSettingsScreen />,
  },
  {
    path: "/admin/gocalendar/new",
    element: <AdminSchedulesNewScreen />,
  },
  {
    path: "/admin/estadisticas",
    element: <AdminStatisticsScreen />,
  },
  {
    path: "/admin/pagos",
    element: <AdminPaymentScreen />,
  },
  {
    path: "/admin/gomarket",
    element: <GoMarketHomeScreen />,
  },
  {
    path: "/admin/gomarket/inventario", 
    element: <GoMarketInventoryScreen />,
  },
  {
    path: "/admin/gomarket/inventario/productos", 
    element: <GoMarketProductsScreen />,
  },
  {
    path: "/admin/gomarket/inventario/nuevo-producto", // Add the new route for creating a new product
    element: <GoMarketItemManagerScreen isNew={true} />, // Pass the isNew prop as true
  },
  {
    path: "/admin/gomarket/inventario/:id_item", // Add the new route
    element:<GoMarketInventoryScreen />,
  },
  {
    path: "/admin/ventas", // Add the new route
    element:<GoMarketOrdersScreen />,
  },
  {
    path: "/admin/pendientes", // Add the new route
    element:<GoMarketOrdersPendingScreen />,
  },
  {
    path: "/admin/pendientes/:id_order", // Add the new route
    element:<GoMarketOrdersPendingScreen />,
  },
  {
    path: "/admin/ventas/:id_order", // Add the new route
    element:<GoMarketOrdersScreen />,
  },
  {
    path: "/admin/gomarket/historial-cajas", // Add the new route
    element:<GoMarketCashRegisterRecordsScreen />,
  },
  {
    path: "/admin/gomarket/historial-cajas/:id_record", // Add the new route
    element:<GoMarketCashRegisterRecordsScreen />,
  },
];
