import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Loader from '../../../Global/Loader/Loader';
import ScheduleItem from '../ScheduleItem';

const DeleteModal = ({ show, onHide, onDelete, deleting, schedule }) => {
    const scheduleInfo = (
        <div className={deleting ? "go-disabled" : ""}>
            <Row>
                <Col md={12}>   
                    <ScheduleItem
                        key={schedule.id}
                        id={schedule.id} 
                        schedule={schedule}
                        previewSchedule={true}
                        inModal={true}
                    />
                </Col>
            </Row>
        </div>
    );

    return (
        <Modal show={show} onHide={onHide} centered className="go-modal go-modal--delete">
            <Modal.Header closeButton />
            <Modal.Body>
                <div className='text-start'>
                    <h4 className='title'>Eliminar horario</h4>
                    <p className='go-text'>¿Estás seguro que quieres eliminar este horario?</p>
                    {scheduleInfo}
                    {deleting && <Loader insideModal={true} />}
                    <Row className={`pt-4 ${deleting ? 'go-disabled' : ''}`}>
                        <Col md={4} xs={6} className="ms-auto">
                            <Button variant="secondary" className="go-btn go-btn--form-cancel" onClick={onHide} disabled={deleting}>
                                No, cancelar
                            </Button>
                        </Col>
                        <Col md={4} xs={6}>
                            <Button variant="danger" className="go-btn go-btn--danger" onClick={onDelete} disabled={deleting}>
                                Sí, eliminar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteModal;
