import React, { useState, useEffect, Fragment } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/es.js";
import Header from "./components/Global/Header/Header";
import SideBar from "./components/Global/GoSidebar/GoSidebar";
import "./App.scss";
import ScrollUtil from "./utils/ScrollUtil";
import CheckPrivateRoute from "./utils/Routing/CheckPrivateRoute";
import CheckAdminPrivateRoute from "./utils/Routing/CheckAdminPrivateRoute";
import { publicRoutes } from "./utils/Routing/publicRoutes";
import { privateRoutes } from "./utils/Routing/privateRoutes";
import { privateAdminRoutes } from "./utils/Routing/privateAdminRoutes";
import { fallbackRoutes } from "./utils/Routing/fallbackRoutes";
import { isTokenExpired } from "./utils/Functions";
import { CashRegisterProvider } from './contexts/GoMarket/CashRegisterContext'; // Import the provider


const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isMobile;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const isMobile = useIsMobile();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // Function to update login status and check token expiration
    setIsSidebarOpen(!isMobile);
    const updateLoginStatus = () => {
      const token = localStorage.getItem("userToken");
      setIsLoggedIn(!!token);
      isTokenExpired(); // Check if the token has expired
    };

    updateLoginStatus();
    window.addEventListener("storage", updateLoginStatus);

    return () => {
      window.removeEventListener("storage", updateLoginStatus);
    };
  }, [location, isMobile]); // Dependency on location ensures check on route change

  

  const parseRouteObjects = (routes, isPrivateRoute = false, isAdminRoute = false) => {
    return routes.map((route) => ({
      path: route.path,
      element: isPrivateRoute && !isAdminRoute ? (
        <CheckPrivateRoute>{route.element}</CheckPrivateRoute>
      ) : isPrivateRoute && isAdminRoute ? (
        <CheckAdminPrivateRoute>{route.element}</CheckAdminPrivateRoute>
      ) : (
        route.element
      ),
    }));
  };

  const routes = useRoutes([
    ...parseRouteObjects(publicRoutes),
    ...parseRouteObjects(privateRoutes, true),
    ...parseRouteObjects(privateAdminRoutes, true, true),
    ...parseRouteObjects(fallbackRoutes)
  ]);

  return (
    <CashRegisterProvider>
    <Fragment>
      <ScrollUtil />
      <ToastContainer />
      {isLoggedIn && location.pathname !== "/" && <Header toggleSidebar={toggleSidebar}/>}
      {isLoggedIn && location.pathname !== "/" && <SideBar isOpen={isSidebarOpen} />}

      {routes}
    </Fragment>
    </CashRegisterProvider>
  );
}

export default App;
