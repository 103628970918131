import { useEffect } from 'react';

function useEnterKeyPress(action, condition = true) {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && condition) {
        action();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [action, condition]);
}

export default useEnterKeyPress;
