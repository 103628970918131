import React, { useState, useEffect } from 'react';
import { fetchUserBookings, deleteBooking } from '../../utils/GoMatchApi'; // Add missing import statement
import {  Container, Card, Button, Row, Col, Badge, Pagination} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../components/GoMatch/Booking/ConfirmationModal'; // Import ConfirmationModal component
import SpinnerOverlay from '../../components/GoMatch/Spinner/SpinnerOverlay'; // Import SpinnerOverlay component
import UserMatches from '../../components/GoMatch/UserMatches/UserMatches'; // Import UserMatches component


import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BookingsScreen = () => {
    const [bookings, setBookings] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    


    const fetchBookings = async (page, limit) => {
        setIsLoading(true);
        try {
            const response = await fetchUserBookings(page, limit);
            if (response.ok && response.bookings) {
                setBookings(response.bookings);
                setTotalPages(response.pagination.totalPages);
                console.log(response);
                console.log('Fetched bookings:', bookings);
                console.log('Pagination:', { currentPage, totalPages });
            } else {
                console.log('No bookings or bad response:', response);
                setBookings([]);
            }
            setIsLoading(false);
            

        } catch (error) {
            console.error('Error fetching bookings:', error);
            setIsLoading(false);
        }
    };
    
    
    
    useEffect(() => {
        fetchBookings(currentPage, 5);  // Ensure this fetches whenever currentPage changes
    }, [currentPage]);
    
    
    
    
    const handleDeleteClick = (bookingId) => {
        setIsModalOpen(true);
        setSelectedBookingId(bookingId);
    };

    const handleConfirmDelete = async () => {
        setIsModalOpen(false);
        const result = await deleteBooking(selectedBookingId);
        setIsLoading(true); 
        if (result) {
            const response = await fetchUserBookings();
            if (response) {
                setBookings(response); // Use the response directly
                console.log('Bookings:', response);
                setIsLoading(false);
            }
        }
    };

    const renderPagination = () => (
        <Pagination className="justify-content-center mt-4 pdl-pagination pagination">
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(totalPages).keys()].map(page => (
                <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => handlePageChange(page + 1)}>
                    {page + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage >= totalPages} />
        </Pagination>
    );

    const renderItemActions = (booking) => (
        <Row className=''>
            <Col className='col-12'>
            <hr></hr>
            </Col>
            <Col className='col-6'>
         
                <Button variant="btn pdl-btn pdl-btn--details pdl-btn--small" onClick={() => navigate(`/match/${booking.scheduleSlug}`)}>
                    Detalles
                </Button>
            </Col>
            <Col>
                <Button variant="btn pdl-btn pdl-btn--delete pdl-btn--small" onClick={() => handleDeleteClick(booking.bookingId)}>
                    Anular
                </Button>
            </Col>
        </Row>
    );



    if (isLoading) {
        return (
            <>
                <SpinnerOverlay />
                <Container>
                    {/* Profile card skeleton */}
                    <Card className="mb-3 pdl-card">
                        <Skeleton height={90} className='text-center pdl-section'>
                        </Skeleton>
                    </Card>

                    <Card className="mb-3 pdl-card">
                        <Skeleton height={155} className='text-center pdl-section'>
                        </Skeleton>
                    </Card>

                    <Card className="mb-3 pdl-card">
                        <Skeleton height={124} className='text-center pdl-section'>
                        </Skeleton>
                    </Card>
                
                </Container>
            </>
        );
    }

    return (
        <Container className='go-container'>

            {/* <Row className="pb-2 pdl-developing" >
                <Col className='col-12'>
                    <div className='pdl-section'>
                    <div className='pdl-section pdl-section--tournament p-0'>
                            <Row>
                                <Col>
                                    <Button className="w-100 btn btn pdl-btn pdl-btn--courts" disabled onClick={() => navigate('/court')}>
                                        Horas disponibles <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row> */}

            <div className='pdl-section'>
                <Row className='pb-1'>
                    <Col>
                        <h2 className='pdl-shared-title pb-2'>Partidos </h2>
                    </Col>
                   
                </Row>

                <Row className={`py-2`}>
                    <Col className='col-12'>
                    <UserMatches bookings={bookings} hasOwnership={true} renderItemActions={renderItemActions} />

                    {totalPages > 1 && renderPagination()}


                    </Col> 
                </Row>

            </div>

            {/* <Row className="py-2 pdl-developing" >
                <Col className='col-12'>
                    <div className='pdl-section'>
                    <h2 className='pdl-shared-title pb-2'>Mis Torneos  <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                        </div>
                    </div>
                </Col>
            </Row> */}

           
            
            <ConfirmationModal 
                show={isModalOpen} 
                onHide={() => setIsModalOpen(false)} 
                onConfirm={handleConfirmDelete}
            />
        </Container>
    );
};

export default BookingsScreen;
