import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Modal, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faTrash } from '@fortawesome/free-solid-svg-icons';
import { handleDeleteAccount, handlePasswordResetRequest, fetchSharedUserProfile, handleSaveChanges, getOrders } from '../../utils/GoMatchApi';
import ProfileForm from '../../components/GoMatch/ProfileForm/ProfileForm';
import SpinnerOverlay from '../../components/GoMatch/Spinner/SpinnerOverlay';

const EditProfileScreen = () => {
  const { username } = useParams();
  const [userData, setUserData] = useState(null);
  const [editableUserData, setEditableUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '', confirmAction: () => {} });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const userDataResponse = await fetchSharedUserProfile(username);
      if (userDataResponse && userDataResponse.id) {
        setUserData(userDataResponse);
        setEditableUserData({ ...userDataResponse });
        setUserId(userDataResponse.id);
      } else {
        toast.error('Usuario no encontrado.');
        navigate('/admin_login');
      }
      setIsLoading(false);
    };
    fetchData();
  }, [username, navigate]);

  const ConfirmationModal = ({ show, handleClose, handleConfirm, title, body }) => (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="pdl-btn" onClick={handleClose}>Cancelar</Button>
        <Button variant="primary" className="pdl-btn" onClick={handleConfirm}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  );

  const showModal = (title, body, confirmAction) => {
    setModalInfo({ show: true, title, body, confirmAction });
  };

  const handlePasswordChangeRequest = async () => {
    const result = await handlePasswordResetRequest(userData.email);
    if (result.ok) {
      toast.success('Se ha enviado un correo con las instrucciones para cambiar tu contraseña.');
    } else {
      toast.error('Error al enviar el correo.');
    }
  };

  const handleLoadingOrder = async () => {
    const result = await getOrders();
    if (result.ok) {
      toast.success('Carga generada en los logs.');
      console.log(result);
      let csvContent = "id,club,fecha,hora,monto,estado,moneda,origen,monto_efectivo,vuelto,reservas,productos\n";

      result.data.forEach(order => {
        const reservas = Array.isArray(order.reservas)
        ? order.reservas.map(reserva => {
            return `id: ${reserva.id}, precio: ${reserva.precio}, fecha: ${reserva.fecha}, hora_inicio: ${reserva.hora_inicio}, hora_fin: ${reserva.hora_fin}, usuario: ${reserva.usuario}, apellido: ${reserva.apellido}, cancha: ${reserva.cancha}, duracion_text: ${reserva.duracion_text}`;
          }).join('; ')
        : 'N/A';

        const productos = Array.isArray(order.productos)
        ? order.productos.map(producto => {
            return `id: ${producto.id}, nombre: ${producto.nombre}, marca: ${producto.marca}, precio: ${producto.precio}, cantidad: ${producto.cantidad}`;
          }).join('; ')
        : 'N/A';
        csvContent += `${order.id},${order.club},${order.fecha},${order.hora},${order.monto},${order.estado},${order.moneda},${order.origen},${order.monto_efectivo},${order.vuelto},${reservas},${productos}\n`;
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } else {
      toast.error('Error al enviar el correo.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/admin_login');
  };

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <Container className='go-container'>
      <ProfileForm editableUserData={editableUserData} setEditableUserData={setEditableUserData} handleSaveChanges={handleSaveChanges} onUserUpdated={setUserData} />
      <Row className="py-2">
        <Col className='col-12'>
          <div className='pdl-section mb-3'>
            <div className="w-100 mb-2 pdl-accent-div pdl-accent-div--primary" onClick={() => showModal('Cambiar contraseña', '¿Estás seguro de que quieres cambiar tu contraseña? Se enviará un correo electrónico con las instrucciones.', handlePasswordChangeRequest)}>
              <Row>
                <Col className="col-2 my-auto text-center"><FontAwesomeIcon icon={faShieldHalved} /></Col>
                <Col className="col-10">
                  <p className='pdl-accent-div--title'>Cambiar contraseña</p>
                  <p className='pdl-accent-div--info'>Te enviaremos un correo electrónico con tu cambio de contraseña.</p>
                </Col>
              </Row>
            </div>
            <div className="w-100 mb-2 pdl-accent-div pdl-accent-div--primary" onClick={() => handleLoadingOrder()}>
              <Row>
                <Col className="col-2 my-auto text-center"><FontAwesomeIcon icon={faShieldHalved} /></Col>
                <Col className="col-10">
                  <p className='pdl-accent-div--title'>Probar carga (Temporal)</p>
                  <p className='pdl-accent-div--info'>Prueba de carga velocidad de datos para traer todas las órdenes históricas.</p>
                </Col>
              </Row>
            </div>
            {/* <div className="w-100 mt-2 pdl-accent-div pdl-accent-div--primary" onClick={() => showModal('Cerrar sesión', '¿Estás seguro de que quieres cerrar sesión?', handleLogout)}>
              <Row>
                <Col className="col-2 my-auto text-center"><FontAwesomeIcon icon={faPowerOff} /></Col>
                <Col className="col-10">
                  <p className='pdl-accent-div--title'>Cerrar sesión</p>
                  <p className='pdl-accent-div--info'>¡Hasta la próxima!</p>
                </Col>
              </Row>
            </div> */}
          </div>
          <div className='pdl-section my-3'>
              <div className="w-100 pdl-accent-div pdl-accent-div--danger" onClick={() => showModal('Eliminar cuenta', '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción es irreversible.', () => handleDeleteAccount(userId, navigate))}>
                <Row>
                  <Col className="col-2 my-auto text-center"><FontAwesomeIcon icon={faTrash} /></Col>
                  <Col className="col-10">
                    <p className='pdl-accent-div--title'>Eliminar cuenta GoMatch</p>
                    <p className='pdl-accent-div--info'>Esta acción borrará todos los datos de esta cuenta de manera permanente.</p>
                  </Col>
                </Row>
              </div>
          </div>
        </Col>
      </Row>
      <ConfirmationModal show={modalInfo.show} handleClose={() => setModalInfo({ ...modalInfo, show: false })} handleConfirm={() => { modalInfo.confirmAction(); setModalInfo({ ...modalInfo, show: false }); }} title={modalInfo.title} body={modalInfo.body} />
    </Container>
  );
};

export default EditProfileScreen;
