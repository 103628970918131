import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoginForm from '../../components/GoMatch/LoginForm/LoginForm';

function LoginScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const registeredEmail = location.state?.registeredEmail;

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      navigate('/feed');
    }
  }, [navigate]);

  return (
    <div className='container'>
      <h1 className='pdl-title-main'>Ingresar</h1>
      <LoginForm registeredEmail={registeredEmail} />
    </div>
  );
}

export default LoginScreen;

