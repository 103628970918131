import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchOrders } from '../../utils/GoMarketApi'; // Adjust the import path as necessary
import OrderList from '../../components/GoMarket/OrderList'; // Adjust the import path as necessary
import moment from 'moment';
import GoPagination from '../../components/Global/Pagination/GoPagination'; // Import the new pagination component
import "react-toastify/dist/ReactToastify.css";

const GoMarketOrdersScreen = () => {
  const [ordersData, setOrdersData] = useState({ orders: [], pagination: {}, sorting: {}, filters: {} });
  const [dateSort, setDateSort] = useState('desc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('Pagado');
  const [loading, setLoading] = useState(false);

  const loadOrders = async () => {

    setLoading(true);
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
    const fetchedOrdersData = await fetchOrders(dateSort, limit, page, formattedStartDate, formattedEndDate, status);
    setOrdersData(fetchedOrdersData);
    setLoading(false)
  };

  useEffect(() => {
    loadOrders();
  }, [dateSort, limit, page, startDate, endDate, status]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Container className='go-container'>
   

      <div className="go-market-orders-screen">
     
        <>
        <OrderList
          orders={ordersData.orders}
          pagination={ordersData.pagination}
          setDateSort={setDateSort}
          setLimit={setLimit}
          setPage={setPage}  // Pass setPage as a prop
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStatus={setStatus}
          reloadOrders={loadOrders}  // Pass the loadOrders function
          screenLoading={loading}
        />
          <Row>
            <Col>
              <GoPagination
                page={page}
                totalPages={ordersData.pagination.totalPages || 1}
                onPageChange={handlePageChange}
              />
            </Col>
          </Row>
        </>
     
      </div>
 
    </Container>
  );
};

export default GoMarketOrdersScreen;
