import React, { useState, useEffect, useRef, useContext   } from 'react';
import moment, { duration } from 'moment';
import { Button, Col, Row, Container, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation, useParams, Link} from 'react-router-dom';
import { mapFrontendToBackend, mapBackendToFrontend, mapBackendToFrontendUser } from '../../utils/Functions';
import { createSchedule, findScheduleById, deleteScheduleById } from '../../utils/GoMatchApi';
import CategoryModal from '../../components/Admin/AdminSchedulesNew/Modals/CategoryModal';
import PublicTypeModal from '../../components/Admin/AdminSchedulesNew/Modals/PublicTypeModal';
import PriceModal from '../../components/Admin/AdminSchedulesNew/Modals/PriceModal';
import PaymentStatusModal from '../../components/Admin/AdminSchedulesNew/Modals/PaymentStatusModal';
import WhoPaysModal from '../../components/Admin/AdminSchedulesNew/Modals/WhoPaysModal'; // Import the new modal
import BookingsModal from '../../components/Admin/AdminSchedulesNew/Modals/BookingsModal';
import DeleteModal from '../../components/Admin/AdminSchedulesNew/Modals/DeleteModal.js';
import { possible_schedule_durations } from '../../utils/Constants.js';
import { createShareText, copyToClipboard } from '../../utils/Share.js'; // Import the utility functions
import Loader from '../../components/Global/Loader/Loader';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './AdminScheduleSettingsScreen.scss';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CashRegisterContext } from '../../contexts/GoMarket/CashRegisterContext';
import useEnterKeyPress from '../../hooks/useEnterKeyPress.js';

const AdminScheduleSettingsScreen = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [categoryModalVisible, setCategoryModalVisible] = useState(false);
    const [publicModalVisible, setPublicModalVisible] = useState(false);
    const [priceModalVisible, setPriceModalVisible] = useState(false);
    const [paymentStatusModalVisible, setPaymentStatusModalVisible] = useState(false);
    const [bookingsModalVisible, setBookingsModalVisible] = useState(false);
    const [whoPaysModalVisible, setWhoPaysModalVisible] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false); // Add this line
    const [deleting, setDeleting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedInput, setSelectedInput] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('6ta');
    const [selectedPublicType, setSelectedPublicType] = useState('');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [selectedPrice, setSelectedPrice] = useState('');
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [selectedPayer, setSelectedPayer] = useState(null); // Add this state for the selected payer
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [fullUserList, setFullUserList] = useState([]); // Add this line

    const scheduleRef = useRef(null);
    const navigate = useNavigate();
    const previuslySelectedDate = useLocation().state?.selectedDate || new Date();
    const { state } = useLocation();
    const { id } = useParams();
    const selectedDurationInt = possible_schedule_durations.find(duration => duration.id === state?.selectedDuration)?.duration_int || 90;

    const scheduleId = state?.schedule?.id || id;

    const startsWithGhost = (id) => {
        return typeof id === 'string' && id.substring(0, 5) === 'ghost';
    };
    
    const paymentStatusMap = {
        p0: 'GoMatch',
        p50: 'Abonado',
        p100: 'Pagado',
        pc: 'Clases',
        pb: 'Bloqueado',
        pr: 'Reservado'
    };

    const { isRegisterOpen, cashRegisters, currentUserId, refreshCashRegisters } = useContext(CashRegisterContext);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        date: state?.schedule?.date || '',
        start_time: state?.schedule?.start_time || '',
        end_time: '',
        price: state?.schedule?.price || '',
        court: state?.schedule.court?.id || '',
        comment: '',
        available: true,
        fully_booked: false,
        admin_match_confirmed: false,
        hide: false,
        email_sent: false,
        category: '6ta',
        payment_status: state?.schedule.payment_status || "GoMatch",
        bookings: [],
        total_slots: state?.schedule?.court?.total_slots || 0,
        duration: state?.selectedDuration || 5,
        is_ghost: startsWithGhost(scheduleId),
        courtName: state?.schedule?.court?.name || '',  // Add this line
        clubName: state?.schedule?.club?.name || '',  // Add this line
        clubAddress: state?.schedule?.club?.address || '' , // Add this line
        duration: state?.selectedDuration || 5,
    });

    useEffect(() => {
        const currentRegisterOpen = cashRegisters.some(cr => cr.users_permissions_user.id === currentUserId && cr.cash_register_open);
        const shouldDisableButton = (!currentRegisterOpen && (formData.payment_status === 'Pagado' || formData.payment_status === 'Abonado')) || (formData.bookings.length === 0 && formData.payment_status !== 'Bloqueado');
        setIsButtonDisabled(shouldDisableButton);
    }, [cashRegisters, currentUserId, formData.bookings.length, formData.payment_status]);
      


    useEffect(() => {
        const fetchSchedule = async () => {
        setIsLoading(true); // Start loading
        if (scheduleId && !startsWithGhost(scheduleId)) {
            const response = await findScheduleById(scheduleId);
            if (response.ok) {
                const schedule = response.data;
                const newSelectedBookings = schedule.bookings.map(booking => ({
                    bookingId: booking.id,
                    userId: booking.user_id,
                    name:  booking.user_name, 
                    lastname: booking.user_lastname,
                    phone: booking.user_phone,
                    email: booking.user_email,
                    padel_league: booking.user_padel_league || 'Sin categoría',
                    profile_picture: booking.user_profile_picture || '',
                    payment_percentage: booking.payment_percentage,
                    is_guest: booking.is_guest,
                })) || [];

                // Determine the payment_status based on all bookings
                let determinedPaymentStatus;
                if (newSelectedBookings.some(booking => booking.payment_percentage === 'p100')) {
                    determinedPaymentStatus = 'Pagado';
                } else if (newSelectedBookings.some(booking => booking.payment_percentage === 'p50')) {
                    determinedPaymentStatus = 'Abonado';
                } else if (newSelectedBookings.some(booking => booking.payment_percentage === 'pc')) {
                    determinedPaymentStatus = 'Clases';
                } else if (newSelectedBookings.some(booking => booking.payment_percentage === 'pb')) {
                    determinedPaymentStatus = 'Bloqueado';
                } else if (newSelectedBookings.some(booking => booking.payment_percentage === 'pr')) {
                    determinedPaymentStatus = 'Reservado';
                }else {
                    determinedPaymentStatus = 'GoMatch';
                }

                setFormData({
                    ...formData,
                    name: schedule.name || '',
                    date: schedule.date || '',
                    start_time: schedule.start_time || '',
                    end_time: schedule.end_time || '',
                    price: schedule.price || '',
                    court: schedule.court?.id || '',
                    comment: schedule.comment || '',
                    available: schedule.available || false,
                    fully_booked: schedule.fully_booked || false,
                    admin_match_confirmed: schedule.admin_match_confirmed || false,
                    hide: schedule.hide || false,
                    email_sent: schedule.email_sent || false,
                    category: mapBackendToFrontend(schedule.category) || '6ta',
                    payment_status: determinedPaymentStatus,
                    bookings: newSelectedBookings,
                    total_slots: schedule.court?.total_slots || 0,
                    duration: schedule.duration || 5,
                    is_ghost: startsWithGhost(scheduleId),
                    courtName: schedule.court?.name || 'undefined',
                    clubName: schedule.club?.name || 'undefined',
                    clubAddress: schedule.club?.address || 'undefined'
                });

                setSelectedBookings(newSelectedBookings);
                setFullUserList(newSelectedBookings); // Ensure full details are retained
                setSelectedPaymentStatus(determinedPaymentStatus);
                setSelectedPublicType(schedule.available);
                setSelectedCategory(schedule.category);

                const payer = newSelectedBookings.find(booking => 
                    booking.payment_percentage === 'p100' || 
                    booking.payment_percentage === 'p50' || 
                    booking.payment_percentage === 'pc' || 
                    booking.payment_percentage === 'pb' || 
                    booking.payment_percentage === 'pr'
                );
                if (payer) {
                    setSelectedPayer(payer);
                    setSelectedCategory(mapBackendToFrontendUser(payer.padel_league));
                } else if (newSelectedBookings.length > 0) {
                    setSelectedPayer(newSelectedBookings[0]);
                    setSelectedCategory(mapBackendToFrontendUser(newSelectedBookings[0].padel_league));
                }
            }
        }
        setIsLoading(false); // End loading
    };

    fetchSchedule();
    }, [scheduleId]);

    useEffect(() => {
        const existingPayer = formData.bookings.find(booking => 
            booking.payment_percentage === 'p50' || 
            booking.payment_percentage === 'p100' || 
            booking.payment_percentage === 'pc' || 
            booking.payment_percentage === 'pb' ||
            booking.payment_percentage === 'pr'
        );
        if (!existingPayer && formData.bookings.length > 0) {
            const firstNonGuestBooking = formData.bookings.find(booking => !booking.is_guest);
            if (firstNonGuestBooking) {
                // Only update if selectedPayer is different
                if (!selectedPayer || selectedPayer.userId !== firstNonGuestBooking.userId) {
                    handleWhoPaysButtonClick(firstNonGuestBooking);
                }
            }
        }

    }, [formData.bookings, selectedPayer]);


    const handleInputClick = (inputName) => {
        setSelectedInput(inputName);
        if (inputName === 'category') {
            setCategoryModalVisible(true);
        } else if (inputName === 'publicType') {
            setPublicModalVisible(true);
        } else if (inputName === 'price') {
            setPriceModalVisible(true);
        } else if (inputName === 'paymentStatus') {
            setPaymentStatusModalVisible(true);
        } else if (inputName === 'bookings') {
            setBookingsModalVisible(true);
        }
    };

    const handleCategoryButtonClick = (value) => {
        setFormData({
            ...formData,
            category: value,
        });
        setSelectedCategory(value);
        setCategoryModalVisible(false);
    };
    
    const handlePublicTypeButtonClick = (value) => {
        setFormData({
            ...formData,
            available: value,
        });
        setSelectedPublicType(value);
        setPublicModalVisible(false);
    };
    
    const handlePriceButtonClick = (value) => {
        setFormData({
            ...formData,
            price: value,
        });
        setSelectedPrice(value);
        setPriceModalVisible(false);
    };

    const handleBookingsButtonClick = (value) => {
        const updatedBookings = value.map(user => ({
            userId: user.userId, // Ensure correct userId for both users and guests
            name: user.name,
            lastname: user.lastname,
            email: user.email,
            profile_picture: user.profile_picture?.formats?.thumbnail?.url || '',
            payment_percentage: 'p0', // Default to p0
            is_guest: user.is_guest || false, // Ensure correct is_guest value
            padel_league: mapBackendToFrontendUser(user.padel_league) || 'Sin categoría' // Ensure padel_league is included
        }));
    
        if (updatedBookings.length === 0) {
            setSelectedPayer(null);
            setFormData({
                ...formData,
                bookings: updatedBookings,
            });
        } else {
            let hasAppliedPayment = false;
            let payment_percentage_value = 'p0';
    
            switch (formData.payment_status) {
                case 'Pagado':
                    payment_percentage_value = 'p100';
                    break;
                case 'Abonado':
                    payment_percentage_value = 'p50';
                    break;
                case 'Clases':
                    payment_percentage_value = 'pc';
                    break;
                case 'Bloqueado':
                    payment_percentage_value = 'pb';
                    break;
                case 'Reservado':
                    payment_percentage_value = 'pr';
                    break;
                case 'GoMatch':
                default:
                    payment_percentage_value = 'p0';
            }
    
            const updatedBookingsWithPayment = updatedBookings.map(booking => {
                if ((booking.userId === value[0].userId || booking.userId === value[0].id) && !hasAppliedPayment) {
                    hasAppliedPayment = true;
                    return {
                        ...booking,
                        payment_percentage: payment_percentage_value
                    };
                } else {
                    return {
                        ...booking,
                        payment_percentage: 'p0'
                    };
                }
            });
    
            setFormData({
                ...formData,
                bookings: updatedBookingsWithPayment,
            });

            setSelectedPayer(value[0]);
        }
    
        setSelectedBookings(value);
    };
    
    const handleWhoPaysButtonClick = (user) => {
        setSelectedPayer(user);
    
        let payment_percentage;
        switch (formData.payment_status) {
            case 'Pagado':
                payment_percentage = 'p100';
                break;
            case 'Abonado':
                payment_percentage = 'p50';
                break;
            case 'Clases':
                payment_percentage = 'pc';
                break;
            case 'Bloqueado':
                payment_percentage = 'pb';
                break;
            case 'Reservado':
                payment_percentage = 'pr';
                break;
            case 'GoMatch':
            default:
                payment_percentage = 'p0';
        }
    
        let hasAppliedPayment = false;
    
        const newBookings = formData.bookings.map(booking => {
            if ((booking.userId === user.userId || booking.userId === user.id) && !hasAppliedPayment) {
                hasAppliedPayment = true;
                return {
                    ...booking,
                    payment_percentage: payment_percentage
                };
            } else {
                return {
                    ...booking,
                    payment_percentage: 'p0'
                };
            }
        });
    
        const newCategory = mapBackendToFrontendUser(user.padel_league) || '6ta';
    
        setFormData({
            ...formData,
            bookings: newBookings,
            category: newCategory
        });
    
        setSelectedCategory(newCategory);
        setWhoPaysModalVisible(false);
    };
    
    const handlePaymentStatusButtonClick = (value) => {
        let payment_percentage_value;
        let newPublicType;
        const currentRegisterOpen = cashRegisters.some(cr => cr.users_permissions_user.id === currentUserId && cr.cash_register_open);

        switch (value) {
            case 'p100':
                payment_percentage_value = 'p100';
                newPublicType = false;
                if(!currentRegisterOpen){
                    toast.error('No puedes seleccionar este tipo de reserva si no tienes una caja abierta');
                }
                break;
            case 'p50':
                payment_percentage_value = 'p50';
                newPublicType = false;
                if(!currentRegisterOpen){
                    toast.error('No puedes seleccionar este tipo de reserva si no tienes una caja abierta');
                }
                break;
            case 'pc':
                payment_percentage_value = 'pc';
                newPublicType = false;
                break;
            case 'pb':
                payment_percentage_value = 'pb';
                newPublicType = false;
                break;
            case 'pr':
                payment_percentage_value = 'pr';
                newPublicType = false;
                break;
            case 'p0':
            default:
                payment_percentage_value = 'p0';
                newPublicType = true;
        }
    
        let newBookings = [...formData.bookings];
        
        if (selectedPayer) {
            let hasAppliedPayment = false;
    
            newBookings = newBookings.map(booking => {
                if ((booking.userId === selectedPayer.userId || booking.userId === selectedPayer.id) && !hasAppliedPayment) {
                    hasAppliedPayment = true;
                    return {
                        ...booking,
                        payment_percentage: payment_percentage_value
                    };
                }
                return {
                    ...booking,
                    payment_percentage: 'p0'
                };
            });
        }
    
        // Clear bookings if payment status is "Bloqueado"
        if (value === 'pb') {
            newBookings = [];
            setSelectedBookings([]);
        }
    
        setFormData({
            ...formData,
            payment_status: paymentStatusMap[value],
            bookings: newBookings,
            available: newPublicType // Update public type
        });
    
        setSelectedPaymentStatus(paymentStatusMap[value]);
        setSelectedPublicType(newPublicType); // Update public type
        setPaymentStatusModalVisible(false);
    };

    // Function to update the payer and reset other payment percentages to 'p0'
    // const updatePayerAndResetPayments = (payer, bookings, payment_status) => {
    //     let payment_percentage_value = 'p0';
    
    //     switch (payment_status) {
    //         case 'Pagado':
    //             payment_percentage_value = 'p100';
    //             break;
    //         case 'Abonado':
    //             payment_percentage_value = 'p50';
    //             break;
    //         case 'Clases':
    //             payment_percentage_value = 'pc';
    //             break;
    //         case 'Bloqueado':
    //             payment_percentage_value = 'pb';
    //             break;
    //         case 'Reservado':
    //             payment_percentage_value = 'pr';
    //             break;
    //         case 'GoMatch':
    //         default:
    //             payment_percentage_value = 'p0';
    //     }
    
    //     return bookings.map(booking => ({
    //         ...booking,
    //         payment_percentage: (booking.userId === payer.userId || booking.userId === payer.id) ? payment_percentage_value : 'p0'
    //     }));
    // };
    
    const handleDelete = async () => {
        try {
            setDeleting(true);
            const response = await deleteScheduleById(scheduleId);
            if (response.ok) {
                toast.success('Horario eliminado con éxito.');
                navigate('/admin/gocalendar', { state: { previuslySelectedDate } });
            } else {
                console.error('Failed to delete schedule:', response.data);
                toast.error('Error al eliminar el horario.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error al eliminar el horario.');
        } finally {
            setShowConfirm(false);
            setDeleting(false);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSwitchChange = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    const createNewSchedule = async () => {
        if (isButtonDisabled) return; // Previene la acción si el botón está deshabilitado
    
        setIsSubmitting(true); // Add this line to start showing the loader and disable buttons
        const formattedBookings = formData.bookings.map(booking => ({
            userId: booking.userId,
            payment_percentage: booking.payment_percentage,
            is_guest: booking.is_guest,
        }));

        const getRegisterOpenByCurrentUser = cashRegisters.find(cr => cr.users_permissions_user.id === currentUserId && cr.cash_register_open);

    
        let data;
        const blockedScheduleBooking = {
            userId: localStorage.getItem("id"),
            payment_percentage: 'pb',
            is_guest: true,
        };
    
        if (startsWithGhost(scheduleId)) {
            data = {
                date: formData.date,
                court: formData.court,
                duration: formData.duration,
                start_time: formData.start_time,
                available: formData.available,
                comment: formData.comment,
                bookings: formData.payment_status === 'Bloqueado' ? [blockedScheduleBooking] : formattedBookings,
                email_sending: isSwitchOn,
                price: formData.price,
                category: mapFrontendToBackend(formData.category),
                cash_register: getRegisterOpenByCurrentUser?.id || null,
                reservation_type: formData.payment_status
            };
        } else {
            data = {
                id: scheduleId,
                available: formData.available,
                comment: formData.comment,
                bookings: formData.payment_status === 'Bloqueado' ? [blockedScheduleBooking] : formattedBookings,
                email_sending: isSwitchOn,
                price: formData.price,
                category: mapFrontendToBackend(formData.category),
                cash_register: getRegisterOpenByCurrentUser?.id || null,
                reservation_type: formData.payment_status
            };
        }
    
        const response = await createSchedule(data);
        setIsSubmitting(false); // Add this line to stop showing the loader and re-enable buttons
        if (response.ok) {
            if (startsWithGhost(scheduleId)) {
                toast.success('Partido creado correctamente');
                navigate('/admin/gocalendar', { state: { previuslySelectedDate, scrollToId: response.data.id } });
            } else {
                toast.success('Partido actualizado correctamente');
                navigate('/admin/gocalendar', { state: { previuslySelectedDate, scrollToId: state?.schedule.id } });
            }
            if(formData.payment_status === 'GoMatch' || formData.payment_status === 'Abonado' || formData.payment_status === 'Clases' || formData.payment_status === 'Reservado'){
                refreshCashRegisters();
            }
        } else {
            console.error("Failed to create schedule:", response.error || response.data);
            toast.error('Error al crear el partido');
        }
    };

    useEnterKeyPress(() => {
        if (!isButtonDisabled) {
          createNewSchedule();
        }
      }, !categoryModalVisible && !publicModalVisible && !priceModalVisible && !paymentStatusModalVisible && !bookingsModalVisible && !whoPaysModalVisible);
      
    
    
    useEffect(() => {
        if (state?.scrollToId) {
            const element = document.getElementById(state.scrollToId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [state?.scrollToId]);

    const handleShareClick = (formData) => {
        const shareText = createShareText(formData, formData.category);
        copyToClipboard(shareText);
    };


    const renderSkeletons = () => {
        return (
            <Container className='go-container go-skeleton'>
                <Row>
                    <div className='go-page-title'>
                        <h4 className="title mb-0 go-text pb-4"> <LazyLoadImage effect='blur' src='/assets/icons/menu/calendar.svg' alt="Calendar" /> Reservar Horario</h4>
                    </div>
                    <Col xs={12} >
                        <Skeleton height={24} className='go-skeleton-input mb-3'/>
                        <Skeleton height={84}  className='go-skeleton-input mb-2'/>
                    </Col>
    
                    <Col md={12} xs={12}>
                        <Skeleton height={361} className='go-skeleton-input mt-2 mb-3'/>
                    </Col>
                    
                </Row>
                <Row className="mt-4 justify-content-end">
                    {startsWithGhost(scheduleId) ? null : (
                    <Col xs={12} md={3} className='mb-3 '>
                        <Button className='go-btn go-btn--delete' onClick={() => setShowConfirm(true)}> 
                            Eliminar
                        </Button>
                    </Col>
                    )}

                <Col xs={12} md={3} className="mb-3">
                    <Link to="/admin/gocalendar" state={{ previuslySelectedDate, scrollToId: state?.schedule.id }} className="go-btn go-btn--close btn btn-primary link">
                        Volver
                    </Link>
                </Col>
                <Col xs={12} md={3}>
                    <Button className="go-btn go-btn--add go-disabled" onClick={createNewSchedule}>
                        {startsWithGhost(scheduleId) ? 'Crear Reserva' : 'Guardar cambios'}
                    </Button>
                </Col>
            </Row>
            </Container>
        );
    };

    if (isLoading) {
       return renderSkeletons();
    }
      
    return (
        <>
        
        
        <Container className={`go-container`}>
        {isSubmitting && <Loader fullScreen={true}/>} 
            <Row className={isSubmitting ? 'go-disabled' : ''}>
                <div className='go-page-title'>
                    <h4 className="title mb-0 go-text pb-4"> <LazyLoadImage effect='blur' src='/assets/icons/menu/calendar.svg' alt="Calendar" /> Reservar Horario</h4>
                </div>

                {/* Info */}
                <Col xs={12} className="mb-3">
                    <p className="go-text">Completa los siguientes datos para configurar el partido</p>
                    <div className="go-alert go-alert--success">
                        <Row>
                            <Col md={1} xs={2} className="my-auto text-center">
                                <LazyLoadImage effect='blur' src="/assets/icons/schedule.svg" className="schedule" alt="schedule" />
                            </Col>
                            <Col md={11} xs={10} className="my-auto">
                                <p className="mb-0 go-text">Actualmente estas reservando en <b className="go-link">{formData.courtName}, {moment(formData.date).format('dddd')} {moment(formData.date).format('LL')}</b> a las <b className="go-link">{formData.start_time} Hrs</b> por <b className='go-link'>{selectedDurationInt}</b> minutos</p>
                            </Col>
                        </Row>
                    </div>
                </Col>

                {/*  Tipo de reserva */}
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3 go-match-settings-input" controlId="schedulePaymentStatus">
                        <Form.Label>Tipo de reserva</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly
                            value={formData.payment_status}
                            placeholder="Seleccione el tipo de reserva"
                            className="schedule-payment-status"
                            onClick={() => handleInputClick('paymentStatus')}
                        />
                    </Form.Group>
                </Col>

                {/* Seleccionar Jugadores */}
                <Col md={4} xs={12}>
                <Form.Group className={`mb-3 go-match-settings-input ${formData.payment_status === "Bloqueado" ? "go-disabled" : ""}`} controlId="scheduleBookings">

                        <Form.Label>Seleccionar Jugadores</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly
                            value={`${selectedBookings.length} / ${formData.total_slots} Jugadores`}
                            placeholder={`${selectedBookings.length} / ${formData.total_slots} Jugadores`}
                            className="schedule-bookings"
                            onClick={() => handleInputClick('bookings')}
                        />
                    </Form.Group>
                </Col>

                {/* Seleccionar quien paga */}
                <Col md={4} xs={12}>
                <Form.Group className={`mb-3 go-match-settings-input ${(formData.bookings.length < 1 || formData.payment_status === "GoMatch" || formData.payment_status === "Bloqueado") ? "go-disabled" : ""}`} controlId="scheduleWhoPays">

                        <Form.Label>Pagador</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly
                            value={selectedPayer ? (selectedPayer.is_guest ? "Invitado" : `${selectedPayer.name} ${selectedPayer.lastname}`) : 'Seleccionar pagador'}
                            placeholder="Seleccionar pagador"
                            className="schedule-who-pays"
                            onClick={() => setWhoPaysModalVisible(true)}
                        />
                    </Form.Group>
                </Col>

                {/* Categoria */}
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3 go-match-settings-input" controlId="scheduleCategory">
                        <Form.Label>Categoría del Partido</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly
                            value={formData.category}
                            placeholder="Categoría del Partido"
                            className="schedule-category"
                            onClick={() => handleInputClick('category')}
                        />
                    </Form.Group>
                </Col>

                {/* Precio */}
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3 go-match-settings-input" controlId="schedulePrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            readOnly
                            value={formData.price}
                            placeholder="Precio"
                            className="schedule-price"
                            onClick={() => handleInputClick('price')}
                        />
                    </Form.Group>
                </Col>

                {/* Comentarios */}
                <Col md={12} xs={12}>
                    <Form.Group className="mb-3 go-match-settings-input" controlId="scheduleComment">
                        <Form.Label>Escribir comentarios (opcional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="comment"
                            value={formData.comment}
                            onChange={handleInputChange}
                            placeholder="Escribir comentarios"
                            className="text-area"
                        />
                    </Form.Group>
                </Col>

                {/* Enviar correo a jugadores para notificarlos email_sending */}
                <Col md={6} xs={12} className='mb-3'>
                
                    <Button className='go-btn go-btn--info' onClick={handleSwitchChange}>
                      
                        <Row>
                            <Col md={10} xs={9}>
                                <LazyLoadImage effect='blur' src='/assets/icons/notification.svg' alt='Notification Icon'/> Enviar correo a jugadores para notificarlos
                            </Col>
                            <Col md={2} xs={3} className='text-end my-auto'>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="sendEmailSwitch"
                                        checked={isSwitchOn}
                                        onChange={() => setIsSwitchOn(!isSwitchOn)}
                                   
                                    />
                                </Form>
                            </Col>
                        </Row>
                    </Button>
                </Col>

                {/* Copiar enlace del partido */}
                <Col md={6} xs={12}>
                    <Button className={`go-btn go-btn--info ${formData?.is_ghost ? "go-disabled" : ""}`} onClick={()=>handleShareClick(formData)}>
                        <LazyLoadImage effect='blur' src='/assets/icons/link.svg' alt='Link Icon' /> Copiar enlace del partido
                    </Button>
                </Col>
       
                <CategoryModal
                    show={categoryModalVisible}
                    onHide={() => setCategoryModalVisible(false)}
                    selectedCategory={selectedCategory}
                    handleButtonClick={handleCategoryButtonClick}
                />

                <PublicTypeModal
                    show={publicModalVisible}
                    onHide={() => setPublicModalVisible(false)}
                    selectedPublicType={selectedPublicType}
                    handleButtonClick={handlePublicTypeButtonClick}
                />

                <PriceModal
                    show={priceModalVisible}
                    onHide={() => setPriceModalVisible(false)}
                    selectedPrice={selectedPrice}
                    handleButtonClick={handlePriceButtonClick}
                />

                <PaymentStatusModal
                    show={paymentStatusModalVisible}
                    onHide={() => setPaymentStatusModalVisible(false)}
                    selectedPaymentStatus={selectedPaymentStatus}
                    handleButtonClick={handlePaymentStatusButtonClick}
                />

                <BookingsModal
                    show={bookingsModalVisible}
                    onHide={() => setBookingsModalVisible(false)}
                    selectedBookings={selectedBookings}
                    handleButtonClick={handleBookingsButtonClick}
                    totalSlots={formData.total_slots}
                    fullUserList={fullUserList} // Pass fullUserList here
                />

                <WhoPaysModal
                    show={whoPaysModalVisible}
                    onHide={() => setWhoPaysModalVisible(false)}
                    selectedBookings={selectedBookings}
                    handleButtonClick={handleWhoPaysButtonClick}
                />

                <DeleteModal
                    show={showConfirm}
                    onHide={() => setShowConfirm(false)}
                    onDelete={handleDelete}
                    deleting={deleting} // Pass deleting state as a prop
                    schedule={formData}
                />



            </Row>

            <Row className={isSubmitting ? 'mt-4 justify-content-end go-disabled' : 'mt-4 justify-content-end'}>
                {startsWithGhost(scheduleId) ? null : (
                    <Col xs={12} md={3} className='mb-3 '>
                    <Button className='go-btn go-btn--delete' onClick={() => setShowConfirm(true)}> 
                        Eliminar
                    </Button>
                </Col>
                )}
                <Col xs={12} md={3} className="mb-3">
                    <Link to="/admin/gocalendar" state={{ previuslySelectedDate, scrollToId: state?.schedule.id }} className="go-btn go-btn--close btn btn-primary link">
                        Volver
                    </Link>
                </Col>
                <Col xs={12} md={3}>
                <Button
                        className={`go-btn go-btn--add ${isButtonDisabled ? "go-disabled" : ""}`}
                        onClick={createNewSchedule}
                    >
                        {startsWithGhost(scheduleId) ? 'Crear Reserva' : 'Guardar cambios'}
                    </Button>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default AdminScheduleSettingsScreen;
