import React, { useState, useEffect, useRef, useContext } from 'react';
import { useCart } from '../../contexts/GoMarket/CartContext';
import { Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import Item from './HomeItem';
import HomeBookingItem from './HomeBookingItem';
import {
  fetchItems,
  getItemsCategories,
  searchItemsByString,
  searchItemsByCategoryId,
  searchItemsByStringAndOrCategoryId,
} from '../../utils/GoMarketApi';
import { getDailyPendingBookings, getPendingBookings } from '../../utils/GoMatchApi';
import SearchBarComponent from '../Global/SearchBar/SearchBarComponent';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import GoPagination from '../Global/Pagination/GoPagination';
import { CashRegisterContext } from '../../contexts/GoMarket/CashRegisterContext';

function ItemList({ refreshBookings, setRefreshBookings }) {
  const { addToCart, setCartBookings } = useCart();
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bookings, setBookings] = useState([]); // Separate state for bookings
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBookingType, setSelectedBookingType] = useState('todos'); // State for booking type
  const [loading, setLoading] = useState(true);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [pagination, setPagination] = useState({});
  const [bookingPagination, setBookingPagination] = useState({}); // Separate pagination for bookings
  const [totalBookingsCount, setTotalBookingsCount] = useState(0);
  const [dailyBookingsCount, setDailyBookingsCount] = useState(0);
  const { currentDailyBookings } = useContext(CashRegisterContext);

  const abortControllerRef = useRef(null);

  const fetchBookingsData = async (bookingType, page, limit, search) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    setLoadingBookings(true);

    let response;

    try {
      if (bookingType === 'hoy') {
        response = await getDailyPendingBookings(page, limit, search, { signal: abortControllerRef.current.signal });
      } else {
        response = await getPendingBookings(page, limit, search, { signal: abortControllerRef.current.signal });
      }

      if (response.ok) {
        const formattedBookings = response.data.data.map(booking => ({
          ...booking
        }));
        setBookings(formattedBookings);
        setBookingPagination(response.data.pagination);
      } else {
        setBookings([]);
        setBookingPagination({});
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Error fetching bookings:", error);
      }
    } finally {
      setLoadingBookings(false);
    }
  };

  const fetchBookingCounts = async () => {
    try {
      const [totalBookingsResponse, dailyBookingsResponse] = await Promise.all([
        getPendingBookings(1, 1, ''),
        getDailyPendingBookings(1, 1, '')
      ]);

      if (totalBookingsResponse.ok) {
        setTotalBookingsCount(totalBookingsResponse.data.pagination.totalLength);
      }
      if (dailyBookingsResponse.ok) {
        setDailyBookingsCount(dailyBookingsResponse.data.pagination.totalLength);
      }
    } catch (error) {
      console.error("Error fetching booking counts:", error);
    }
  };

  const handleTabSelect = async (key) => {
    if (key === 'inventario') {
      setPage(1);
      setSearchTerm('');
      setSelectedCategory('');
      setLoading(true);
      fetchItemsData(); // Fetch items when selecting inventory
    } else if (key === 'reservas') {
      setPage(1);
      setSearchTerm('');
      setSelectedCategory('bookings');
      setLoadingBookings(true);
      await fetchBookingCounts();
      await fetchBookingsData(selectedBookingType, page, limit, searchTerm);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getItemsCategories();
        const filteredCategories = categoriesData.filter(category => category.item_category_name !== 'Reservas');
        setCategories(filteredCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchItemsData = async () => {
    if (selectedCategory === 'bookings') return; // Prevent fetching items when 'bookings' is selected
  
    setLoading(true);
    try {
      let data;
      if (selectedCategory === '') {
        data = searchTerm
          ? await searchItemsByString(searchTerm, page, limit)
          : await fetchItems(page, limit);
      } else {
        data = searchTerm
          ? await searchItemsByStringAndOrCategoryId(searchTerm, selectedCategory, page, limit)
          : await searchItemsByCategoryId(selectedCategory, page, limit);
      }
    
      setItems(data.items);
      setPagination(data.pagination);
  
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (refreshBookings) {
      if (selectedCategory === 'bookings') {
        setLoadingBookings(true);
        fetchBookingsData(selectedBookingType, page, limit, searchTerm);
      } else {
        fetchItemsData();
      }
      setRefreshBookings(false);
    }
  }, [refreshBookings]);

  useEffect(() => {
    if (selectedCategory === 'bookings') {
      setLoadingBookings(true);
      fetchBookingsData(selectedBookingType, page, limit, searchTerm);
    } else {
      fetchItemsData();
    }
  }, [page, searchTerm, selectedCategory, selectedBookingType]);

  const handleBookingTypeClick = async (type) => {
    if (selectedBookingType !== type) {
      setLoadingBookings(true);
      setSelectedBookingType(type);
      setPage(1);
      fetchBookingCounts();
      await fetchBookingsData(type, 1, limit, '');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const renderSkeletons = () => {
    return Array.from({ length: 6 }).map((_, i) => (
      <Col key={i} xs={12} sm={6} md={4} className='pb-3'>
        <Skeleton height={selectedCategory !== 'bookings' ? 255 : 289} className='go-skeleton go-skeleton--card pt-3'/>
      </Col>
    ));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleAddBookingToCart = (booking) => {
    setCartBookings(prevBookings => {
      const existingBooking = prevBookings.find(b => b.id === booking.id);
      if (!existingBooking) {
        return [...prevBookings, booking];
      }
      return prevBookings;
    });
  };

  return (
    <>
      <Tabs
        id="item-list-tabs"
        activeKey={selectedCategory === 'bookings' ? 'reservas' : 'inventario'}
        onSelect={handleTabSelect}
        className="mb-3 go-tabs"
      >
        <Tab eventKey="inventario" className='go-tabs go-tabs__title' title={selectedCategory !== 'bookings' ? 
        <><img src='/assets/icons/menu/box-green.svg' alt='box'/> Inventario</> : 
        <><img src='/assets/icons/menu/box.svg' alt='box'/> Inventario</>}>
          <Row className="mb-3">
            <Col className='col-md-6'>
              <SearchBarComponent searchTerm={searchTerm} handleSearchChange={handleSearchChange} placeholder="Busca en tu inventario" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div className='go-categories'>
                <Button
                  className={`${selectedCategory === '' ? 'go-btn go-btn--category selected' : 'go-btn go-btn--category'} ${loading ? 'go-disabled' : ''} me-2`}
                  onClick={() => setSelectedCategory('')}
                >
                  Todos
                </Button>
                {categories.map(category => (
                  <Button
                    key={category.id}
                    onClick={() => {
                      setSelectedCategory(category.id);
                      setPage(1);
                    }}
                    className={`${selectedCategory === category.id ? 'go-btn go-btn--category selected' : 'go-btn go-btn--category'} ${loading ? 'go-disabled' : ''} me-2`}
                  >
                    {category.item_category_name}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="reservas" className='go-tabs go-tabs__title' title={selectedCategory === 'bookings' ? 
          <><img src='/assets/icons/menu/calendar-green.svg' alt='calendar'/> Reservas
          <div bg="danger" className={`ms-2 notification-badge ${currentDailyBookings ? '' : 'd-none'}`}></div>
          </> : 
          <><img src='/assets/icons/menu/calendar.svg' alt='calendar'/> Reservas 
            <div bg="danger" className={`ms-2 notification-badge ${currentDailyBookings ? '' : 'd-none'}`}></div>
          </>
        }>
          <Row className="mb-3">
            <Col className='col-md-6'>
              <SearchBarComponent searchTerm={searchTerm} handleSearchChange={handleSearchChange} placeholder="Busca en tus reservas" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div className='go-categories'>
                <Button
                  className={`go-btn go-btn--category ${selectedBookingType === 'todos' ? 'selected' : ''} ${loadingBookings ? 'go-disabled' : ''} me-2`}
                  onClick={() => handleBookingTypeClick('todos')}
                >
                  Todos <div className='notification'>{totalBookingsCount}</div>
                </Button>
                <Button
                  className={`go-btn go-btn--category ${selectedBookingType === 'hoy' ? 'selected' : ''} ${loadingBookings ? 'go-disabled' : ''} me-2`}
                  onClick={() => handleBookingTypeClick('hoy')}
                >
                  Hoy <div className='notification'>{dailyBookingsCount}</div>
                </Button>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>

      <Row className='go-market--items'>
        {loading || (selectedCategory === 'bookings' && loadingBookings) ? (
          renderSkeletons()
        ) : selectedCategory === 'bookings' && bookings.length === 0 && !loadingBookings ? (
          <Col>
            <div className='text-center text-muted py-5'>
              <p className='go-text mb-0'>No se han encontrado reservas</p>
            </div>
          </Col>
        ) : selectedCategory === 'bookings' && !loadingBookings ? (
          bookings.map(booking => (
            <HomeBookingItem key={booking.id} booking={booking} onAddToCart={handleAddBookingToCart} />
          ))
        ) : items.length > 0 ? (
          items.map(item => (
            <Item key={item.id} item={item} onAddToCart={addToCart} />
          ))
        ) : (
          <Col>
            <div className='text-center text-muted py-5'>
              <p className='go-text mb-0'>No se han encontrado productos</p>
              <p className='go-text'>Revisar mi <a href="market/inventario" className='go-link'>Inventario</a></p>
            </div>
          </Col>
        )}
      </Row>

      <Row className="mt-3">
        <Col className="">
          <GoPagination
            page={page}
            totalPages={selectedCategory === 'bookings' ? bookingPagination.totalPages : pagination.totalPages}
            onPageChange={handlePageChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default ItemList;
