import React, { useState } from 'react';
import { Table, Col, Row, Accordion } from 'react-bootstrap';

const CashRegisterRecord = ({ activeRecord }) => {
  const [activeKey, setActiveKey] = useState(null);

  if (!activeRecord) {
    return <div>Error: Order not found</div>;
  }

  const handleToggle = () => {
    setActiveKey(activeKey === '0' ? null : '0');
  };

  const renderUserInfo = () => (
    <Row className='px-3'>
      <Col md={4} xs={6}>
        <p className='go-text'>
          <img src="/assets/icons/profile-circle.svg" alt="Profile" />
          {activeRecord.cash_register_record_user}
        </p>
        <p className='go-text'>
          <img className="go-filter--black cash-icon" src="/assets/icons/strongbox.svg" alt="Cash Register" />
          {activeRecord.cash_register_record_cash_register}
        </p>
      </Col>
      <Col md={8} xs={6}>
        <p className='go-text'>
          <img src="/assets/icons/calendar.svg" alt="Calendar" />
          {activeRecord.cash_register_record_date}
        </p>
        <p className='go-text'>
          <img src="/assets/icons/clock.svg" alt="Clock" />
          {activeRecord.cash_register_record_time_whitout_seconds} Hrs
        </p>
      </Col>
    </Row>
  );

  const renderRecordAmounts = () => (
    <Table borderless hover className='go-table go-table--orders go-table--records'>
      <thead>
        <tr>
          <th>Concepto</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Monto apertura <div className='go-table--records__total text-end'>${activeRecord.cash_register_record_open_amount}</div></td>
        </tr>
        <tr>
          <td>Monto cierre <div className='go-table--records__total text-end'>${activeRecord.cash_register_record_close_amount}</div></td>
        </tr>
        <tr>
          <td>Monto cierre (Sistema) <div className='go-table--records__total text-end'>${activeRecord.cash_register_record_close_cash_backend_balance}</div></td>
        </tr>
        <tr onClick={handleToggle} style={{ cursor: 'pointer' }}>
          <td className='w-100'><b>Ventas Totales</b><div className='go-table--records__total text-end'><b>${activeRecord.cash_register_record_close_total_balance}</b></div></td>
        </tr>
        <Accordion activeKey={activeKey} className='w-100'>
          <Accordion.Collapse eventKey="0">
            <Table className='w-100'>
              <tbody>
                <tr>
                  <td>Ventas totales Débito</td>
                  <td className="text-end">${activeRecord.cash_register_record_close_debit_balance}</td>
                </tr>
                <tr>
                  <td>Ventas totales Crédito</td>
                  <td className="text-end">${activeRecord.cash_register_record_close_credit_balance}</td>
                </tr>
                <tr>
                  <td>Ventas totales Transferencias</td>
                  <td className="text-end">${activeRecord.cash_register_record_close_transfer_balance}</td>
                </tr>
                <tr>
                  <td>Ventas totales Efectivo</td>
                  <td className="text-end">${activeRecord.cash_register_record_close_cash_balance}</td>
                </tr>
                <tr>
                  <td>Ventas totales Reservas</td>
                  <td className="text-end">${activeRecord.cash_register_record_close_reservation_balance}</td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Collapse>
        </Accordion>
      </tbody>
    </Table>
  );

  const renderBalanceAlert = () => {
    const balanceDiff = activeRecord.cash_register_record_close_cash_balance_diff;
    const message = balanceDiff === 0
      ? "¡Todo en orden!"
      : balanceDiff < 0
      ? `Según nuestros cálculos, faltan $${Math.abs(balanceDiff)} en efectivo en este cierre de caja`
      : `Según nuestros cálculos, sobran $${balanceDiff} en efectivo en este cierre de caja`;

    const alertType = balanceDiff === 0 ? 'go-alert--success' : 'go-alert--danger';
    const icon = balanceDiff === 0 ? 'check' : 'alert';

    return (
      <div className={`go-alert ${alertType}`}>
        <p className='text-muted go-text mb-0'>
          <img src={`/assets/icons/${icon}.svg`} className='me-3' alt={icon} />
          {message}
        </p>
      </div>
    );
  };

  return (
    <>
      <h4 className="title px-2 mb-3">Registro #{activeRecord.id}</h4>
      {renderUserInfo()}
      <Row className='py-3'>
        <Col md={12}>
          {renderRecordAmounts()}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {renderBalanceAlert()}
        </Col>
      </Row>
    </>
  );
};

export default CashRegisterRecord;
