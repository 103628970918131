import React from "react";
import "./AdminConfiguration.scss";
import { Row, Col } from 'react-bootstrap';
import { bucketUrl } from "../../../utils/Constants";


function AdminConfiguration({ clubList, handleEditClub }) {
  if (!clubList.length) {
    return <div>No hay registros para mostrar</div>;
  }

  return (
    <>
      <Row className='pb-2'>
          <Col className='col-12'>
          
              <div className='pdl-section'>
              <h2 className='pdl-shared-title'>Mis Clubs </h2>
                {clubList.map((club, index) => (
                 
                    <div className="pdl-section pdl-section--white" key={index}>
                      <img
                        src={
                          club?.logo?.url
                            ? club.logo.url
                            : bucketUrl+"/club_logos/club.jpeg"
                        }
                        alt="Club Logo"
                        className="configuration-admin-logo img-fluid m-1"
                      />
                      <div className="card-body custom-text-color">
                        <h5 className="card-title">{club.name}</h5>
                        <p className="card-text m-0 pdl-card-address">{club.address}</p>
                        <p className="card-text m-0 pdl-card-phone">{club.phone}</p>
                        <p className="card-text m-0 pdl-card-email">{club.email}</p>
                        <button
                          className="btn pdl-btn pdl-btn--edit mt-3"
                          onClick={() => handleEditClub(index)}
                        >
                          Editar
                        </button>
                      </div>
                    </div>
               
                ))}
              </div>
          </Col>
      </Row>
    </>
  );
}

export default AdminConfiguration;
