// src/components/Global/SearchBar/SearchBar.js
import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import './SearchBarComponent.scss'; // Import the associated SCSS file

const SearchBarComponent = ({ searchTerm, handleSearchChange, placeholder, extraClassName, id }) => {
    return (
        <InputGroup className="go-searchbar-container">
       
                <InputGroup.Text className="go-searchbar-icon">
                    <img src="/assets/icons/search.svg" alt="Search" />
                </InputGroup.Text>
          
            <FormControl
                id={id}
                className={`go-searchbar ${extraClassName}`}
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleSearchChange}
                autoFocus={true}
            />
        </InputGroup>
    );
};

export default SearchBarComponent;
