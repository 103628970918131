import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faCircleChevronRight,
  faHouse,
  faImage,
  faMoneyBill,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { handleSavePriceListsChanges, uploadClubLogo } from "../../../utils/GoMatchApi";
import Spinner from "react-bootstrap/Spinner";
import "./AdminClubForm.scss";
import { validate24TimeFormat } from "../../../utils/Functions";

const AdminClubForm = ({
  club,
  editedClubData,
  setClub,
  setEditedClubData,
  handleSaveClubChanges,
}) => {
  const [errors, setErrors] = useState({});
  const [priceListsErrors, setPriceListsErrors] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [urlNewLogo, setUrlNewLogo] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const disableEdition = true;

  const validate = (data) => {
    let tempErrors = {};
    let isValid = true;

    if (!data.email) {
      tempErrors.email = "Correo es un campo requerido";
      toast.error("Correo es un campo requerido");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      tempErrors.email = "El Correo no es válido";
      toast.error("El Correo no es válido");
      isValid = false;
    }
    if (!data.name.trim()) {
      tempErrors.name = "Nombre del Club es un campo requerido";
      toast.error("Nombre del Club es un campo requerido");
      isValid = false;
    }
    if (!data.address.trim()) {
      tempErrors.address = "Dirección de Club es un campo requerido";
      toast.error("Dirección de Club es un campo requerido");
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const validatePriceLists = (data) => {
    let tempErrors = [];
    let isValid = true;

    data.map((priceList, index) => {
      if (!priceList?.name) {
        tempErrors[index] = {
          ...tempErrors[index],
          name: "El nombre de la lista es un campo requerido",
        };
        isValid = false;
      }
      if (!priceList?.range_valley_start) {
        tempErrors[index] = { ...tempErrors[index], range_valley_start: "Hora requerida" };
        isValid = false;
      } else if (!validate24TimeFormat(priceList?.range_valley_start)) {
        tempErrors[index] = {
          ...tempErrors[index],
          range_valley_start: "Formato inválido. Debe ser HH:mm: (07:30)",
        };
        isValid = false;
      }
      if (!priceList?.range_valley_end) {
        tempErrors[index] = { ...tempErrors[index], range_valley_end: "Hora requerida" };
        isValid = false;
      } else if (!validate24TimeFormat(priceList?.range_valley_end)) {
        tempErrors[index] = {
          ...tempErrors[index],
          range_valley_end: "Formato inválido. Debe ser HH:mm: (07:30)",
        };
        isValid = false;
      }
      if (!priceList?.amount_valley) {
        tempErrors[index] = { ...tempErrors[index], amount_valley: "Valor requerido" };
        isValid = false;
      } else if (isNaN(priceList?.amount_valley)) {
        tempErrors[index] = { ...tempErrors[index], amount_valley: "Ingresa solo números" };
        isValid = false;
      }
      if (!priceList?.range_rush_start) {
        tempErrors[index] = { ...tempErrors[index], range_rush_start: "Hora requerida" };
        isValid = false;
      } else if (!validate24TimeFormat(priceList?.range_rush_start)) {
        tempErrors[index] = {
          ...tempErrors[index],
          range_rush_start: "Formato inválido. Debe ser HH:mm: (18:00)",
        };
        isValid = false;
      }
      if (!priceList?.range_rush_end) {
        tempErrors[index] = { ...tempErrors[index], range_rush_end: "Hora requerida" };
        isValid = false;
      } else if (!validate24TimeFormat(priceList?.range_rush_end)) {
        tempErrors[index] = {
          ...tempErrors[index],
          range_rush_end: "Formato inválido. Debe ser HH:mm: (23:00)",
        };
        isValid = false;
      }
      if (!priceList?.amount_rush) {
        tempErrors[index] = { ...tempErrors[index], amount_rush: "Valor requerido" };
        isValid = false;
      } else if (isNaN(priceList?.amount_rush)) {
        tempErrors[index] = { ...tempErrors[index], amount_rush: "Ingresa solo números" };
        isValid = false;
      }
      return null;
    });

    setPriceListsErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setClub({ ...club, [name]: value });
    setEditedClubData({ ...editedClubData, [name]: value });
  };

  const handleSocialsChange = (e) => {
    let { name, value } = e.target;
    setClub({ ...club, socials: { ...club.socials, [name]: value } });
    setEditedClubData({ ...editedClubData, socials: { ...club.socials, [name]: value } });
  };

  const handleBankAccountChange = (e) => {
    let { name, value } = e.target;
    setClub({ ...club, bank_account: { ...club.bank_account, [name]: value } });
    setEditedClubData({ ...editedClubData, bank_account: { ...club.bank_account, [name]: value } });
  };

  const handleBankAccountChangeHacker = (e) => {
    let { name } = e.target;
    const disabledInputName = ["nombre", "rut", "numero_cuenta", "banco", "tipo_cuenta"];
    if (disabledInputName.find((inputName) => inputName === name) || disableEdition)
      return toast.error("No nos hackees por favor. Somos startup! :)");
  };

  const handleFileChange = async (e) => {
    setIsFileLoading(true);
    let paymentReceiptId = null;
    if (e.target.files[0]) {
      try {
        const [uploadedFileData] = await uploadClubLogo(e.target.files[0], club.name);
        paymentReceiptId = uploadedFileData.id;
        setUrlNewLogo(uploadedFileData.url);
        setEditedClubData({ ...editedClubData, logo: paymentReceiptId });
        setIsFileLoading(false);
      } catch (error) {
        console.log(error);
        setIsFileLoading(false);
      }
    }
  };

  const handlePriceListChange = (e, index) => {
    let { name, value } = e.target;
    let auxArray = [...club.price_lists];
    auxArray[index][name] = value;
    validatePriceLists(auxArray);
    setClub({ ...club, price_lists: auxArray });
    setEditedClubData({ ...editedClubData, price_lists: auxArray });
  };

  const handleTabChange = (tabKey) => {
    validatePriceLists(club?.price_lists);
    setSelectedTab(Number(tabKey));
  };

  const handleSave = async () => {
    const validatePriceListsBoolean = validatePriceLists(club?.price_lists);
    if (validate(club) && validatePriceListsBoolean) {
      if (urlNewLogo) {
        setClub({ ...club, logo: { url: urlNewLogo } });
      }
      if (editedClubData?.price_lists) {
        editedClubData.price_lists.map((priceList, index) => {
          return handleSavePriceListsChanges(priceList.id, editedClubData.price_lists[index]);
        });
      }
      handleSaveClubChanges();
      setUrlNewLogo(null);
    } else {
      if (!validatePriceListsBoolean)
        toast.error("Hay errores en el formulario de listas de precios");
      else toast.error("Hay errores en el formulario");
    }
  };

  return (
    <Row className="">
    <Col className='col-12'>
        <div className='pdl-section pt-4'>
        <h2 className='pdl-shared-title pb-2'>{club.name}</h2>
      <Accordion className="pb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon icon={faHouse} className="admin-club-form-accordion-icon" />
            Información general
          </Accordion.Header>
          <Accordion.Body>
            <div>
              Nombre del club
              <input
                className={`pdl-input form-control ${errors.name && "is-invalid"}`}
                type="text"
                name="name"
                value={club.name}
                placeholder="Nombre club"
                onChange={handleChange}
              />
            </div>

            <div>
              Dirección
              <input
                className={`pdl-input form-control ${errors.address && "is-invalid"}`}
                type="text"
                name="address"
                value={club.address}
                placeholder="Dirección del club"
                onChange={handleChange}
              />
            </div>

            <div>
              Correo
              <input
                className={`pdl-input form-control ${errors.email && "is-invalid"}`}
                type="email"
                name="email"
                value={club.email}
                placeholder="Correo "
                onChange={handleChange}
              />
            </div>

            <div>
              Teléfono
              <input
                className={`pdl-input form-control`}
                type="text"
                name="phone"
                value={club.phone}
                placeholder="Teléfono"
                onChange={handleChange}
              />
            </div>

            <div className="row">
              <div className="col-6">
                Horario de apertura
                <input
                  className="pdl-input form-control"
                  type="text"
                  name="open_time"
                  value={club.open_time}
                  placeholder="Hora de apertura"
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                Horario de cierre
                <input
                  className="pdl-input form-control"
                  type="text"
                  name="close_time"
                  value={club.close_time}
                  placeholder="Hora de cierre"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              Nombre Dueño Club o Administrador
              <input
                className="pdl-input form-control"
                type="text"
                name="club_owner_name"
                value={club.club_owner_name}
                placeholder="Encargado del Club"
                onChange={handleChange}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <FontAwesomeIcon icon={faShareNodes} className="admin-club-form-accordion-icon" />
            Redes sociales
          </Accordion.Header>
          <Accordion.Body>
            <div>
              Facebook
              <input
                className={`pdl-input form-control`}
                type="text"
                name="facebook"
                value={club.socials.facebook}
                placeholder="Facebook"
                onChange={handleSocialsChange}
              />
            </div>
            <div>
              Instagram
              <input
                className={`pdl-input form-control`}
                type="text"
                name="instagram"
                value={club.socials.instagram}
                placeholder="Instagram"
                onChange={handleSocialsChange}
              />
            </div>
            <div>
              Twitter
              <input
                className={`pdl-input form-control`}
                type="text"
                name="twitter"
                value={club.socials.twitter}
                placeholder="Twitter"
                onChange={handleSocialsChange}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FontAwesomeIcon icon={faBuildingColumns} className="admin-club-form-accordion-icon" />
            Datos bancarios
          </Accordion.Header>
          <Accordion.Body>
            <div className="text-muted small mb-2">
              Por tu seguridad no puedes editar estos campos. Contacta a soporte{" "}
              <a href="/admin/configuracion/editar_club" className="go-link">GoMatch</a> para actualizarlos.
            </div>
            <div>
              Nombre cuenta Banco
              <input
                className={`pdl-input form-control`}
                disabled
                type="text"
                name="nombre"
                value={club.bank_account.nombre}
                placeholder="Nombre cuenta Banco"
                onChange={handleBankAccountChangeHacker}
              />
            </div>
            <div>
              Rut
              <input
                className={`pdl-input form-control`}
                disabled
                type="text"
                name="rut"
                value={club.bank_account.rut}
                placeholder="Rut"
                onChange={handleBankAccountChangeHacker}
              />
            </div>
            <div>
              Cuenta bancaria
              <input
                className={`pdl-input form-control`}
                disabled
                type="text"
                name="numero_cuenta"
                value={club.bank_account.numero_cuenta}
                placeholder="Cuenta bancaria"
                onChange={handleBankAccountChangeHacker}
              />
            </div>
            <div>
              Banco
              <input
                className={`pdl-input form-control`}
                disabled
                type="text"
                name="banco"
                value={club.bank_account.banco}
                placeholder="Banco"
                onChange={handleBankAccountChangeHacker}
              />
            </div>
            <div>
              Tipo cuenta
              <input
                className={`pdl-input form-control`}
                disabled
                type="text"
                name="tipo_cuenta"
                value={club.bank_account.tipo_cuenta}
                placeholder="Tipo cuenta"
                onChange={handleBankAccountChangeHacker}
              />
            </div>
            <div>
              Correo
              <input
                className={`pdl-input form-control`}
                type="text"
                name="email"
                value={club.bank_account.email}
                placeholder="Correo"
                onChange={handleBankAccountChange}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <FontAwesomeIcon icon={faImage} className="admin-club-form-accordion-icon" />
            Logo del Club
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <h4>Subir logo de club</h4>
              <div className="row justify-content-around text-center m-3">
                <div className="col-5 admin-club-form-border-cols">
                  <h5>Logo actual</h5>
                  {club.logo?.url ? (
                    <img src={club?.logo?.url} alt="Logo Actual" className="img-fluid" />
                  ) : (
                    <p>No tienes Logo.</p>
                  )}
                </div>
                {isFileLoading && (
                  <Fragment>
                    <div className="col-2 admin-club-form-chevron-icon">
                      <FontAwesomeIcon icon={faCircleChevronRight} size="2x" />
                    </div>
                    <div className="col-5 admin-club-form-border-cols admin-club-form-border-cols-spinner">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </Fragment>
                )}
                {urlNewLogo && (
                  <Fragment>
                    <div className="col-2 admin-club-form-chevron-icon">
                      <FontAwesomeIcon icon={faCircleChevronRight} size="2x" />
                    </div>
                    <div className="col-5 admin-club-form-border-cols">
                      <h5>Nuevo logo</h5>
                      <div>
                        <img src={urlNewLogo} alt="Logo Nuevo" className="img-fluid" />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
              <input type="file" accept="image/png, image/jpeg" onChange={handleFileChange} />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <FontAwesomeIcon icon={faMoneyBill} className="admin-club-form-accordion-icon" />
            Listado de precios
          </Accordion.Header>
          <Accordion.Body>
            {!club?.price_lists.length ? (
              <div className="text-muted small mb-2">
                No tienes listados de precios registrados para tus canchas. Contácta a soporte{" "}
                <a href="/admin/configuracion/editar_club" className="go-link">GoMatch</a> para agregarlos.
              </div>
            ) : (
              <Tabs
                defaultActiveKey="0"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(tabKey) => handleTabChange(tabKey)}
              >
                {club?.price_lists.map((priceList, index) => (
                  <Tab eventKey={index} title={`Lista ${index + 1}`} key={index}>
                    <div>
                      Nombre lista de precios
                      <input
                        className={`pdl-input form-control ${
                          priceListsErrors[index]?.name && selectedTab === index && "is-invalid"
                        }`}
                        type="text"
                        name="name"
                        value={priceList.name}
                        placeholder="Listado precios canchas dobles"
                        onChange={(e) => handlePriceListChange(e, index)}
                      />
                      {priceListsErrors[index]?.name && selectedTab === index && (
                        <div className="invalid-feedback">{priceListsErrors[index]?.name}</div>
                      )}
                    </div>
                    <h6 className="mt-4">Datos hora baja</h6>
                    <div className="row">
                      <div className="col-6">
                        Hora inicio
                        <input
                          className={`pdl-input form-control ${
                            priceListsErrors[index]?.range_valley_start &&
                            selectedTab === index &&
                            "is-invalid"
                          }`}
                          type="text"
                          name="range_valley_start"
                          value={priceList.range_valley_start}
                          placeholder="07:00"
                          onChange={(e) => handlePriceListChange(e, index)}
                        />
                        {priceListsErrors[index]?.range_valley_start && selectedTab === index && (
                          <div className="invalid-feedback">
                            {priceListsErrors[index]?.range_valley_start}
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        Hora fin
                        <input
                          className={`pdl-input form-control ${
                            priceListsErrors[index]?.range_valley_end &&
                            selectedTab === index &&
                            "is-invalid"
                          }`}
                          type="text"
                          name="range_valley_end"
                          value={priceList.range_valley_end}
                          placeholder="17:00"
                          onChange={(e) => handlePriceListChange(e, index)}
                        />
                        {priceListsErrors[index]?.range_valley_end && selectedTab === index && (
                          <div className="invalid-feedback">
                            {priceListsErrors[index]?.range_valley_end}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      Precio
                      <input
                        className={`pdl-input form-control ${
                          priceListsErrors[index]?.amount_valley &&
                          selectedTab === index &&
                          "is-invalid"
                        }`}
                        type="text"
                        name="amount_valley"
                        value={priceList.amount_valley}
                        placeholder="18000"
                        onChange={(e) => handlePriceListChange(e, index)}
                      />
                      {priceListsErrors[index]?.amount_valley && selectedTab === index && (
                        <div className="invalid-feedback">
                          {priceListsErrors[index]?.amount_valley}
                        </div>
                      )}
                    </div>
                    <h6 className="mt-4">Datos hora alta</h6>
                    <div className="row">
                      <div className="col-6">
                        Hora inicio
                        <input
                          className={`pdl-input form-control ${
                            priceListsErrors[index]?.range_rush_start &&
                            selectedTab === index &&
                            "is-invalid"
                          }`}
                          type="text"
                          name="range_rush_start"
                          value={priceList.range_rush_start}
                          placeholder="17:00"
                          onChange={(e) => handlePriceListChange(e, index)}
                        />
                        {priceListsErrors[index]?.range_rush_start && selectedTab === index && (
                          <div className="invalid-feedback">
                            {priceListsErrors[index]?.range_rush_start}
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        Hora fin
                        <input
                          className={`pdl-input form-control ${
                            priceListsErrors[index]?.range_rush_end &&
                            selectedTab === index &&
                            "is-invalid"
                          }`}
                          type="text"
                          name="range_rush_end"
                          value={priceList.range_rush_end}
                          placeholder="23:00"
                          onChange={(e) => handlePriceListChange(e, index)}
                        />
                        {priceListsErrors[index]?.range_rush_end && selectedTab === index && (
                          <div className="invalid-feedback">
                            {priceListsErrors[index]?.range_rush_end}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      Precio
                      <input
                        className={`pdl-input form-control ${
                          priceListsErrors[index]?.amount_rush &&
                          selectedTab === index &&
                          "is-invalid"
                        }`}
                        type="text"
                        name="amount_rush"
                        value={priceList.amount_rush}
                        placeholder="24000"
                        onChange={(e) => handlePriceListChange(e, index)}
                      />
                      {priceListsErrors[index]?.amount_rush && selectedTab === index && (
                        <div className="invalid-feedback">
                          {priceListsErrors[index]?.amount_rush}
                        </div>
                      )}
                    </div>
                  </Tab>
                ))}
              </Tabs>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <button className="btn btn-success w-100 mt-2" onClick={handleSave} disabled={isFileLoading}>
        Guardar Cambios
      </button>
   
    </div>
    </Col>

  </Row>  
);
};

export default AdminClubForm;
