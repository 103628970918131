import React, { useState, useEffect } from "react";
import { padel_league, padel_position } from "../../../utils/Selects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatRut, validateRut } from "../../../utils/Functions";
import { uploadFile } from "../../../utils/GoMatchApi";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SpinnerOverlay from '../Spinner/SpinnerOverlay';
import moment from 'moment';

const ProfileForm = ({
  editableUserData,
  setEditableUserData,
  handleSaveChanges,
  onUserUpdated,
}) => {
  const [errors, setErrors] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(editableUserData.profilePicture || null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Added loading state
  useEffect(() => {
    console.log(editableUserData);
    if (editableUserData.birthday) {
      // Specify the format of the input date string if it's known
      const date = moment(editableUserData.birthday, 'DD/MM/YYYY').toDate();
      console.log(date);
      setSelectedDate(date);
    }
  }, [editableUserData.birthday]); // Depend on 'editableUserData.birthday' to re-run this effect
  
  const handleChange = (date) => {
    setSelectedDate(date); // Update the state when date is changed
    // Update editableUserData directly or you can manage this change separately depending on your use case
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setEditableUserData({...editableUserData, birth_date: formattedDate});
  };

  const range = (start, end) => {
    let arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  };

  const years = range(1900, new Date().getFullYear());
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  const validate = (data) => {
    let tempErrors = {};
    let isValid = true;
    if (!data.email) {
      tempErrors.email = "Correo es un campo requerido";
      toast.error("Correo es un campo requerido");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      tempErrors.email = "El Correo no es válido";
      toast.error("El Correo no es válido");
      isValid = false;
    }
    if (!data.name.trim()) {
      tempErrors.name = "Nombre es un campo requerido";
      toast.error("Nombre es un campo requerido");
      isValid = false;
    }
    if (!data.lastname.trim()) {
      tempErrors.lastname = "Apellido es un campo requerido";
      toast.error("Apellido es un campo requerido");
      isValid = false;
    }
    if (data.rut && !validateRut(data.rut)) {
      tempErrors.rut = "RUT inválido";
      toast.error("RUT inválido");
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const onSaveChanges = async () => {
    if (validate(editableUserData)) {
      setIsLoading(true);
      if (profileImage) {
        try {
          const folder = "profile_photos"; // Adjust the folder path as needed
          const uploadResponse = await uploadFile(profileImage, folder);
          if (uploadResponse) {
            editableUserData.profile_picture = uploadResponse[0].id;
          }
        } catch (error) {
          toast.error("Error uploading file: " + error.message);
          return;
        }
      }
      // Update birth_date with the selected date from DatePicker
      if (selectedDate) {
        editableUserData.birth_date = moment(selectedDate).format('YYYY-MM-DD');
        console.log(editableUserData.birth_date);
    }
    

      const updatedUserData = await handleSaveChanges(editableUserData, editableUserData.id);
      if (updatedUserData) {
        onUserUpdated(updatedUserData);
        toast.success('Profile updated successfully!');
      } else {
        toast.error('Failed to save changes');
      }
      setIsLoading(false);
    }
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableUserData({
      ...editableUserData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  if (isLoading) {
    return (
      <div className="pdl-profile-container pdl-profile-container--loading">
        <SpinnerOverlay />
        <h1 className="pdl-title-main">Perfil del Usuario</h1>
      </div>
    );
  }

  return (
    <div className="pdl-profile-container">
      <h1 className="pdl-title-main">Perfil del Usuario</h1>
      <div className="form-group form-group--section mb-3">
        {imagePreview && (
          <div className="pdl-profile__img pdl-profile__img--edit">
            <img src={imagePreview} alt="Preview" className="img-thumbnail mt-2 mb-0" width="200" />
          </div>
        )}
        <input type="file" className="form-control pdl-input pdl-input--file mt-3 mb-0" id="profileImage" onChange={handleImageChange} />
      </div>
      <div className="form-group form-group--section mb-3">
        <label className="pdl-label text-start w-100">Nombre</label>
        <input className={`pdl-input form-control ${errors.name ? 'is-invalid' : ''}`} type="text" name="name" value={editableUserData.name} placeholder="Nombre" onChange={handleInputChange} />
        <label className="pdl-label text-start w-100">Apellido</label>
        <input className={`pdl-input form-control ${errors.lastname ? 'is-invalid' : ''}`} type="text" name="lastname" value={editableUserData.lastname || ""} placeholder="Apellido" onChange={handleInputChange} />
        
        <label className="pdl-label text-start w-100">Fecha de nacimiento</label>
          
        <div className="pdl-datepicker pdl-datepicker--form mb-2">
        <DatePicker 
          className={`pdl-input form-control w-100 ${errors.birth_date ? 'is-invalid' : ''}`} 
          name="birth_date" 
          dateFormat="dd-MM-yyyy" 
          isInvalid={!!errors.birth_date} 
          showYearDropdown={true} 
          maxDate={new Date()} 
          calendarType="ISO 8601"
          placeholderText="Fecha de Nacimiento"
          currentMonth={selectedDate}
          renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
              <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(Number(value))}>
                {years.map(option => <option key={option} value={option}>{option}</option>)}
              </select>
              <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                {months.map(option => <option key={option} value={option}>{option}</option>)}
              </select>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
            </div>
          )}
          selected={selectedDate} 
          onChange={handleChange} 
        />
      </div>
      
      <label className="pdl-label text-start w-100">Correo</label>
        <input className={`pdl-input form-control ${errors.email ? 'is-invalid' : ''}`} type="email" name="email" value={editableUserData.email} placeholder="Tu correo" onChange={handleInputChange} />
        
        <label className="pdl-label text-start w-100">Teléfono</label>
        <input className={`pdl-input form-control ${errors.phone ? 'is-invalid' : ''}`} type="phone" name="phone" value={editableUserData.phone} placeholder="Tu teléfono" onChange={handleInputChange} />
        
        <label className="pdl-label text-start w-100">Rut</label>
        <input className={`pdl-input form-control ${errors.rut ? 'is-invalid' : ''}`} type="text" name="rut" value={editableUserData.rut} placeholder="Rut" onChange={handleInputChange} />

        <label className="pdl-label text-start w-100">Dirección</label>
        <input className="pdl-input form-control" type="text" name="address" value={editableUserData.address} placeholder="Calle #123, Comuna, Región" onChange={handleInputChange} />
        
        <label className="pdl-label text-start w-100">Categoría</label>
        <select className="pdl-input form-control" name="padel_league" value={editableUserData.padelLeague} onChange={handleInputChange}>
          {padel_league.map(league => <option key={league.option} value={league.value}>{league.value}</option>)}
        </select>

        <label className="pdl-label text-start w-100">Posición</label>
        <select className="pdl-input form-control mb-0" name="padel_position" value={editableUserData.padelPosition} onChange={handleInputChange}>
          {padel_position.map(position => <option key={position.option} value={position.value}>{position.value}</option>)}
        </select>
        <button className="btn btn-success pdl-btn-success w-100 mt-3 pdl-btn" onClick={onSaveChanges}>Guardar Cambios</button>
      </div>
    </div>
  );
};

export default ProfileForm;
