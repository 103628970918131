import LoginScreen from "../../screens/GoMatch/LoginScreen";
import HomeScreen from "../../screens/GoMatch/HomeScreen";
import RegisterScreen from "../../screens/GoMatch/RegisterScreen";
import BuildingMatchScreen from "../../screens/GoMatch/BuildingMatchScreen";
import AdminLoginScreen from "../../screens/Admin/AdminLoginScreen";
import PasswordResetScreen from "../../screens/GoMatch/PasswordResetScreen";
import PaymentScreen from "../../screens/GoMatch/PaymentScreen";
import TermsAndConditionsScreen from "../../screens/GoMatch/TermsAndConditions";

// Add all the paths and components that are public (don't need authentication) to this array
export const publicRoutes = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/terms_and_conditions",
    element: <TermsAndConditionsScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/register",
    element: <RegisterScreen />,
  },
  {
    path: "/match/:slug",
    element: <BuildingMatchScreen />,
  },
  {
    path: "/admin_login",
    element: <AdminLoginScreen />,
  },
  {
    path: "/password_reset",
    element: <PasswordResetScreen />,
  },
  {
    path: "/payment/:slug",
    element: <PaymentScreen />,
  },
];
