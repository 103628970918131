import React from "react";
import Card from "react-bootstrap/Card";
import "./ScheduleInformation.scss";

function ScheduleInformation({scheduleInfo}) {
  return (
    <Card className="bg-white text-dark">
      <Card.Img
        className="match-card-information"
        src="https://www.padeladdict.com/wp-content/uploads/2022/05/cuando-jugar-con-paredes-en-padel-portada-1068x580.jpeg"
        alt="Card match image"
      />
      <Card.ImgOverlay>
        <Card.Title className="match-card-information-title">
          {scheduleInfo?.clubName}
        </Card.Title>
        <Card.Text className="match-card-information-text">
          {scheduleInfo?.courtName}
        </Card.Text>
        <Card.Text className="match-card-information-text">
          <b>Fecha:</b> {scheduleInfo?.scheduleDate}
        </Card.Text>
        <Card.Text className="match-card-information-text">
        <b> Hora:</b>  {scheduleInfo?.scheduleStartTime} - {scheduleInfo?.scheduleEndTime}
        </Card.Text>
        <Card.Text className="match-card-information-text">
        <b>Duración del partido:</b>  {scheduleInfo?.scheduleDuration}
        </Card.Text>
        <Card.Text className="match-card-information-text">
        <b> Dirección:</b>  {scheduleInfo?.clubAddress}
        </Card.Text>
        <Card.Text className="match-card-information-text">
        <b> Valor cancha:</b>  {scheduleInfo?.schedulePrice}
        </Card.Text>
      </Card.ImgOverlay>
    </Card>
  );
}

export default ScheduleInformation;