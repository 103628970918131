import React from 'react';
import { Modal, Button, Row, Col, Badge } from 'react-bootstrap';

const PaymentStatusModal = ({ show, onHide, handleButtonClick, selectedPaymentStatus }) => {
    return (
        <Modal show={show} size='lg' onHide={onHide} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Seleccionar tipo de reserva</h4>

                <Row>
                    <Col md={6} xs={12} className='my-2'>
                        <Button className={`go-btn go-btn-select--private ${selectedPaymentStatus === 'GoMatch' ? 'selected' : ''}`} onClick={() => handleButtonClick('p0')}>
                            <h4> GoMatch</h4>
                            
                            <p>Todos los jugadores pagan el total de la cancha dividido en partes iguales</p>
                        </Button>
                    </Col>
                 
                    <Col md={6} xs={12} className='my-2'>
                        <Button className={`go-btn go-btn-select--private ${selectedPaymentStatus === 'Reservado' ? 'selected' : ''}`} onClick={() => handleButtonClick('pr')} >
                            <h4> Reservado</h4>
                            <p>Reserva el horario para un jugador</p>
                        </Button>
                    </Col>
                
                    <Col md={6} xs={12} className='my-2'>
                        <Button className={`go-btn go-btn-select--private ${selectedPaymentStatus === 'Abonado' ? 'selected' : ''}`} onClick={() => handleButtonClick('p50')}>
                            <h4>  Abonado </h4>
                            <p>El pagador reserva la cancha y abona la mitad del pago total</p>
                        </Button>
                    </Col>
                    <Col md={6} xs={12} className='my-2'>
                        <Button className={`go-btn go-btn-select--private ${selectedPaymentStatus === 'Pagado' ? 'selected' : ''}`} onClick={() => handleButtonClick('p100')}>
                            <h4> Pagado </h4>
                            <p>El pagador cancela el total de la cancha</p>
                        </Button>
                    </Col>
                    <Col md={6} xs={12} className='my-2'>
                        <Button className={`go-btn go-btn-select--private ${selectedPaymentStatus === 'Clases' ? 'selected' : ''}`} onClick={() => handleButtonClick('pc')} >
                            <h4> Clases</h4>
                            <p> Reserva el horario para una clase</p>
                        </Button>
                    </Col>
                    <Col md={6} xs={12} className='my-2'>
                        <Button className={`go-btn go-btn-select--private ${selectedPaymentStatus === 'Bloqueado' ? 'selected' : ''}`} onClick={() => handleButtonClick('pb')} >
                            <h4>Bloqueado</h4>
                            <p>Oculta este horario para los demás jugadores</p>
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default PaymentStatusModal;
