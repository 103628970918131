import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ItemForm from '../Forms/ItemForm';
import '../Forms/ItemForm.scss'; // Ensure you have the corresponding styles
import ProductsItem from '../ProductsItem';
import Loader from '../../Global/Loader/Loader';

function EditProductModal({ show, handleClose, product, handleSave, handleFileChange, fileName, productCategories, modalLoading }) {
  const [formData, setFormData] = useState({
    item_name: product?.product_name || '',
    item_brand: product?.product_brand || '',
    item_description: product?.product_description || '',
    item_bulk: product?.item_bulk || false,
    item_image: product?.product_image || '',
    item_favorite: product?.item_favorite || false,
    item_buy_price: product?.item_buy_price || 0,
    item_sell_price: product?.item_sell_price || 0,
    item_stock: {
      item_stock_stock: product?.item_stock?.item_stock_stock || '',
      item_stock_critical_stock: product?.item_stock?.item_stock_critical_stock || ''
    },
    item_category: product?.item_category?.id || '',
  });

  useEffect(() => {
    setFormData({
      item_name: product?.product_name || '',
      item_brand: product?.product_brand || '',
      item_description: product?.product_description || '',
      item_bulk: product?.item_bulk || false,
      item_image: product?.product_image || '',
      item_favorite: product?.item_favorite || false,
      item_buy_price: product?.item_buy_price || 0,
      item_sell_price: product?.item_sell_price || 0,
      item_stock: {
        item_stock_stock: product?.item_stock?.item_stock_stock || '',
        item_stock_critical_stock: product?.item_stock?.item_stock_critical_stock || ''
      },
      item_category: product?.item_category?.id || '', 
    });
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(product.id, formData);
  };



  return (
    <Modal show={show} size="lg" centered className="go-modal go-modal--edit-product" onHide={handleClose}>
 
        <Modal.Header closeButton>
        </Modal.Header>
          <Modal.Body>
          {modalLoading ? (
        <Loader insideModal={true}/>
      ) : (
        <>
          <div className='pb-3'>
          <h4 className='title mb-0'>Guardar producto en mi Inventario</h4>
          <p className='go-text go-color--grey'>Edita los campos que necesites antes de agregar este producto a tu inventario</p>
          </div>
          <ProductsItem key={product.id} product={product} isModal disableClick />
          <ItemForm
            formData={formData}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            handleSubmit={handleSubmit}
            handleCloseModal={handleClose}
            isNew={false}
            isProduct={true}
            handleFileChange={handleFileChange}
            fileName={fileName}
            categories={productCategories}
          />
        </>
        )}
        </Modal.Body>
     
    </Modal>
  );
}

export default EditProductModal;
