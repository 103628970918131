import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const CategoryModal = ({ show, onHide, selectedCategory, handleButtonClick }) => {
    return (
        <Modal show={show} onHide={onHide} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Seleccionar categoría del partido</h4>

                <h4 className="subtitle">Genero Masculino</h4>
                <p className="go-text">Seleccionar categoría masculina</p>

                <Row className="pb-4 px-2">
                    {['1ra', '2da', '3ra', '4ta', '5ta', '6ta'].map((category, index) => (
                        <Col xs={4} key={index} className='px-1 py-0'>
                            <Button
                                className={`go-btn go-btn-select--category ${selectedCategory === category ? 'selected' : ''}`}
                                onClick={() => handleButtonClick(category)}
                            >
                                {category}
                            </Button>
                        </Col>
                    ))}
                </Row>

                <h4 className="subtitle">Genero Femenino</h4>
                <p className="go-text">Seleccionar categoría femenina</p>

                <Row className="pb-4 px-2">
                    {['A', 'B', 'C', 'D'].map((category, index) => (
                        <Col xs={3} key={index} className='px-1 py-0'>
                            <Button
                                className={`go-btn go-btn-select--category ${selectedCategory === category ? 'selected' : ''}`}
                                onClick={() => handleButtonClick(category)}
                            >
                                {category}
                            </Button>
                        </Col>
                    ))}
                </Row>

                <h4 className="subtitle">Partido sin categoria</h4>
                <Row className='px-2'>
                    <Col xs={3} className='px-1 py-0'>
                        <Button
                            className={`go-btn go-btn-select--category ${selectedCategory === 'Sin categoría' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('Sin categoría')}
                        >
                            -
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default CategoryModal;
