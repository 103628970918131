import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoginForm from '../../components/Admin/LoginForm/LoginForm';
import { Row, Col, Container, Carousel } from "react-bootstrap";

function AdminLoginScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const registeredEmail = location.state?.registeredEmail;

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      navigate('/admin_dashboard');
    }
  }, [navigate]);

  return (
    <Container className='pt-0'>
      <Row className='justify-content-center go-login'>
   
        <Col xs={12} md={6} className='my-auto pe-5'>
          <h4 className='text-center'>
            <img src='/assets/logos/gmgreen.svg' alt='GoMatch' className='go-logo mb-3' height={'50px'} />
          </h4>
          <h4 className='go-text text-center'>
            Iniciar sesión
          </h4>
          <p className='go-text text-center'>Por favor, ingresar sus datos para <br></br> iniciar sesión en su cuenta.</p>
          <LoginForm registeredEmail={registeredEmail} />
        </Col>
        <Col xs={12} md={6} className='my-auto'>
        <Carousel>
          <Carousel.Item>
            <img src='/assets/illustrations/tennis.png' alt='soccer' className='w-100' />
            <Carousel.Caption>
              <h4 className='go-text'>Te ayudamos con la gestión Integral </h4>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src='/assets/illustrations/soccer.png' alt='soccer' className='w-100' />
            <Carousel.Caption>
            <h4 className='go-text'>Organización de cada uno de tus deportes favoritosl</h4>

            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src='/assets/illustrations/basquet.png' alt='soccer' className='w-100' />
            <Carousel.Caption>
            <h4 className='go-text'>Logrando la integridad en la organización</h4>

            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img src='/assets/illustrations/volley.png' alt='soccer' className='w-100' />
            <Carousel.Caption>
            <h4 className='go-text'>Entre jugadores y tu club favorito</h4>

            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminLoginScreen;

