import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './PreviewModal.scss';
import { createShareText, copyToClipboard } from '../../../../utils/Share';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { deleteScheduleById } from '../../../../utils/GoMatchApi';
import { mapBackendToFrontend, mapBackendToFrontendUser, scheduleStatus } from '../../../../utils/Functions';
import { toast } from 'react-toastify';
import Loader from '../../../Global/Loader/Loader';
import ScheduleItem from '../ScheduleItem';

const invitedImg = "/assets/illustrations/invited.png";

function SchedulePreviewModal({ show, onHide, schedule, selectedDate, selectedDuration, loadSchedules }) {
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = useState(false);
    const [deleting, setDeleting] = useState(false);

    if (!schedule) return null;

    const totalAmount = schedule.court.total_slots;
    const status = scheduleStatus(schedule);

    const handleShareClick = () => {
        const shareText = createShareText(schedule);
        copyToClipboard(shareText);
    };

    const freeSlots = totalAmount - (schedule.bookings ? schedule.bookings.length : 0);

    const sortedBookings = schedule.bookings?.slice().sort((a, b) => {
        if (a.is_guest && !b.is_guest) return 1;
        if (!a.is_guest && b.is_guest) return -1;
        return 0;
    });

    const handleDelete = async () => {
        setDeleting(true);
        try {
            const response = await deleteScheduleById(schedule.id);
            if (response.ok) {
                toast.success('Horario eliminado con éxito.');
                onHide();
                loadSchedules(); // Reload the schedules after deletion
            } else {
                toast.error('Error al eliminar el horario.');
            }
        } catch (error) {
            toast.error('Error al eliminar el horario.');
        } finally {
            setDeleting(false);
            setShowConfirm(false);
        }
    };

    const scheduleInfo = (
        <div className={deleting ? "go-disabled" : ""}>
            <Row>
                <Col md={12}>   
                    <ScheduleItem
                        key={schedule.id}
                        id={schedule.id}  // Agregar esta línea
                        schedule={schedule}
                        previewSchedule={true}
                    />
                </Col>
            </Row>
        </div>
    );

    return (
        <Modal show={show} onHide={() => { setShowConfirm(false); onHide(); }} centered className={`go-modal go-preview go-preview--${status} go-show--${showConfirm}`}>
            <Modal.Header closeButton />
            <Modal.Body>
                {!showConfirm ? (
                    <div className='go-preview-inside'>
                        <Row className='mb-3'>
                            <Col md={6} xs={6} className='text-start my-auto'>
                                <h4 className={`status ${status}`}>Horario {status}</h4>
                            </Col>
                            <Col md={6} xs={6} className='ms-auto my-auto text-end'>
                                <Button className='go-btn go-btn--share me-2' onClick={handleShareClick}>
                                    <img src='/assets/icons/share.svg' alt='share' />
                                </Button>
                                <Button className='go-btn go-btn--options me-2 text-center' 
                                    onClick={() => navigate(`/admin/gocalendar/${schedule.id}`, { state: { schedule, selectedDate } })}>
                                    <img src='/assets/icons/settings.svg' alt='Options' />
                                </Button>
                                <Button className='go-btn go-btn--options text-center' onClick={() => setShowConfirm(true)}>
                                    <img src='/assets/icons/trash.svg' alt='trash' />
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} xs={12} className='text-start'>
                                <h4 className='court'>{schedule.court.name}</h4>
                            </Col>
                            <Col md={6} xs={12}>
                                <p className='date'><img src='/assets/icons/calendar.svg' alt='Calendar' /> {moment(schedule.date).format("DD/MM/YYYY")}</p>
                            </Col>
                            <Col md={6} xs={12}>
                                <p className='time'><img src='/assets/icons/clock.svg' alt='Clock' /> {schedule.start_time} - {schedule.end_time}</p>
                            </Col>
                        </Row>

                        <hr />

                        <Row className='text-center pt-2'>
                            <Col md={4} xs={12} className='my-auto'>
                                <p className='title-small'>Disponibilidad</p>
                                <p className='available'>{!schedule.available ? 'Bloqueado' : 'Público'}</p>
                            </Col>
                            <Col md={4} xs={12} className='my-auto'>
                                <p className='title-small'>Categoría</p>
                                <p className='category'>{mapBackendToFrontend(schedule.category)}</p>
                            </Col>
                            <Col md={4} xs={12} className='my-auto'>
                                <p className='price'>${schedule.price}</p>
                            </Col>
                        </Row>

                        <hr />

                        <Row className='text-center'>
                            {sortedBookings?.map(booking => (
                                <Col md={3} xs={6} key={booking.userId || booking.id}>
                                    <div className='user-image'>
                                        {booking.is_guest ? (
                                            <LazyLoadImage  
                                                effect="blur"
                                                src={invitedImg} alt='Invitado' />
                                        ) : (
                                            <LazyLoadImage  
                                                effect="blur"
                                                src={booking.user?.profile_picture || booking.user?.profile_picture_url || invitedImg} alt='Usuario' />
                                        )}
                                    </div>
                                    <p className='user-name pt-2'>{booking.is_guest ? "invitado" : booking.user?.name + " " + booking.user?.lastname}</p>
                                 
                                    {mapBackendToFrontendUser(booking.user.padel_league) !== 'Sin categoría' && <p className='user-category'>
                                        {booking.is_guest ? "" : mapBackendToFrontendUser(booking.user.padel_league)}
                                        </p>
                                    }
                                 

                                </Col>
                            ))}
                            {Array.from({ length: freeSlots }).map((_, index) => (
                                <Col md={3} xs={6} key={`libre-${index}`}>
                                    <div className='user-image'>
                                        <LazyLoadImage  
                                        effect="blur"
                                        src="/assets/icons/free-slot.svg" alt='Libre' />
                                    </div>
                                    <p className='user-category pt-2'>Libre</p>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ) : (
                    <div className='text-start'>
                        <h4 className='title'>Eliminar horario</h4>
                        <p className='go-text'>¿Estás seguro que quieres eliminar este horario?</p>
                        {scheduleInfo}
                        {deleting && <Loader insideModal={true} />}
                        <Row className={`pt-4 ${deleting ? 'go-disabled' : ''}`}>
                            <Col md={4} xs={6} className="ms-auto">
                                <Button variant="secondary" className="go-btn go-btn--form-cancel" onClick={() => setShowConfirm(false)} disabled={deleting}>
                                    No, cancelar
                                </Button>
                            </Col>
                            <Col md={4} xs={6}>
                                <Button variant="danger" className="go-btn go-btn--danger" onClick={handleDelete} disabled={deleting}>
                                    Sí, eliminar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default SchedulePreviewModal;
