// CreateUserModal.js updated

import React from 'react';
import { Modal } from 'react-bootstrap';
import CreateUserForm from '../Forms/CreateUserForm'; // Ensure the path is correct

const CreateUserModal = ({ show, handleClose, triggerRefresh, setLoading }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar nuevo usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateUserForm
          handleSuccess={() => {
            handleClose();
            triggerRefresh();
          }}
          setLoading={setLoading}
          handleBack={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
