import React, { useState, useEffect, useContext } from 'react';
import '../../components/Global/Datepicker/Datepicker.scss';
import { Col, Row, Form, Table, Modal, Badge } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import CashRegisterRecord from './CashRegisterRecord';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useMediaQuery from '../../hooks/useMediaQuery';

const CashRegisterRecordList = ({ records, onFilterChange, users, screenLoading }) => {
  const [activeRecord, setActiveRecord] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [actionType, setActionType] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  const { id_record } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleShow = (record) => {
    setActiveRecord(record);
    navigate(`/admin/gomarket/historial-cajas/${record.id}`);
  };

  const handleClose = () => {
    setActiveRecord(null);
    navigate(`/admin/gomarket/historial-cajas`);
  };

  useEffect(() => {
    if (id_record) {
      const record = records.find(record => record.id.toString() === id_record);
      if (record) {
        setActiveRecord(record);
      }
    }
  }, [id_record, records]);

  useEffect(() => {
    onFilterChange({ startDate, actionType, selectedUser });
  }, [startDate, actionType, selectedUser]);


  const renderSkeleton = () => (
    <Row>
      <Col xs={12} sm={12} md={12} className='pb-3'>
        <Skeleton height={668} className='go-skeleton go-skeleton--card pt-3'/>
      </Col>
    </Row>
  );

  const renderRecordsAsDivs = () => (
    <div className="">
      {records.map((record) => (
        <div key={record.id} className="go-mobile-table" onClick={() => handleShow(record)}>

          <div className="go-mobile-table__header">
            <Row>
              <Col>
                <img src='/assets/icons/strongbox.svg' alt="coins" className='cashregister-icon' /> {record.cash_register_record_cash_register} 
              </Col>
              <Col>
                <Badge className={`go-badge go-badge--${record.cash_register_record_open ? 'open-list' : 'closed-list'}`}> 
                  {record.cash_register_record_open ? 'Apertura' : 'Cierre'} 
                </Badge>
              </Col>
            </Row>
          </div>
          <div className='go-mobile-table__body'>
            <Row>
              <Col xs={6}>
                <img src='/assets/icons/calendar.svg' alt="calendar" className='mb-2'/> {record.cash_register_record_date}
                <br></br>
                <img src='/assets/icons/clock.svg' alt="clock" /> {record.cash_register_record_time_whitout_seconds}
              </Col>
              <Col>
                <img src='/assets/icons/profile-circle.svg' alt="user-profile" /> {record.cash_register_record_user}
                <div className="record-detail">Apertura: ${record.cash_register_record_open_amount}</div>
                <div className="record-detail">Cierre: ${record.cash_register_record_close_amount}</div>
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Row>
        <div className='go-page-title'>
          <h4 className='go-text'><img src='/assets/icons/strongbox.svg'  /> Historial de cajas</h4>
        </div>

        <Col className='col-md-12'>
          <p className='go-text'>Seleccione los filtros correspondientes para una búsqueda más precisa en el historial de registros de caja.</p>
        </Col>
        <Col className='col-md-3 col-12'>
          <div className='go-form'>
            <Form.Select aria-label="Usuario" onChange={(e) => setSelectedUser(e.target.value)}>
              <option value="">Usuario</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>{user.name} {user.lastname}</option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>
      
      {screenLoading ? renderSkeleton() : (
        records.length > 0 ? (
          <>
            {isMobile ? renderRecordsAsDivs() : (
              <Table borderless responsive hover className='go-table go-table--orders'>
                <thead>
                  <tr>
                    <th>Caja</th>
                    <th>Fecha</th>
                    <th>Hora</th>
                    <th>Monto Apertura</th>
                    <th>Monto Cierre</th>
                    <th>Usuario</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody >
                  {records.map((record) => (
                    <tr key={record.id} onClick={() => handleShow(record)}>
                      <td><img src='/assets/icons/strongbox.svg' className='go-table go-table--icon' alt="calendar" /> {record.cash_register_record_cash_register}</td>
                      <td>{record.cash_register_record_date}</td>
                      <td>{record.cash_register_record_time_whitout_seconds} Hrs </td>
                      <td>${record.cash_register_record_open_amount}</td>
                      <td>${record.cash_register_record_close_amount}</td>
                      <td>{record.cash_register_record_user}</td>
                      <td>
                        <Badge className={`go-badge go-badge--${record.cash_register_record_open ? 'open-list' : 'closed-list'}`}> 
                          {record.cash_register_record_open ? 'Apertura' : 'Cierre'} 
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {activeRecord && (
              <Modal show={true} centered size="lg" onHide={handleClose} className='go-modal go-modal--order'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <CashRegisterRecord activeRecord={activeRecord} />
                </Modal.Body>
              </Modal>
            )}
          </>
        ) : (
          <Row>
            <Col className='col-md-12'>
              <div className='text-center text-muted py-5'>
                <p className='go-text mb-0 '>No se han encontrado registros de cajas con estos parametros</p>
                <p className='go-text'>Gestiona tus cajas en <a href="/admin/gomarket" className='go-link'>GoMarket</a></p>
              </div>
            </Col>
          </Row>
        )
      )}
    </>
  );
};

export default CashRegisterRecordList;
