// src/screens/GoMarketInventoryScreen.js
import React from 'react';
import InventoryItemList from '../../components/GoMarket/InventoryItemList'; // Adjust path as necessary
import { Container } from 'react-bootstrap';

function GoMarketInventoryScreen() {
    return (
        <Container className=' go-container'>
 
            <InventoryItemList />
        </Container>
    );
}

export default GoMarketInventoryScreen;
