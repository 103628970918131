import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Loader from "../../components/Global/Loader/Loader";
import { CashRegisterContext } from "../../contexts/GoMarket/CashRegisterContext";

export function CheckAdminPrivateRoute({ children }) {
  const { roleType } = useContext(CashRegisterContext);
  const [isLoading, setIsLoading] = useState(true); // Set initial state to true to indicate loading

  useEffect(() => {
    if (roleType !== null) {
      setIsLoading(false);
    }
  }, [roleType]);

  // Wait until the roleType is determined and loading is complete
  if (isLoading) {
    return (
      <Container className="go-container go-container--route">
        <Loader />
      </Container>
    );
  }

  // Check if the user has the admin role
  if (roleType !== 'club_administrator') {
    return <Navigate to="/error" />;
  }

  // Render the children only if the user has the admin role and loading is complete
  return children;
}

export default CheckAdminPrivateRoute;
