import React, { useState } from 'react';
import { Row, Col, Modal, Button, Form, Table } from 'react-bootstrap';
import './PaymentMethodModal.scss';
import UserListSearch from '../UserListSearch.js';
import Loader from '../../Global/Loader/Loader.js';
import useEnterKeyPress from '../../../hooks/useEnterKeyPress';

const PAYMENT_SELECTION = 'paymentSelection';
const BUSCADO_USUARIOS = 'buscadoUsuarios';
const EFECTIVO = 'efectivo';

const PaymentMethodModal = ({ 
  showModal, 
  handleClose, 
  handlePaymentMethodChange, 
  handleNumericInput, 
  handleAddDenomination, 
  amountPaid, 
  setAmountPaid, 
  total, 
  handlePayment,
  selectedUser, // Add this line
  setSelectedUser, // Add this line
  loading // Add this line
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [modalContent, setModalContent] = useState(PAYMENT_SELECTION);

  const handleMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    handlePaymentMethodChange(method);
    if (method === 'Efectivo') {
      setAmountPaid(0); // Initialize amountPaid to 0 when "Efectivo" is selected
      setModalContent(EFECTIVO);
      setSelectedUser(" ");

    } 
      else {
      setModalContent(PAYMENT_SELECTION);
      setSelectedUser(" ");
      setAmountPaid(total);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setAmountPaid(value);
  };

  const handleEnviarUsuarioClick = () => {
    setModalContent(BUSCADO_USUARIOS);
    handlePaymentMethodChange('Transferencia');
    setAmountPaid(total); // Set amountPaid to total when "Enviar a usuario" is selected
    setSelectedUser(null);
  };

  const handleBackToMethodSelectionClick = () => {
    setModalContent(PAYMENT_SELECTION);
    setSelectedPaymentMethod(null);
    setAmountPaid(0);
    setSelectedUser(null);

  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  async function handleAction(status) {
    await handlePayment(status);
    setModalContent(PAYMENT_SELECTION);
    setSelectedUser(null);
    handleClose();
    setSelectedPaymentMethod(null);
  }

  const renderPaymentSelection = () => (
    <>
      <Row>
        <Col className='col-12'>
          <h4 className='title'>Método de pago</h4>
          <p className='pb-0 pt-0 go-text'>Seleccione método de pago del cliente</p>
        </Col>
      </Row>
      <Row>
        <Col className='col-md-6 col-12 pb-3 order-1'>
          <Button 
            variant="primary" 
            className={`go-btn go-btn--payment-debit ${selectedPaymentMethod === 'Débito' ? 'selected' : ''}`} 
            onClick={() => handleMethodChange('Débito')}
          >
            <img src="/assets/icons/card.svg" alt="Debit Card" />
            Débito
          </Button>
        </Col>
        <Col className='col-md-6 col-12 pb-3 order-2'>
          <Button 
            variant="primary" 
            className={`go-btn go-btn--payment-credit ${selectedPaymentMethod === 'Crédito' ? 'selected' : ''}`} 
            onClick={() => handleMethodChange('Crédito')}
          >
            <img src="/assets/icons/card.svg" alt="Credit Card" />
            Crédito
          </Button>
        </Col>
        <Col className='col-md-6 col-12 pb-3 order-4'>
          <Button 
            variant="primary" 
            className={`go-btn go-btn--payment-transfer ${selectedPaymentMethod === 'Transferencia' ? 'selected' : ''}`} 
            onClick={() => handleMethodChange('Transferencia')}
          >
            <img src="/assets/icons/transfer.svg" alt="Transfer" />
            Transferencia
          </Button>
        </Col>
        <Col className='col-md-6 col-12 pb-3 order-3'>
          <Button 
            variant="primary" 
            className={`go-btn go-btn--payment-cash ${selectedPaymentMethod === 'Efectivo' ? 'selected' : ''}`} 
            onClick={() => handleMethodChange('Efectivo')}
          >
            <img src="/assets/icons/dollar.svg" alt="Cash" />
            Efectivo
          </Button>
        </Col>
        <Col className='col-md-12 col-12 pb-3 order-5'>
          <Button 
            className={`go-btn go-btn--menu go-btn--menu-payment w-100 ${selectedPaymentMethod === 'Transferencia' ? '' : 'go-disabled'}`} 
            onClick={handleEnviarUsuarioClick} // Change modal content on click
          >
            <Row>
              <Col className='col-md-3 col-3 text-center my-auto'>
                <div className='image-div'>
                  <img src="/assets/illustrations/email.png" alt="products" />
                </div>
              </Col>
              <Col className='col-md-9 col-9 text-start'>
                <h4 className='go-text'>Enviar datos de transferencia</h4>
                <p className='go-text'>Envia un correo al usuario con los detalles de su compra.</p>
              </Col>
            </Row>
          </Button>
        </Col>
        <Col className='col-md-12 col-12 order-6'>
          <h5 className='total'>Total: ${total}</h5>
        </Col>
      </Row>
    </>
  );

  const renderEfectivo = () => (
    <div>
      <h4 className="title">Método de pago efectivo</h4>
      <p className='go-text'>Escriba o presione los números o unidades monetarias para indicar la cantidad con la que paga el cliente.</p>
      
      <Row className='py-4 px-2'>
        {/* <Col className='col-md-5 d-none d-md-block'>
          <h4 className='go-text'>Teclado numérico</h4>
          <Row >
            {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map(num => (
              <Col key={num} md={3} xs={2} className="d-flex justify-content-center px-1 py-1">
                <Button variant="secondary" className="go-cash go-cash--num w-100" onClick={() => handleNumericInput(num)}>
                  {num}
                </Button>
              </Col>
            ))}
          </Row>
        </Col> */}
        <Col className='col-md-12 ms-auto'>
          <h4 className='go-text'> Billetes y monedas</h4>
          <Row>
            {['10', '50', '100', '500', '1000', '2000', '5000', '10000', '20000'].map(amount => (
              <Col md={4} xs={4} className=" px-1 py-1" key={amount}>
                <Button key={amount} variant="secondary" className="go-cash go-cash--money w-100" onClick={() => handleAddDenomination(parseInt(amount, 10))}>${amount}</Button>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    
      <Row className='pt-md-4 pt-0  '>
      <Col className='col-md-12'>
        <Table hover className='go-table go-table--orders'>
          <tbody>
            <tr>
              <td>Total a pagar</td>
              <td className="text-end pe-3">${total}</td>
            </tr>
            <tr>
              <td >Cliente paga con</td>
              <td className="text-end">
                <Form.Control
                  type="text"
                  value={`$${amountPaid}`}
                  onChange={handleAmountChange}
                  className="amount-paid-input ms-auto go-cash-custom-input"
                  placeholder="Ingrese monto"
                  autoFocus={true}
                  
                />
              </td>
            </tr>
            <tr>
              <td>Vuelto para el cliente</td>
              <td className="text-end go-color--green pe-3">
                ${amountPaid ? Math.max(amountPaid - total, 0) : 0}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    
     
    </div>
  );
  
  const renderUserSearch = () => (
    <div>
      <h4 className='title'>Buscador de usuarios</h4>
      <UserListSearch onUserSelect={handleUserSelect} />
    </div>
  );

  useEnterKeyPress(
    !selectedUser || !amountPaid || parseFloat(amountPaid) < total || total <= 0 
      ? () => handleAction("Pendiente") 
      : () => handleAction("Pagado"),
    showModal // Only trigger when the modal is open
  );
  
  
  return (
    <Modal show={showModal} onHide={() => {handleClose(); handleBackToMethodSelectionClick();}} centered size='lg' className='go-modal go-modal--payment'>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className='go-modal-content'>
         

          {loading ? (
         
              <Loader insideModal={true}/>
        
          ): (

            <div>
              {modalContent === PAYMENT_SELECTION && renderPaymentSelection()}
              {modalContent === EFECTIVO && renderEfectivo()}
              {modalContent === BUSCADO_USUARIOS && renderUserSearch()}

              <Row className='pt-2'>
                <Col className='col-md-4 col-6 ms-auto mb-3'>
                  <Button variant="primary" className="go-btn go-btn--form-cancel" onClick={modalContent === PAYMENT_SELECTION ? () => {handleClose(); handleBackToMethodSelectionClick();} : ()=>handleBackToMethodSelectionClick()}>Volver</Button>
                </Col>
                <Col className='col-md-4 col-6'>
                  <Button 
                      className="go-btn go-btn--form-standby"
                      variant="primary" 
                      onClick={() => handleAction("Pendiente")} 
                    >
                      Pendiente
                  </Button>
                </Col>
                <Col className='col-md-4 col-12'>
                <Button 
                    className={`go-btn go-btn--form-submit ${ !selectedUser || !amountPaid || parseFloat(amountPaid) < total || total <= 0  ? 'go-disabled' : ''}`}	
                    variant="primary" 
                    onClick={() => handleAction("Pagado")} 
                    disabled={ !selectedUser || !amountPaid || parseFloat(amountPaid) < total || total <= 0} // Update this line
                  >
                    Validar pago
                  </Button>
                </Col>
              </Row>
            </div> 
          )}
        </div>
       
      </Modal.Body>
    </Modal>
  );
  
}

export default PaymentMethodModal;
