import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Container, Row, Col, Card, Modal } from "react-bootstrap";
import {
  getScheduleBankAccount,
  createPayment,
  uploadFile,
  getUserId,
  findScheduleForPaymentDataByScheduleSlug,
} from "../../utils/GoMatchApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faCheckCircle, faTrash, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import SpinnerOverlay from "../../components/GoMatch/Spinner/SpinnerOverlay";
import { formatCurrencyCLP, splitSlug } from "../../utils/Functions";

function PaymentScreen() {
  const navigate = useNavigate();
  const { slug } = useParams();
  // const { state: paymentState } = useLocation();
  const { pathname } = useLocation();
  const [bankAccount, setBankAccount] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for the modal
  const [loggedUser, setLoggedUser] = useState(true);
  const [paymentDataState, setPaymentDataState] = useState(null);
  const scheduleId = splitSlug(slug)?.id;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const userId = await getUserId();
        if (!userId) setLoggedUser(false);
        const response = await getScheduleBankAccount(scheduleId);
        setBankAccount(response.data || response);
        if (userId) {
          const responseScheduleForPaymentData = await findScheduleForPaymentDataByScheduleSlug(slug);
          setLoggedUser(true);
          if (responseScheduleForPaymentData.ok) {
            if (!responseScheduleForPaymentData.data.userBelongsToSchedule) navigate("/pago_no_encontrado");
            else setPaymentDataState(responseScheduleForPaymentData.data);
          } else {
            navigate("/error");
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (e) => setSelectedFile(e.target.files[0]);

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      let paymentReceiptId = null;
      if (selectedFile) {
        const [uploadedFileData] = await uploadFile(selectedFile, "payment_receipts");
        paymentReceiptId = uploadedFileData.id;
      }

      const paymentData = {
        date: new Date().toISOString().split("T")[0],
        amount: paymentDataState.payingAmount,
        currency: "CLP",
        source: "Transferencia",
        token: "token-transferencia",
        status: "En proceso",
        bookingId: paymentDataState.bookingId,
        paymentReceiptId,
      };

      const paymentResult = await createPayment(paymentData);
      if (paymentResult.ok) {
        setShowModal(true);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/gocalendar"); // Redirect after 5 seconds
        }, 5000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoading) {
    return <SpinnerOverlay />; // Render the spinner when loading
  }

  if (error) return <p>Error: {error}</p>;

  const PaymentConfirmationModal = () => (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <FontAwesomeIcon icon={faCheckCircle} className="me-2" /> Comprobande subido con éxito
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>El club validará tu pago. </p>
        <p>
          Serás redirigido en 10 segundos a <a href="/admin/gocalendar">GoCalendar</a>
        </p>
      </Modal.Body>
    </Modal>
  );

  const handleRedirectToLogin = () => {
    localStorage.setItem("redirect_to", pathname);
    navigate("/login");
  };

  return (
    <Container className={isLoading ? "blurred-content go-container" : " go-container"}>
      <h1 className="pdl-title-main">Pago</h1>
      {bankAccount ? (
        <Card className="card pdl-card">
          <Card.Body>
            <h4>Detalles de transferencia</h4>
            <p>
              <strong>Nombre:</strong> {bankAccount.nombre}
            </p>
            <p>
              <strong>RUT:</strong> {bankAccount.rut}
            </p>
            <p>
              <strong>Email:</strong> {bankAccount.email}
            </p>
            <p>
              <strong>Banco:</strong> {bankAccount.banco}
            </p>
            <p>
              <strong>Tipo de Cuenta:</strong> {bankAccount.tipo_cuenta}
            </p>
            <p>
              <strong>Número de Cuenta:</strong> {bankAccount.numero_cuenta}
            </p>
            <p>
              <strong>Monto:</strong>{" "}
              {paymentDataState?.payingAmount
                ? formatCurrencyCLP(paymentDataState.payingAmount)
                : "Debes iniciar sesión para conocer el monto a pagar."}
            </p>
          </Card.Body>
          {loggedUser ? (
            <Card.Footer className="pb-3">
              <h4>Subir comprobante</h4>
              <input type="file" accept="image/png, image/jpeg" onChange={handleFileChange} />
              <Row className="pt-3 pb-2">
                <Col className="col-2 pe-0">
                  <Button variant="danger" className="w-100">
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Col>
                <Col className="col-10">
                  <Button variant="success" className="w-100" onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" /> Enviar comprobante
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          ) : (
            <Card.Footer>
              <p>Para gestionar el comprobante de pago debes iniciar sesión.</p>
            </Card.Footer>
          )}
        </Card>
      ) : (
        <p>Loading...</p>
      )}

      {loggedUser ? (
        <Fragment>
          <Row className="py-4">
            <Col>
              <Button variant="success" className="w-100">
                Pagar con Webpay
              </Button>
            </Col>
          </Row>

          <Button onClick={() => navigate("/court")} className="w-100">
            <FontAwesomeIcon icon={faArrowCircleLeft} className="me-2" />
            Volver a las canchas
          </Button>
        </Fragment>
      ) : (
        <div className="py-2">
          <Button onClick={handleRedirectToLogin} className="w-100">
            <FontAwesomeIcon icon={faRightToBracket} className="me-2" />
            Iniciar sesión
          </Button>
        </div>
      )}
      <PaymentConfirmationModal />
    </Container>
  );
}

export default PaymentScreen;
