import React, { useState, useEffect } from 'react';
import { Col, Row, Container, ButtonGroup, Button, Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AdminSchedulesNewScreen.scss';
// import ScheduleBlockItem from '../../components/Admin/AdminSchedulesNew/ScheduleBlockItem';

function AdminSchedulesNewScreen() {
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [courts, setCourts] = useState([]);
  const [hoveredIndices, setHoveredIndices] = useState({}); 
  const [currentCourt, setCurrentCourt] = useState(null);

  useEffect(() => {
      // Simulating an API call to fetch court data
      const fetchCourts = async () => {
          const courtData = [
              {
                  id: 1,
                  name: 'Cancha 1',
                  blocks: [
                      { id: 1, type: "available-v2", status: "DISPONIBLE", time: "08:00 - 08:30", price: "$3600" },
                      { id: 2, type: "available-v2", status: "DISPONIBLE", time: "08:30 - 09:00", price: "$3600" },
                      { id: 3, type: "available-v2", status: "DISPONIBLE", time: "09:00 - 09:30", price: "$3600" },
                      { id: 4, type: "gomatch", status: "GOMATCH", time: "09:30 - 10:00", user: "Nombre Usuario", players: "1", price: "24000" },
                      { id: 5, type: "p50", status: "ABONADO", time: "10:00 - 10:30", price: "3600" },
                      { id: 6, type: "p100", status: "PAGADO", time: "10:30 - 11:00", price: "3600" },
                      { id: 7, type: "blocked", status: "BLOQUEADO", time: "11:00 - 11:30", price: "3600" }
                    ]
                },
                {
                    id: 2,
                    name: 'Cancha 2',
                    blocks: [
                        { id: 8, type: "available-v2", status: "DISPONIBLE", time: "08:00 - 08:30", price: "$3600" },
                        { id: 9, type: "available-v2", status: "DISPONIBLE", time: "08:30 - 09:00", price: "$3600" },
                        { id: 10, type: "available-v2", status: "DISPONIBLE", time: "09:00 - 09:30", price: "$3600" },
                        { id: 11, type: "gomatch", status: "GOMATCH", time: "09:00 - 09:30", user: "Nombre Usuario", players: "1", price: "30000" },
                        { id: 12, type: "p50", status: "ABONADO", time: "09:00 - 09:30", price: "3600" },
                        { id: 13, type: "p100", status: "PAGADO", time: "09:00 - 09:30", price: "3600" }
                    ]
                }
            ];
            setCourts(courtData);
        };
        
        fetchCourts();
    }, []);
    
    const handleDurationClick = (duration) => {
        setSelectedDuration(duration);
    };
    
    const handleBlockClick = (block) => {
        setModalData(block);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
        setModalData(null);
  };

  const handleBlockHover = (courtId, index) => {
    setHoveredIndices((prev) => ({ ...prev, [courtId]: index }));
    setCurrentCourt(courtId);
  };
  
  const handleBlockLeave = () => {
    setHoveredIndices({});
    setCurrentCourt(null);
  };
  

  const selectedDurationInBlocks = selectedDuration === '30 min' ? 1 : selectedDuration === '60 min' ? 2 : 3;

  return (
    <Container className='go-container'>
      {/** Datepicker **/}
      <Row className='pb-4 justify-content-center'>
        <Col xs={12} md={3} className=''>
          <div className='go-datepicker go-datepicker--schedules'>
            <ReactDatePicker
              dateFormat="dd/MM/yyyy"
              className="form-control mb-3 w-100"
              placeholderText="Fecha"
              maxDate={new Date()}
              isClearable
            />
          </div>
        </Col>
        <Col xs={12} md={7}>
          <div className='go-btn go-btn--minutes'>
            <ButtonGroup aria-label="minutes">
              <Button
                onClick={() => handleDurationClick('30 min')}
                className={selectedDuration === '30 min' ? 'selected' : ''}
              >
                30 min
              </Button>
              <Button
                onClick={() => handleDurationClick('60 min')}
                className={selectedDuration === '60 min' ? 'selected' : ''}
              >
                60 min
              </Button>
              <Button
                onClick={() => handleDurationClick('90 min')}
                className={selectedDuration === '90 min' ? 'selected' : ''}
              >
                90 min
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>

     
        <Row className='justify-content-center'>
        {courts.map((court) => (
        <Col xs={12} md={5} className='' key={court.id}>
            {/** Court title and share **/}
            <Row className='pb-3'>
            <Col xs={10} md={6}>
                <div className='go-schedules-court__name'>
                <img src='/assets/icons/court.svg' alt={`Court ${court.id}`} className='pe-2' /> {court.name}
                </div>
            </Col>
            <Col xs={2} md={2} className='ms-auto text-center'>
                <div className='go-schedules-court__share'>
                <img src='/assets/icons/share.svg' alt='Share' />
                </div>
            </Col>
            </Row>

            {/** Schedule list **/}
            <Row>
           
            </Row>
        </Col>
        ))}

        </Row>
   

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Block Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData && (
            <>
              <p>Status: {modalData.status}</p>
              <p>Time: {modalData.time}</p>
              <p>Price: {modalData.price}</p>
              {modalData.user && <p>User: {modalData.user}</p>}
              {modalData.players && <p>Players: {modalData.players}</p>}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AdminSchedulesNewScreen;
