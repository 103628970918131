import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddPlayerModal from "./AddPlayerModal";
import "./BuildingMatch.scss";
import { bucketUrl, user_role_type } from "../../../utils/Constants";

function BuildingMatch({
  scheduleInfo,
  scheduleSlots,
  userId,
  userDataBuildingMatch,
  loggedUser,
  disableDeleteButton,
  handleUpdateScheduleInfo,
  handleDeleteBooking,
  handleRedirectToLogin,
}) {
  const availableSlots = scheduleSlots?.totalCourtSlots - scheduleInfo?.bookings?.length || 0;
  const scheduleInfoCopy = { ...scheduleInfo };
  const [show, setShow] = useState(false);

  const handleAddPlayer = () => {
    if (!loggedUser) {
      handleRedirectToLogin();
    } else {
      setShow(true);
    }
  };

  const setCloseAddPlayerModal = () => {
    setShow(false);
  };

  const addPlayer = () => {
    const newVisualBooking = {
      bookingSlots: 1,
      bookingIsGuest: !scheduleInfoCopy.bookings.find((booking) => booking.user.id === userId) ? false : true,
      user: {
        id: userDataBuildingMatch?.id,
        name: userDataBuildingMatch?.name,
        profilePicture: userDataBuildingMatch?.profile_picture?.url,
        padelLeague: userDataBuildingMatch?.padel_league,
        username: userDataBuildingMatch?.username,
      },
    };
    scheduleInfoCopy.bookings.push(newVisualBooking);
    handleUpdateScheduleInfo(scheduleInfoCopy);
    setShow(false);
  };

  const handleDeletePlayer = (bookingIndex) => {
    const booking = scheduleInfo.bookings[bookingIndex];
    // If exists booking.bookingId, the booking is in the database, else is a visual booking (fake visualization)
    if (booking.bookingId) {
      handleDeleteBooking(booking.bookingId);
    } else {
      scheduleInfoCopy.bookings.pop();
      handleUpdateScheduleInfo(scheduleInfoCopy);
    }
  };

  return (
    <div className="building-match">
      <AddPlayerModal show={show} setCloseAddPlayerModal={setCloseAddPlayerModal} addPlayer={addPlayer} />
      <Col className="couting-block">Invita a {availableSlots} personas más para completar el partido</Col>
      <Row className="building-match-row" xs="auto">
        {scheduleInfoCopy?.bookings?.map((booking, bookingIndex) => {
          return (
            <Col key={bookingIndex} className="building-match-row-col">
              {booking?.user?.roleType === user_role_type.CLUB_ADMIN && booking?.bookingIsGuest ? (
                <img
                  className={"building-match-row-img_circle"}
                  src={
                    scheduleInfoCopy?.clubLogo ||
                    bucketUrl+"/generic_player_gomatch"
                  }
                  alt="User img profile"
                />
              ) : (
                <img
                  className={
                    booking?.bookingIsGuest
                      ? "building-match-row-img_circle gray_scale"
                      : "building-match-row-img_circle"
                  }
                  src={
                    booking?.user?.profilePicture ||
                    bucketUrl+"/generic_player_gomatch"
                  }
                  alt="User img profile"
                />
              )}
              {booking?.bookingIsGuest ? (
                <div className="py-2">
                  <div className="building-match-row-username">
                    {booking?.user?.name?.split(" ").shift() ?? booking?.user?.username}
                  </div>
                  <div className="building-match-row-username">Invitado</div>
                </div>
              ) : (
                <div className="py-2">
                  {/* <div className="building-match-row-padel_league">
                    Cat: {booking?.user?.padelLeague}
                  </div> */}
                  <div className="building-match-row-username">
                    {booking?.user?.name?.split(" ").shift() ?? booking?.user?.username}
                  </div>
                </div>
              )}
              {booking.user.id === userId && (
                <div
                  className={
                    disableDeleteButton
                      ? "building-match-row-delete-button-disabled"
                      : "building-match-row-delete-button"
                  }
                  onClick={() => handleDeletePlayer(bookingIndex)}
                >
                  Eliminar
                </div>
              )}
            </Col>
          );
        })}
        {Array.from(Array(availableSlots)).map((s, index) => {
          return (
            <Col className="building-match-row-col" key={index}>
              <div className="building-match-row-empty_circle" onClick={() => handleAddPlayer(index)}>
                +
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default BuildingMatch;
