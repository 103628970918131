import React, { useState } from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import { findClubUserListFilterByString } from '../../utils/GoMatchApi'; // Adjust the import path as needed
import './UserListSearch.scss';
import SearchBarComponent from '../Global/SearchBar/SearchBarComponent';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UserListSearch = ({ onUserSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchChange = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim().length < 3) {
      setUserList([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const response = await findClubUserListFilterByString(value);
    if (response.ok) {
      setUserList(response.data);
    } else {
      // Handle error
      setUserList([]);
    }

    setIsLoading(false);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    onUserSelect(user);
  };

  const renderSkeletons = () => {
  
    return (
        <>
            <Row className='py-4'>
                <Col className='col-md-12'>
                <p className=' go-text text-muted '>Seleccione usuario de la lista</p>
                    <Skeleton height={196} className='go-skeleton go-skeleton--card pt-3'/>
                </Col>
            </Row>
        </>
    );
  };

  return (
    <div className="go-user-list-search">
   
        <SearchBarComponent 
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          placeholder="Ingrese nombre de usuario"
        />
 
      {isLoading ? (
        renderSkeletons()
      ) : (
        <div className='py-4'>
            <p className=' go-text text-muted '>Seleccione un usuario de la lista</p>
        <ListGroup className='go-user-list'>
          {userList.map(user => (
            <ListGroup.Item action key={user.id} onClick={() => handleUserClick(user)} className='go-user-list-item'>
                <Row>
                    <Col className="col-md-1 col-2 my-auto">
                        <div className='go-user-list-item__image'>
                            {user?.profile_picture?.formats?.thumbnail?.url ? <img src={user?.profile_picture?.formats?.thumbnail?.url } alt="User" className="go-user-list-item__image" /> : <img src="/assets/icons/user-circle.svg" alt="User" className="go-user-list-item__image" />}
                        </div>
                    </Col>
                    <Col className="col-md-10 col-10 my-auto">
                        <p className='mb-0 go-text'>{user.name} {user.lastname}</p>
                        <p className='mb-0 go-text text-muted   '>{user.email}</p>
                    </Col>
                </Row>
             
            </ListGroup.Item>
          ))}
        </ListGroup>
        </div>
      )}
      {selectedUser && (
        <div className="selected-user pb-4 mt-auto">
            <Row>
                <Col className='col-md-2 col-3   my-auto px-4'>
                    <img src='/assets/illustrations/email.svg' alt='email' className='selected-user__image w-100' />
                </Col>
                <Col className='col-md-10 col-9 my-auto'>
                    <p className='go-text my-auto'>Se enviará un correo a <b>{selectedUser.email}</b> con los detalles de la compra y datos del club, debes validar este pago en la pantalla de <a href='/admin/ventas' className='go-link'>ventas</a></p>
                </Col>
            </Row>
       
        </div>
      )}
    </div>
  );
};

export default UserListSearch;
