import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonGroup, Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';

const ShareModal = ({ show, onHide, schedules, selectedDate }) => {
    const [duration, setDuration] = useState(90);
    const [textToCopy, setTextToCopy] = useState('');
    const [clubName] = useState(schedules[0].club.name);
    const [clubAddress] = useState(schedules[0].club.address);
    const [selectedCourt, setSelectedCourt] = useState('all');
    const [selectedPeriod, setSelectedPeriod] = useState('day');

    useEffect(() => {
        const currentHour = moment().hour();
        const dayOrNight = currentHour < 18 ? 'day' : 'night';
        setSelectedPeriod(dayOrNight);
        generateAllCourtsText(dayOrNight);
    }, []);

    useEffect(() => {
        updateText(duration);
    }, [duration, selectedCourt, selectedPeriod]);

    const filterAvailableTimes = (schedules, duration) => {
        const unavailableRanges = schedules
            .filter(schedule => !schedule.available)
            .map(schedule => ({
                start: moment(schedule.start_time, 'HH:mm'),
                end: moment(schedule.end_time, 'HH:mm'),
            }));

        return schedules.filter(schedule => {
            const startTime = moment(schedule.start_time, 'HH:mm');
            const endTime = startTime.clone().add(duration, 'minutes');
            return schedule.available && !unavailableRanges.some(range =>
                startTime.isBetween(range.start, range.end, null, '[)') ||
                endTime.isBetween(range.start, range.end, null, '(]') ||
                (startTime.isBefore(range.start) && endTime.isAfter(range.end))
            );
        });
    };

    const schedulesByCourt = schedules.reduce((acc, schedule) => {
        if (!acc[schedule.court.id]) {
            acc[schedule.court.id] = {
                courtName: schedule.court.name,
                dayTimes: [],
                nightTimes: [],
            };
        }

        const startTime = moment(schedule.start_time, 'HH:mm');
        const isDayTime = startTime.isBefore(moment('18:00', 'HH:mm'));
        const availableSchedules = filterAvailableTimes(
            schedules.filter(s => s.court.id === schedule.court.id),
            duration
        );

        if (availableSchedules.some(s => s.id === schedule.id)) {
            if (isDayTime) {
                acc[schedule.court.id].dayTimes.push(schedule);
            } else {
                acc[schedule.court.id].nightTimes.push(schedule);
            }
        }

        return acc;
    }, {});

    const formatScheduleText = schedules => {
        return schedules.map(schedule => {
            const startTime = moment(schedule.start_time, 'HH:mm');
            const endTime = startTime.clone().add(duration, 'minutes');
            return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')} hrs`;
        }).join('\n      •');
    };

    const handleCopy = (times, courtId, period) => {
        setSelectedCourt(courtId);
        setSelectedPeriod(period);
        setTextToCopy(generateText(times, schedulesByCourt[courtId].courtName));
    };

    const handleCopyAll = period => {
        setSelectedCourt('all');
        setSelectedPeriod(period);
        generateAllCourtsText(period);
    };

    const handleDurationChange = (newDuration) => {
        setDuration(newDuration);
    };

    const updateText = (newDuration) => {
   

        if (selectedCourt === 'all') {
            generateAllCourtsText(selectedPeriod, newDuration);
        } else {
            const courtData = schedulesByCourt[selectedCourt];

            if (!courtData) {
                return;
            }

            const times = selectedPeriod === 'day' ? courtData.dayTimes : courtData.nightTimes;
            setTextToCopy(generateText(times, courtData.courtName, newDuration));
        }
    };

    const generateText = (times, courtName, durationOverride = duration) => {
        return `Horarios actualizados!\n🎾Disponibilidad ${clubName} 🎾\n📆 ${moment(selectedDate).format('dddd D [de] MMMM')} 🔥\n📍${clubAddress} 📌\n\n🏟️  ${courtName}\n      •${formatScheduleText(times, durationOverride)}`;
    };

    const generateAllCourtsText = (dayOrNight, durationOverride = duration) => {
        let text = `Horarios actualizados!\n🎾Disponibilidad ${clubName} 🎾\n📆 ${moment(selectedDate).format('dddd D [de] MMMM')} 🔥\n📍${clubAddress} 📌`;
        Object.entries(schedulesByCourt).forEach(([courtId, courtData]) => {
            const times = dayOrNight === 'day' ? courtData.dayTimes : courtData.nightTimes;
            if (times.length > 0) {
                text += `\n\n🏟️  ${courtData.courtName}\n      •${formatScheduleText(times, durationOverride)}`;
            }
        });
        setTextToCopy(text);
    };

    const handleCopyEditedText = () => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => toast.success('Texto copiado ¡Compártelo!'))
            .catch(err => toast.error('Error al copiar el texto: ' + err));
    };

    return (
        <Modal size='lg' show={show} onHide={onHide} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Compartir Horarios</h4>
                <p className='go-text'>Copia los horarios disponibles del día y compártelos en redes sociales</p>
                <Row>
                    <Col md={6} xs={12}>
                        <p className='go-text'><b>1.-</b> Seleccione la duración de los bloques de horario</p>
                        <Row>
                            <Col xs={8}>
                                <ButtonGroup aria-label="minutes" className='go-btn go-btn--minutes'>
                                    <Button onClick={() => handleDurationChange(60)} className={duration === 60 ? 'selected' : ''}>60 min</Button>
                                    <Button onClick={() => handleDurationChange(90)} className={duration === 90 ? 'selected' : ''}>90 min</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <p className='go-text my-4'><b>2.-</b> Seleccione la cancha que quieres compartir y el periodo</p>
                        {Object.keys(schedulesByCourt).length > 0 ? (
                            <>
                                {Object.entries(schedulesByCourt).map(([courtId, courtData]) => (
                                    <Row key={courtId} className="court-item mb-3">
                                        <Col xs={12} md={6} className='mb-3 mb-md-1'>
                                            <div className='go-schedules-court__name'>
                                                <img src='/assets/icons/court.svg' alt={`Court`} className='pe-2' /> {courtData.courtName}
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <ButtonGroup aria-label="minutes" className='go-btn go-btn--minutes'>
                                                <Button 
                                                    className={`go-btn go-btn--copy ${selectedCourt === courtId && selectedPeriod === 'day' ? 'selected' : ''}`} 
                                                    onClick={() => handleCopy(courtData.dayTimes, courtId, 'day')}
                                                >Día</Button>
                                                <Button 
                                                    className={`go-btn go-btn--copy ${selectedCourt === courtId && selectedPeriod === 'night' ? 'selected' : ''}`} 
                                                    onClick={() => handleCopy(courtData.nightTimes, courtId, 'night')}
                                                >Noche</Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                ))}
                                <Row className="court-item mb-3">
                                    <Col xs={12} md={6} className='mb-3 mb-md-1'>
                                        <div className='go-schedules-court__name share-modal'>
                                            <img src='/assets/icons/court.svg' alt={`Court`} className='pe-2' /> Todas
                                        </div>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <ButtonGroup aria-label="minutes" className='go-btn go-btn--minutes'>
                                            <Button 
                                                className={`go-btn go-btn--copy ${selectedCourt === 'all' && selectedPeriod === 'day' ? 'selected' : ''}`} 
                                                onClick={() => handleCopyAll('day')}
                                            >Día</Button>
                                            <Button 
                                                className={`go-btn go-btn--copy ${selectedCourt === 'all' && selectedPeriod === 'night' ? 'selected' : ''}`} 
                                                onClick={() => handleCopyAll('night')}
                                            >Noche</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <p>No hay horarios disponibles para compartir.</p>
                        )}
                    </Col>
                    <Col md={6} xs={12}>
                        <p className='go-text mb-3'><b>3.-</b> Edita el texto y presiona en copiar</p>
                        <Form.Group className="mb-3 go-match-settings-input" controlId="shareTextArea">
                            <Form.Control
                                as="textarea"
                                rows={14}
                                name="comment"
                                value={textToCopy}
                                onChange={(e) => setTextToCopy(e.target.value)}
                                placeholder="Texto a copiar..."
                                className="text-area"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} xs={12} className='ms-auto'>
                        <Button onClick={handleCopyEditedText} className='mt-2 go-btn go-btn--add'>Copiar Texto</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ShareModal;
