import React, { useState, useEffect } from "react";
import { handleSaveClubDataChanges } from "../../utils/GoMatchApi";
import { useLocation, useNavigate } from "react-router-dom";
import SpinnerOverlay from "../../components/GoMatch/Spinner/SpinnerOverlay";
import { Container } from "react-bootstrap";
import AdminClubForm from "../../components/Admin/Configuration/AdminClubForm";
import { toast } from "react-toastify";

const AdminEditClubScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [club, setClub] = useState({});
  const [editedClubData, setEditedClubData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchClubData = async () => {
      if (!state) {
        navigate("/admin/configuracion");
        return;
      }
      setClub({ ...state });
      setIsLoading(false);
    };
    fetchClubData();
  }, [state, navigate]);

  const handleSaveClubChanges = async () => {
    try {
      setIsLoading(true);
      const updateResponse = await handleSaveClubDataChanges(club.id, editedClubData);
      if (updateResponse) {
        toast.success("¡Datos del club actualizados correctamente!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <Container className="go-container"> 
      <AdminClubForm
        club={club}
        editedClubData={editedClubData}
        setClub={setClub}
        setEditedClubData={setEditedClubData}
        handleSaveClubChanges={handleSaveClubChanges}
      />
      {/* <Button
        variant="info"
        className="w-100 mt-2"
        onClick={() => navigate("/admin/configuracion")}
      >
        Volver
      </Button> */}
    </Container>
  );
};

export default AdminEditClubScreen;
