import React from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench  } from '@fortawesome/free-solid-svg-icons';


const RankScreen = () => {
    return (
        <Container className='go-container'>
             <h1 className='pdl-title-main mb-0'>Torneos</h1>
      
             <Row className="py-2 pdl-developing" >
                <Col className='col-12'>
                    <div className='pdl-section'>
                    <h2 className='pdl-shared-title pb-2'>Torneos <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="py-2 pdl-developing" >
                <Col className='col-12'>
                    <div className='pdl-section'>
                    <h2 className='pdl-shared-title pb-2'>Ligas <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="py-2 pdl-developing" >
                <Col className='col-12'>
                    <div className='pdl-section'>
                    <h2 className='pdl-shared-title pb-2'>Americanos <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="py-2 pdl-developing" >
                <Col className='col-12'>
                    <div className='pdl-section'>
                    <h2 className='pdl-shared-title pb-2'>Cuadrangulares <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default RankScreen;
