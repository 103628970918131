import React, { useState, useEffect } from 'react';
import { Badge, Container, Row, Col, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faUsers, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { getNextUserBirthdays, getMostFrecuentUsers, getUserAmount } from '../../utils/GoMatchApi'; // Ensure the function name matches
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AdminStatisticsScreen = () => {
    const [birthdays, setBirthdays] = useState([]);
    const [frequentUsers, setFrequentUsers] = useState([]);
    const [userAmount, setUserAmount] = useState([]);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const birthdayResponse = await getNextUserBirthdays(3);
                if (birthdayResponse.ok) {
                    setBirthdays(birthdayResponse.data);
                } else {
                    console.error('Failed to fetch birthdays:', birthdayResponse.statusText);
                }
                
                const frequentUsersResponse = await getMostFrecuentUsers(5); // Adjust the argument if needed
                if (frequentUsersResponse.ok) {
                    setFrequentUsers(frequentUsersResponse.data);
                } else {
                    console.error('Failed to fetch frequent users:', frequentUsersResponse.statusText);
                }

                const userAmountResponse = await getUserAmount(); // Adjust the argument if needed
                if (userAmountResponse.ok) {
                    setUserAmount(userAmountResponse.data);
                } else {
                    console.error('Failed to fetch frequent users:', userAmountResponse.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const isBirthdayToday = (birthday) => {
        const today = moment().format('DD/MM');
        return birthday === today;
    };

    if (isLoading) {
        return (
            <Container className="go-container">

                 <Row className='pb-2'>
                    <Col>
                         <Skeleton height={294} className='text-center pdl-section'>
                        </Skeleton>
                    </Col>
                  </Row>

                  <Row className='py-2'>
                    <Col>
                         <Skeleton height={280} className='text-center pdl-section'>
                        </Skeleton>
                    </Col>
                  </Row>

                  <Row className='py-2'>
                    <Col>
                         <Skeleton height={410} className='text-center pdl-section'>
                        </Skeleton>
                    </Col>
                  </Row>
            </Container>
        );
    }

    return (
        <Container className="go-container">

            
            <Row className='pb-2'>
               <Col className='col-12'>
                        <div className='pdl-section pb-3'>
                        <h2 className='pdl-shared-title pb-2'>Usuarios agregados</h2>

                        <Row>
                            <Col className='col-6 py-2'>
                                <div className="pdl-section pdl-section--small pdl-section--white p-0 h-100" onClick={() => navigate("/admin/lista_usuarios")}>
                                    <Button className='pdl-btn-user-list'>
                                        <h2 className='pdl-shared-title pb-1'>Lista Usuarios</h2>
                                        <h3><FontAwesomeIcon icon={faUsers} /> </h3>
                                    </Button>
                                </div>
                            </Col>
                            <Col className='col-6 py-2'>
                                <div className="pdl-section pdl-section--small pdl-section--white">
                                    <h2 className='pdl-shared-title pb-2'>Total</h2>
                                        <h3>{userAmount.totalUsersCount}</h3>
                                </div>
                            </Col>
                            <Col className='col-6 py-2'>
                                <div className="pdl-section pdl-section--small pdl-section--white">
                                    <h2 className='pdl-shared-title pb-2'>Esta semana</h2>
                                    <h3>{userAmount.usersAddedLast7Days}</h3>
                                </div>
                            </Col>
                            <Col className='col-6 py-2'>
                                <div className="pdl-section pdl-section--small pdl-section--white">
                                    <h2 className='pdl-shared-title pb-2'>Este mes</h2>
                                    <h3>{userAmount.usersAddedLast30Days}</h3>
                                </div>
                            </Col>
                    </Row>
                </div>
            </Col>
        </Row>

            {/* Cumpleaños */}
            <Row className='py-2'>
                <Col className="col-12">
                    <div className="pdl-section">
                        <h2 className='pdl-shared-title pb-2'>Próximos cumpleaños</h2>
                        <ListGroup as="ol">
                            {birthdays.length > 0 ? (
                                birthdays.map((user, index) => (
                                    <ListGroup.Item
                                        as="li"
                                        className={`d-flex justify-content-between align-items-start pdl-list-group-item ${isBirthdayToday(user.birthday) ? 'pdl-birthday' : ''}`}
                                        key={index}
                                        action
                                        onClick={() => navigate(`/${user.username}`)}
                                        
                                    >
                                         
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{user.name} {user.lastname}</div>
                                            <FontAwesomeIcon icon={faBirthdayCake} /> <span className='pdl-list-group-item--birthday'>{user.birthday}</span>
                                        </div>
                                        <Badge bg="primary" className='my-auto'>
                                            {user.ageCelebrating} años
                                        </Badge>
                                      
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <ListGroup.Item>Ningún cumpleaños próximo.</ListGroup.Item>
                            )}
                        </ListGroup>
                    </div>
                </Col>
            </Row>

            {/* Most Frequent Users */}
            <Row className='py-2'>
                <Col className="col-12">
                    <div className="pdl-section">
                        <h2 className='pdl-shared-title pb-2'>Clientes frecuentes</h2>
                        <ListGroup as="ol">
                            {frequentUsers.length > 0 ? (
                                frequentUsers.map((user, index) => (
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start pdl-list-group-item"
                                        key={index}
                                        action
                                        onClick={() => navigate(`/${user.username}`)}
                                    >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{user.name} {user.lastname}</div>
                                            <FontAwesomeIcon icon={faCalendarCheck} /> <span className='pdl-list-group-item--bookings'>{user.bookingCount} {user.bookingCount === 1 ? 'Reserva' : 'Reservas'}</span>
                                        </div>
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <ListGroup.Item>Ningún cliente frecuente.</ListGroup.Item>
                            )}
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminStatisticsScreen;
