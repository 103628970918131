import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CartProvider } from '../../contexts/GoMarket/CartContext';
import CashRegister from '../../components/GoMarket/CashRegister';
import ItemList from '../../components/GoMarket/HomeItemList';

function GoMarketInventoryScreen() {
  const [refreshBookings, setRefreshBookings] = useState(false); 
  return (
    <CartProvider>
      <Container className='go-container'>
        <Row>
        <Col className='col-md-7 col-12'>
            <ItemList refreshBookings={refreshBookings} setRefreshBookings={setRefreshBookings} />
          </Col>
          <Col className='col-md-5 col-12'>
            <CashRegister setRefreshBookings={setRefreshBookings} />
          </Col>
         
        </Row>
      </Container>
    </CartProvider> 
  );
}

export default GoMarketInventoryScreen;
