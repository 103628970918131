
export const padel_league = [
    { option: '0', value: 'Sin categoría' },
    { option: '1', value: 'Primera masculino' },
    { option: '2', value: 'Segunda masculino' },
    { option: '3', value: 'Tercera masculino' },
    { option: '4', value: 'Cuarta masculino' },
    { option: '5', value: 'Quinta masculino' },
    { option: '6', value: 'Sexta masculino' },
    { option: 'A', value: 'A femenino' },
    { option: 'B', value: 'B femenino' },
    { option: 'C', value: 'C femenino' },
    { option: 'D', value: 'D femenino' }
]

export const padel_position = [
    { option: 'Sin posición', value: 'Sin posición' },
    { option: 'Revés', value: 'Revés' },
    { option: 'Drive', value: 'Drive' },
    { option: 'Híbrido', value: 'Híbrido' }
]






