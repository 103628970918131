import React, { useState, useRef, useContext } from 'react';
import { Nav, Modal, Button, Row, Col, Badge } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CashRegisterContext } from '../../../contexts/GoMarket/CashRegisterContext';
import './GoSidebar.scss';

const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activePath, setActivePath] = useState(currentPath);
  const imgRefs = useRef({});

  const { currentPendingOrders, currentDailyBookings } = useContext(CashRegisterContext);

  const isActive = (path, exact = false) => {
    return exact ? activePath === path : activePath.startsWith(`${path}/`) || activePath === path;
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/admin_login');
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const handleNavLinkClick = (path) => (event) => {
    if (path === '/logout') {
      handleLogoutClick(event);
    } else {
      setActivePath(path);
    }
  };

  const navItems = [
    { path: '/admin_dashboard', label: 'Dashboard', icon: 'home' },
    { path: '/admin/gocalendar', label: 'GoCalendar', icon: 'calendar' },
    { path: '/admin/gomarket', label: 'GoMarket', icon: 'shop', exact: true,  badge: currentDailyBookings || 0 },
    { path: '/admin/gomarket/inventario', label: 'Inventario', icon: 'box' },
    { path: '/admin/ventas', label: 'Ventas', icon: 'ventas' },
  
    {
      path: '/admin/pendientes',
      label: 'Pendientes',
      icon: 'pendientes',
      badge: currentPendingOrders || 0 // Add badge property here
    },
    { path: '/admin/gomarket/historial-cajas', label: 'Cajas', icon: 'strongbox' },
    //{ path: '/admin/reporteria', label: 'Reporteria', icon: 'stadistics' },

    { path: '/admin/configuracion', label: 'Configurar', icon: 'gear' },
    { path: '/logout', label: 'Salir', icon: 'logout' },
  ];

  const renderNavLink = ({ path, label, icon, exact, extraClass, badge }) => {
    const isLinkActive = isActive(path, exact);
    return (
      <Nav.Link
        key={path}
        as={Link}
        to={path === '/logout' ? '#' : path}
        className={`${extraClass ? extraClass : ''} go-sidebar__item nav-link ${isLinkActive ? 'active' : ''}`}
        onClick={handleNavLinkClick(path)}
      >
        <img
          ref={(el) => (imgRefs.current[path] = el)}
          data-icon={icon}
          src={`/assets/icons/menu/${icon}-active.svg`}
          alt={label}
        />
        {label}
        {badge !== undefined && badge > 0 && (
          <div bg="danger" className={`ms-2 notification-badge ${ badge ? '' : 'd-none'} `  } >
          </div>
        )}
      </Nav.Link>
    );
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''} go-sidebar`}>
        <Nav className="flex-column">
          <Link to='/admin_dashboard' className='go-header__brand ps-4'>
            <img className="go-header__logo" src="/assets/logos/gmgreen.svg" alt="GoMatch Logo" />
            <span className='go-header__title'> GoMatch </span>
          </Link>
          {navItems.slice(0, 3).map(renderNavLink)}
          <hr className='go-sidebar__hr'></hr>
          {navItems.slice(3, 8).map(renderNavLink)}
          <hr className='go-sidebar__hr'></hr>
          {navItems.slice(8).map(renderNavLink)}
        </Nav>
      </div>

      <Modal show={showLogoutModal} className='go-modal go-modal--payment' centered onHide={handleCloseLogoutModal}>
        <Modal.Body>
          <h4 className='title'>Estás seguro que quieres salir?</h4>
          <p className='go-text'>Te esperaremos aquí mismo :)</p>
          <Row className='pt-4'>
            <Col className="col-md-6 col-12">
              <Button variant="secondary" className='go-btn go-btn--close' onClick={handleCloseLogoutModal}>
                Volver
              </Button>
            </Col>
            <Col className="col-md-6 col-12">
              <Button variant="primary" className='go-btn go-btn--add' onClick={handleLogout}>
                Cerrar sesión
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
