// src/screens/GoMarketInventoryScreen.js
import React from 'react';
import ProductsItemList from '../../components/GoMarket/ProductsItemList'; // Adjust path as necessary
import { Container } from 'react-bootstrap';


function GoMarketInventoryScreen() {
    return (
        <Container className='go-container '>
     
            <ProductsItemList />
        </Container>
    );
}

export default GoMarketInventoryScreen;
