import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PasswordResetForm from '../../components/GoMatch/PasswordReset/PasswordResetForm';
import PasswordResetRequestForm from '../../components/GoMatch/PasswordReset/PasswordResetRequestForm';
import { Container } from 'react-bootstrap';


const PasswordResetScreen = () => {
  // Use React Router's useSearchParams to check for the presence of a 'code' query parameter.
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code'); // This would be null if 'code' is not present in the URL.

  return (
    <Container>
      
      {/* Conditionally render the appropriate component based on the presence of 'code' in the URL */}
      {code ? (
        <>
          <h1 className='pdl-title-main'>Nueva contraseña</h1>
          <PasswordResetForm />
        </>
      ) : (
        <>
          <h1 className='pdl-title-main'>Olvidaste tu contraseña o deseas cambiarla?</h1>
          <PasswordResetRequestForm />
        </>
      )}
    </Container>
  );
};

export default PasswordResetScreen;
