import React, { useCallback, useEffect, useState } from "react";
import AdminPayment from "../../components/Admin/AdminPayment/AdminPayment";
import { fetchAdminPaymentData } from "../../utils/GoMatchApi";
import moment from "moment";

function AdminPaymentScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [paymentData, setPaymentData] = useState(null);

  const fetchPaymentData = useCallback(async () => {
    setIsLoading(true);
    try {
      // TODO: We are currently giving the clubId as null, but we need to change this to the real clubId
      // * On the backend, we are currently taking the first clubId from the list of clubs in case that the admin has more than one club.
      
      const clubId = null;
      const formatStartDate = moment(selectedStartDate).format("yyyy-MM-DD");
      const formatEndDate = moment(selectedEndDate).format("yyyy-MM-DD");
      const fetchData = await fetchAdminPaymentData(clubId, formatStartDate, formatEndDate);
      setPaymentData(fetchData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    fetchPaymentData();
  }, [fetchPaymentData]);


  const handleFetchPaymentsData = async (selectedStartDate, selectedEndDate) => {
    setSelectedStartDate(selectedStartDate);
    setSelectedEndDate(selectedEndDate);
    fetchPaymentData(selectedStartDate, selectedEndDate);
  };

  return (
    <div className="container go-container">
      <AdminPayment
        isLoading={isLoading}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        paymentData={paymentData}
        handleFetchPaymentsData={handleFetchPaymentsData}
      />
    </div>
  );
}

export default AdminPaymentScreen;
