import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerOverlay from '../../../components/GoMatch/Spinner/SpinnerOverlay';
import { handlePasswordResetRequest, handleCheckEmailExistence } from '../../../utils/GoMatchApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const PasswordResetRequestForm = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cooldown, setCooldown] = useState(false); // New state to track cooldown

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (cooldown) {
      toast.info('Espera un momento antes de enviar otra solicitud.');
      return;
    }
    setIsLoading(true); // Start loading
  
    // First, check if the email exists in the database
    const checkResult = await handleCheckEmailExistence(email);
    if (checkResult.ok) {
      // If the email exists, proceed with password reset request
      const result = await handlePasswordResetRequest(email);
  
      if (result.ok) {
        toast.success('Se ha enviado un correo con un enlace para restablecer tu contraseña. Revisa tu bandeja de entrada.');
        setCooldown(true);
        setTimeout(() => setCooldown(false), 60000); // Reset cooldown after 60 seconds
      } else {
        // Handle case where password reset email could not be sent
        toast.error('Error al enviar el correo. Por favor, inténtalo de nuevo.');
      }
    } else {
      // If the email does not exist, inform the user
      toast.error('El correo electrónico no está registrado en nuestra base de datos.');
    }
  
    setIsLoading(false); // Stop loading
  };
  

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row className='justify-content-center'>
        <Col className='col-12 col-md-6'>
          <div className='pdl-info'>
            <Row>
              <Col className='col-1 text-center my-auto'>
                <FontAwesomeIcon icon={faCircleInfo} className='pdl-info__icon'/> 
              </Col>
              <Col className='col-11 my-auto'>
                <p>
                  No te preocupes, enviaremos un enlace a tu correo para que puedas restablecerla.
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {cooldown && <Row className="justify-content-center">
        <Col className='col-12 col-md-6'>
          <div className='pdl-info pdl-info--danger mt-0'>
            <Row>
              <Col className='col-1 text-center my-auto'>
                <FontAwesomeIcon icon={faCircleInfo} className='pdl-info__icon'/> 
              </Col>
              <Col className='col-11 my-auto'>
                <p>
                  Espera un momento antes de enviar otra solicitud.
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>}
      <Row className='justify-content-center'>
        <Col className="col-12 col-md-4">
          <input
            type="email"
            id="email"
            required
            className='pdl-input'
            placeholder='Ingresa tu correo'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoading || cooldown} // Disable input during cooldown or loading
          />
        </Col>
        <Col className="col-12 col-md-2">
          <Button type="submit" className='btn btn-success pdl-btn w-100' disabled={isLoading || cooldown}>
            Enviar
          </Button>
        </Col>
      </Row>
      
    </form>
  );
};

export default PasswordResetRequestForm;
