import React, { useEffect, useState } from "react";
import SpinnerOverlay from "../../components/GoMatch/Spinner/SpinnerOverlay";
import AdminConfiguration from "../../components/Admin/Configuration/AdminConfiguration";
import { getAdminClubs } from "../../utils/GoMatchApi";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

function AdminConfigurationScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [clubList, setClubList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const fetchClubUserList = async () => {
      try {
        const response = await getAdminClubs();
        setClubList(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchClubUserList();
  }, []);

  const handleEditClub = (clubListIndex) => {
    navigate("/admin/configuracion/editar_club", { state: clubList[clubListIndex] });
  };

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <Container className="go-container">
      <div>
        <AdminConfiguration clubList={clubList} handleEditClub={handleEditClub} />
      </div>
    </Container>
  );
}

export default AdminConfigurationScreen;
