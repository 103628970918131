import React from 'react';
import { Accordion, AccordionButton, Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationPin, faReceipt, faSquare } from '@fortawesome/free-solid-svg-icons';

const UserMatches = ({ bookings, hasOwnership, renderItemActions, currentPage, totalPages, onPageChange }) => {
    const renderBookings = () => {
        return bookings.map((booking, index) => (
            <Accordion.Item eventKey={`${index}`} key={booking.id || booking.bookingId}>
                <AccordionButton>
                <Row>
                    <Col className='col-8'>
                        <div className='pdl-accordion-header__time'>
                            <FontAwesomeIcon icon={faClock} className='pe-2' />
                            <span className='pdl-shared-accordion__date'>{booking.scheduleDate || booking.schedule.date || null} </span> - 
                            <span className='pdl-shared-accordion__time'>{booking.scheduleStartTime || booking.schedule.start_time}</span>
                        </div>
                    </Col>
                    <Col className='col-4'>
                        <div className='pdl-accordion-header__slots'>
                            {booking.bookingStatus === "Pagado" || booking.status === "Pagado" ? <Badge bg="" className='pdl-shared-badge pdl-shared-badge--payed'>Pagado</Badge> : null}
                            {booking.bookingStatus === "Por pagar" || booking.status === "Por pagar" ? <Badge bg="" className='pdl-shared-badge pdl-shared-badge--topay'>Por Pagar</Badge> : null}
                        </div>
                    </Col>
                </Row>
                </AccordionButton>
                <Accordion.Body className='accordion-body--shared pt-4'>
                    <Row>
                        <Col className='col-5'>
                            <div className='pdl-shared-club__image'>
                                <img src={booking.clubPicture || booking.schedule.club.logo} alt={booking.clubName || booking.schedule.club.name} />
                            </div>
                        </Col>
                        <Col className='col-7'>
                            <div><FontAwesomeIcon icon={faLocationPin} className='pe-2' /> {booking.clubName || booking.schedule.club.name}</div>
                            <div><FontAwesomeIcon icon={faSquare} className='pe-2' /> {booking.courtName || booking.schedule.court.name}</div>
                            <div><FontAwesomeIcon icon={faReceipt} className='pe-2' /> {booking.bookingStatus || booking.status}</div>
                        </Col>
                    </Row>
                    {renderItemActions ? renderItemActions(booking) : null}
                </Accordion.Body>
            </Accordion.Item>
        ));
    };



    return (
       <>
        {hasOwnership ? 
            <div>
                <Accordion className="pdl-accordion pdl-shared-accordion">
                    {bookings && bookings.length > 0 ? renderBookings() : "No se encontraron reservas"}
                </Accordion>
            </div> : "No tienes reservas"
        }
         </>
    );
};

export default UserMatches;
