import React from 'react';
import { Modal, Row, Col, ListGroup } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const invitedImg = "/assets/illustrations/invited.png";

const WhoPaysModal = ({ show, onHide, selectedBookings, handleButtonClick }) => {
    const handleUserSelect = (user) => {
        handleButtonClick(user);
        onHide();
    };

    const getUserProfilePicture = (user) => {
        if(user.is_guest) {
            return invitedImg;
        } else {
            return user.profile_picture || user.profile_picture_url || invitedImg;
        }
    };

    const uniqueBookings = Array.from(new Map(selectedBookings.map(booking => [booking.userId, booking])).values());

    return (
        <Modal show={show} onHide={onHide} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Seleccionar quien paga</h4>

                <ListGroup className='go-user-list mb-4'>
                    {uniqueBookings.map(booking => (
                        <ListGroup.Item
                            action
                            key={booking.userId}
                            onClick={() => handleUserSelect(booking)}
                            className='go-user-list-item'
                        >
                            <Row>
                                <Col md={2} xs={2} className='text-center my-auto'>
                                    <div className='img-div'>
                                        <LazyLoadImage 
                                            effect="blur"
                                            src={getUserProfilePicture(booking)} 
                                            alt="img" 
                                        />
                                    </div>
                                </Col>
                                <Col md={10} xs={10} className="my-auto overflow-hidden ps-md-2">
                                    <p className='mb-0 name'>{booking.is_guest ? "Invitado" : `${booking.name} ${booking.lastname}`}</p>
                                    <p className='mb-0 email'>{booking.email}</p>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
        </Modal>
    );
};

export default WhoPaysModal;
