// Header.js
import React, { useState, useEffect, useContext } from 'react';
import './Header.scss';
import { Col, Row, Button, Container, Badge } from 'react-bootstrap';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import CashRegisterLogic from '../../GoMarket/CashRegisterLogic';
import { CashRegisterContext } from '../../../contexts/GoMarket/CashRegisterContext'; // Import the context

const Header = ({ toggleSidebar }) => {

  const [showOpenModal, setShowOpenModal] = useState(false);

  // Use the context
  const { isRegisterOpen, setIsRegisterOpen, currentUsername, currentUserId, cashRegisters, setCashRegisters } = useContext(CashRegisterContext);

  return (
    <div className='go-header'>
      <Container className='go-container go-container--header'>
        <Row>
          <Col md={8} xs={6}>
            <img
              className="go-header__toggle"
              src="/assets/icons/toggle.svg"
              alt="Toggle Sidebar"
              onClick={toggleSidebar}
            />
            <Breadcrumb />
          </Col>
          <Col md={4} xs={6} className="text-end my-auto">
            <Button
              className='go-btn go-btn--cash'
              onClick={() => setShowOpenModal(true)}
            >
              <img className="go-header__notifications" src="/assets/icons/strongbox.svg" />
              <span className='go-header__cash-text'>
              {isRegisterOpen ? (
                                <span className='go-color--green'>  <Badge className='go-badge go-badge--open'> Abierta </Badge> </span>
                              ) : (
                                <span className='go-color--black'>  <Badge className='go-badge go-badge--closed'> Cerrada </Badge>  </span>
                              )}
              </span>
            </Button>
            {/* <Button className='go-header__btn pe-0'>
              <img className="go-header__notifications" src="/assets/icons/notification-circle.svg" />
            </Button> */}
            <Link to={`/user/${currentUsername}`} className='go-header__btn ms-2' href='/'>
              <img className="go-header__user" src="/assets/icons/user-circle.svg" />
            </Link>
          </Col>
        </Row>
      </Container>

      <CashRegisterLogic
        showOpenModal={showOpenModal}
        setShowOpenModal={setShowOpenModal}
        setRegisterOpen={setIsRegisterOpen}
        setSelectedCashRegister={() => {}}
        cashRegisters={cashRegisters}
        setCashRegisters={setCashRegisters}
        currentUserId={currentUserId}
      />
    </div>
  );
};

export default Header;
