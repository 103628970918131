import { toast } from 'react-toastify';
import moment from "moment/moment";
import { resizeImage } from './Functions';
import { user_role_type } from './Constants';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:1337';

export const handleLogin = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/api/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier: username, password })
    });

    if (!response.ok) {
      const data = await response.json();
      const message = data.message || "Error en el login";
      return { ok: false, message };
    }

    const data = await response.json();
    console.log("data from login", data);
    // Use localStorage consistently
    localStorage.setItem('userToken', data.jwt);
    localStorage.setItem("name", data.user.name);
    
    
    return { ok: true };
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, message: error.toString() };
  }
};

export const handleAdminLogin = async (username, password) => {
  try {
    const loginResponse = await fetch(`${BASE_URL}/api/auth/local`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ identifier: username, password }),
    });

    if (!loginResponse.ok) {
      const data = await loginResponse.json();
      const message = data.message || "Error en el login";
      return { ok: false, message };
    }

    const data = await loginResponse.json();

    const authResponse = await fetch(`${BASE_URL}/api/user/role_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.jwt}`,
      },
    });
    if (!authResponse.ok) {
      throw new Error("Failed to fetch user data");
    }

    localStorage.setItem("userToken", data.jwt);
    localStorage.setItem("name", data.user.name);
    localStorage.setItem("id", data.user.id);

    
    const loginData = await authResponse.json();
    return { ok: true, data: loginData };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, message: error.toString() };
  }
};

export const getUserRole = async () => {
  try {
    const token = localStorage.getItem('userToken');
    const response = await fetch(`${BASE_URL}/api/user/role_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, message: error.toString() };
  }
};

export const handleRegister = async (name, lastname, email, password, navigate) => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          lastname: lastname,
          username: name + lastname + Math.floor(Math.random() * 100000),
          email: email,
          password: password,
          profile_picture: 134, 
        })
      });

      const data = await response.json();

      if (!response.ok) {
          // Extract the detailed error message from the API response
          const errorMessage = data.error?.message || 'Registration failed';
          return { ok: false, message: errorMessage };
      }

      toast.success('Registro Exitoso. Tu cuenta ha sido guardada con éxito!');
      return { ok: true, data: data };
  } catch (error) {
      console.error('Error:', error);
      toast.error('Hubo un problema en el registro. Por favor, inténtalo de nuevo más tarde :(');
      return { ok: false, error: error.message || error };
  }
};

// profile
export const getUserProfile = async () => {
  try {
    const token = localStorage.getItem('userToken');
    const response = await fetch(`${BASE_URL}/api/users/me/profile`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user profile data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null; // Return null in case of error
  }
};

// edit-profile
export const getUserData = async () => {
  try {
    const token = localStorage.getItem('userToken');
    const response = await fetch(`${BASE_URL}/api/users/me?populate=profile_picture,role`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null; // Return null in case of error
  }
};

export const getUserId = async () => {
  try {
    const token = localStorage.getItem('userToken');
    const response = await fetch(`${BASE_URL}/api/users/me?fields[0]=id`, {
      method: 'GET',
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user ID');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user ID:', error);
    return null; // Return null in case of error
  }
};

export const handleSaveChanges = async (editableUserData, id) => {
  try {
    const token = localStorage.getItem('userToken');
    const data = {...editableUserData};
    console.log(data)
    const response = await fetch(`${BASE_URL}/api/user/update_user/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data})
    });

    if (!response.ok) {
      throw new Error('Failed to update user data');
    }

    const updatedUserData = await response.json();
    console.log(updatedUserData)
  
    return updatedUserData; // Return the updated user data
  } catch (error) {
    toast.error('Hubo un problema al guardar, Por favor, inténtalo de nuevo más tarde :(');
    return null; // Return null in case of error
  }
};

export const handleDeleteAccount = async (userId, navigate) => {
  try {
    const confirmed = window.confirm('Are you sure you want to delete your account?');
    if (!confirmed) {
      return;
    }

    const token = localStorage.getItem('userToken');
    const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete account');
    }

    localStorage.removeItem('userToken');
    alert('Cuenta eliminada del registro, hasta pronto!'); // Using alert as a simple replacement for Toast

    // Redirect to the login page
    navigate('/login');
  } catch (error) {
    console.error('Error deleting account:', error);
    alert('No se pudo eliminar la cuenta, Intentelo de nuevo más tarde'); // Using alert as a simple replacement for Toast
  }
};

// export const getAllUserSchedules = async (date) => {
//   const token = localStorage.getItem('userToken');
//   try {
//     const response = await fetch(`${BASE_URL}/api/users/me?populate[clubs][fields][0]=name&populate[clubs][populate][courts][fields][0]=name&populate[clubs][populate][courts][populate][schedules][fields][0]=*&fields[0]=name`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`,
//       },
//     });
    
//     if (!response.ok) {
//       // Instead of throwing an error, we return a response that indicates failure
//       return { ok: false, data: await response.json() };
//     }
//     const data = await response.json();
//     // Return a response that indicates success
//     return { ok: true, data: data };
//   } catch (error) {
//     // Return a response that indicates an error
//     console.error('Error:', error);
//     return { ok: false, error: error };
//   }
// };

export const createSchedule = async (schedule) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/create_schedule_by_admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(schedule),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const fetchUserBookings = async (page = 1, limit = 5) => {
  const token = localStorage.getItem('userToken');
  const response = await fetch(`${BASE_URL}/api/my-bookings-custom?page=${page}&limit=${limit}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user bookings');
  }

  const data = await response.json();
  return { ok: true, ...data };
};



export const fetchUserSchedulesByDate = async (date) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/available_schedules/${moment(date).format()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}



export const findScheduleBySlug = async (slug) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/slug/${slug}`, {
      method: "GET",
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });
    
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const findScheduleById = async (id) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/find_schedule/${id}`, {
      method: "GET",
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });
    
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const findScheduleForPaymentDataByScheduleSlug = async (slug) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/payment_info/${slug}`, {
      method: "GET",
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });
    
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const findScheduleSlotsById = async (id) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/slots/${id}`, {
      method: "GET",
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });
    
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

// * Consider to customize this function in backend to check validations like: slots available in schedule
export const createBooking = async (bookingData) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/bookings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          match_type: bookingData.match_type ?? "Público",
          reservation_date: bookingData.reservation_date ?? moment().format("YYYY-MM-DD"),
          status: bookingData.status ?? "Por pagar",
          slug: bookingData.slug ?? "slug",
          slots: bookingData?.bookingSlots ?? 1,
          is_guest: bookingData.isGuest ?? false,
          payment_percentage: bookingData.paymentPercentage ?? "p0",
          schedule: {
            id: bookingData.scheduleId,
          },
          users_permissions_user: {
            id: bookingData.userId,
          },
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};

// * Deprecated updateBookingSlots function. We are not currently using slots in the booking model.
// export const updateBookingSlots = async (bookingData) => {
//   const token = localStorage.getItem("userToken");
//   try {
//     const response = await fetch(`${BASE_URL}/api/bookings/${bookingData.bookingId}`, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify({
//         data: {
//           slots: bookingData?.bookingSlots ?? 1,
//         }
//       }),
//     });
//     if (!response.ok) {
//       return { ok: false, data: await response.json() };
//     }
//     const data = await response.json();
//     return { ok: true, data: data };
//   } catch (error) {
//     console.error("Error:", error);
//     return { ok: false, error: error };
//   }
// }




export const deleteBooking = async (bookingId) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/booking/delete_booking/${bookingId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};



export const getBuildingMatchUserData = async () => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(
      `${BASE_URL}/api/users/me?populate[profile_picture][fields][0]=url&fields[0]=id&fields[1]=name&fields[2]=padel_league&fields[3]=username`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};

export const getScheduleBankAccount = async (id) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/bank-account/${id}`, {
      method: 'GET',
      headers: token
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      : {
          "Content-Type": "application/json",
        },
    });
    
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

export const createPayment = async (paymentData) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/payments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          date: paymentData.date,
          amount: paymentData.amount,
          currency: paymentData.currency,
          source: paymentData.source,
          token: paymentData.token,
          status: paymentData.status,
          booking: {
            id: paymentData.bookingId,
          },
          payment_receipt:{
            id: paymentData.paymentReceiptId,
          }
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};

export const uploadFile = async (originalFile, folder) => {
  try {
    const token = localStorage.getItem('userToken');
    // First, await the resizeImage operation to complete
    const resizedBlob = await resizeImage(originalFile, 1024, 1024);

    // Prepare the formData with the resized image
    const formData = new FormData();
    formData.append('files', resizedBlob, originalFile.name);
    formData.append('path', folder);

    // Then proceed with the upload
    const response = await fetch(`${BASE_URL}/api/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('File upload failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error; // Ensure this error is caught and handled in the component
  }
};


// export const uploadFileOld = async (file, folder) => {
//   const formData = new FormData();

//   // Splitting the file name from its extension
//   const dotIndex = file.name.lastIndexOf('.');
//   const nameWithoutExtension = file.name.substring(0, dotIndex);
//   const randomStr = randomString(10); // Assuming randomString(10) generates a random string of 10 characters

//   // Append only to the name without the extension
//   const newPath = nameWithoutExtension + "_" + randomStr;

//   formData.append('files', file);
//   formData.append('path', `${folder}`);

//   const token = localStorage.getItem('userToken');
//   const response = await fetch(`${BASE_URL}/api/upload`, {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     body: formData,
//   });

//   if (!response.ok) {
//     throw new Error('File upload failed');
//   }

//   return response.json(); // This should return the uploaded file information, including its ID
// };



export const fetchSchedulesByDate = async (clubId, date) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedules/${clubId}/${date}`, {
      method: 'GET',
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });

    if (!response.ok) {
      // Instead of throwing an error, we return a response that indicates failure
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    // Return a response that indicates success
    return { ok: true, data: data };
  } catch (error) {
    // Return a response that indicates an error
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}


export const fetchSchedulesAndGhostSchedulesByDate = async (date, duration = 30) => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/get_schedules_and_ghost_schedules/${date}/${duration}`, {
      method: 'GET',
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });

    if (!response.ok) {
      // Instead of throwing an error, we return a response that indicates failure
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    // Return a response that indicates success
    return { ok: true, data: data };
  } catch (error) {
    // Return a response that indicates an error
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

export const deleteScheduleById = async (scheduleId) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/delete_schedule/${scheduleId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};


export const getUserClubData = async() => {
  const token = localStorage.getItem('userToken');
  try {
    const response = await fetch(`${BASE_URL}/api/admin/club`, {
      method: 'GET',
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
    });

    if (!response.ok) {
      // Instead of throwing an error, we return a response that indicates failure
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    // Return a response that indicates success
    return { ok: true, data: data };
  } catch (error) {
    // Return a response that indicates an error
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

export const updateBookingStatus = async (bookingId, newStatus) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/bookings/update_status/${bookingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          status: newStatus,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
   return { ok: false, error: error };
  }
};

export const updateSchedulePrice = async (scheduleId, updatedPrice) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/update_price/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          price: updatedPrice,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const updateScheduleAvailability = async (scheduleId, availability) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/update_availability/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          available: availability,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const updateScheduleEmailStatus = async (scheduleId, email_sent) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/update_email_sent/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          email_sent: email_sent,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const updateScheduleCategory = async (scheduleId, category) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/category/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          category: category,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const uploadClubLogo = async (originalFile, clubName) => {
  try {
    const token = localStorage.getItem('userToken');
    // Resize the image first and wait for the Blob result
    const resizedBlob = await resizeImage(originalFile, 1024, 1024);

    // Prepare the formData with the resized image
    const formData = new FormData();
    formData.append('files', resizedBlob, originalFile.name);
    formData.append('path', `club_logos/${clubName}`);

    // Then proceed with the upload
    const response = await fetch(`${BASE_URL}/api/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('File logo upload failed');
    }

    return await response.json(); // This should return the uploaded file information
  } catch (error) {
    console.error('Error uploading club logo:', error);
    throw error; // Ensure this error is caught and handled properly
  }
};


export const getClubUserList = async (page = 1, limit = 10) => {
  const token = localStorage.getItem('userToken');
  const response = await fetch(`${BASE_URL}/api/admin/club_user_list?page=${page}&limit=${limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const data = await response.json();
  return { ok: true, ...data };
};

export const getNextUserBirthdays = async (amount) => {
  const token = localStorage.getItem('userToken');
  const response = await fetch(`${BASE_URL}/api/admin/birthdays/${amount}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const data = await response.json();
  return { ok: true, ...data };
}

export const getMostFrecuentUsers = async (amount) => {
  const token = localStorage.getItem('userToken');
  const response = await fetch(`${BASE_URL}/api/admin/frecuent_users/${amount}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const data = await response.json();
  return { ok: true, ...data };
}

export const getUserAmount = async () => {
  const token = localStorage.getItem('userToken');
  const response = await fetch(`${BASE_URL}/api/admin/user_amount`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
   
  });
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const data = await response.json();
  return { ok: true, ...data };
}





export const findClubUserListFilterByString = async (stringToSearch = null) => {
  const token = localStorage.getItem('userToken');
  const response = await fetch(`${BASE_URL}/api/admin/club_user_list/${stringToSearch}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    return { ok: false, data: await response.json() };
  }
  const data = await response.json();
  return { ok: true, data: data };
}

export const getAdminClubs = async () => {
  const token = localStorage.getItem("userToken");
  const response = await fetch(`${BASE_URL}/api/admin/club_list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    return { ok: false, data: await response.json() };
  }
  const data = await response.json();
  return { ok: true, data: data };
};

export const handleSaveClubDataChanges = async (clubId, editedClubData) => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${BASE_URL}/api/admin/club/edit_club_info/${clubId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: editedClubData }),
    });

    if (!response.ok) {
      throw new Error("Failed to update user data");
    }

    const updatedUserData = await response.json();
    return updatedUserData;
  } catch (error) {
    toast.error("Hubo un problema al guardar, Por favor, inténtalo de nuevo más tarde :(");
    return null; // Return null in case of error
  }
};

export const handleSavePriceListsChanges = async (priceListId, editedPriceListsData) => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${BASE_URL}/api/admin/club/edit_club_info/price_list/${priceListId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: editedPriceListsData }),
    });

    if (!response.ok) {
      throw new Error("Failed to update user data");
    }

    const updatedUserData = await response.json();
    return updatedUserData;
  } catch (error) {
    toast.error("Hubo un problema al guardar los cambios de la Lista de Precios. Por favor, inténtalo de nuevo más tarde.");
    return null; // Return null in case of error
  }
};

export const fetchAdminPaymentData = async (clubId = null, startDate, endDate, courtId = null) => {
  try {
    const userRole = await getUserRole();
    // Validate user admin role. Must be 'club_administrator', else throw error
    if (userRole.data.user !== user_role_type.CLUB_ADMIN || !userRole.ok) {
      throw new Error("Failed to update user data");
    }
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${BASE_URL}/api/admin/schedule/payment_data/${clubId}/${startDate}/${endDate}/${courtId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to update user data");
    }

    const adminPaymentsData = await response.json();
    return adminPaymentsData;
  } catch (error) {
    toast.error("Hubo un problema al traer la información de pagos. Por favor, inténtalo de nuevo más tarde.");
    return null;
  }
};

export const createClubUser = async (userData) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/create/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData), // Send userData as JSON
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};


// password reset

export const handlePasswordResetRequest = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/api/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // user's email
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    return { ok: true };
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

export const handlePasswordReset = async (code, password, passwordConfirmation) => {
  try {
    const response = await fetch(`${BASE_URL}/api/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, password, passwordConfirmation }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    return { ok: true };
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

export const handleCheckEmailExistence = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/api/users/check-email/${encodeURIComponent(email)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // Removed the body
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const data = await response.json(); // Assuming the server returns JSON data
    return { ok: data.emailExists }; // Adjust this based on how your server indicates the email existence
  } catch (error) {
    console.error('Error:', error);
    return { ok: false, error: error };
  }
}

export const matchConfirmationAndSendEmail = async (scheduleId) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/match_confirmation/${scheduleId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}


// SharedProfile Edit-Profile Refactor

export const fetchSharedUserProfile = async (username) => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${BASE_URL}/api/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,

      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null; // Return null in case of error
  }
}

export const fetchLoggedInUser = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${BASE_URL}/api/users/me?populate=role`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null; // Return null in case of error
  }
}

export const fetchUserById = async (userId) => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null; // Return null in case of error
  }
}

export const handleToggleSchedules = async (scheduleId) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/hide_or_reveal_nearby_schedules/${scheduleId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

// Update the hide attribute of the schedule to true
export const hideSchedule = async (scheduleId) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/hide_schedule/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          hide: true,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const addToUserWaitingList = async (userData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/user-waiting-list/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        message: userData.message,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to add user to waiting list');
    }

    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error.message };
  }
};


// Update the hide attribute of the schedule to false
export const revealSchedule = async (scheduleId) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/schedule/reveal_schedule/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          hide: false,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
};

export const updateBookingPayerById = async (bookingId, percentage) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/booking/update_payer/${bookingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          payment_percentage: percentage,
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const saveScheduleCommentByScheduleId = async (scheduleId, comment) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/schedule/save_comment/${scheduleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          comment: String(comment),
        }
      }),
    });
    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }
    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
  
  
}


// Bookings

export const getDailyPendingBookings = async (page = 1, limit = 8, search = '')=> {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/booking/daily_pending_bookings?page=${page}&limit=${limit}&search=${search}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const getPendingBookings = async (page = 1, limit = 10, search = '') => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/booking/find_pending_bookings?page=${page}&limit=${limit}&search=${search}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const getPendingBookingsAndOrders = async (page = 1, limit = 10) => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/booking/find_pending_bookings_and_orders?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}

export const getOrders = async () => {
  const token = localStorage.getItem("userToken");
  try {
    const response = await fetch(`${BASE_URL}/api/admin/order/get_sales_report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return { ok: false, data: await response.json() };
    }

    const data = await response.json();
    return { ok: true, data: data };
  } catch (error) {
    console.error("Error:", error);
    return { ok: false, error: error };
  }
}


