// src/components/GoMarket/ProductsItem.js
import React from 'react';
import { Card, Row, Col, Badge } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ProductsItem = ({ product, onClick, isModal }) => {
    const handleCardClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Col className={isModal ? 'col-md-12 col-12 mb-4' : 'col-md-6 col-12 mb-4'}>
            <div className={`gomarket-card gomarket-card--inventory ${product.product_already_added ? 'added' : ''}`}>
                <Card style={{ width: '100%' }} onClick={handleCardClick}>
                    <Row>
                        <Col md={3} xs={4} className='my-auto'>
                            <div className='gomarket-card--inventory-image'>
                            <LazyLoadImage
                                    alt={product.product_name}
                                    effect="blur"
                                    src={product.product_image}
                                    className="card-img-top gomarket-card__image"
                                    />
                            </div>
                        </Col>
                        <Col md={9} xs={8}>
                            <Card.Body>
                                <Row>
                                    <Col className="col-12">
                                        <Card.Title className="gomarket-card--inventory-title">{product.product_name}</Card.Title>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-7 col-12">
                                        <Card.Text className="gomarket-card--inventory-brand">{product.product_brand}</Card.Text>
                                        <Card.Text className="gomarket-card--inventory-category">
                                            <Badge className='gomarket-card__badge go-badge--category'>{product.product_category}</Badge>
                                        </Card.Text>
                                        <Card.Text className="gomarket-card--inventory-barcode">{product.product_barcode}</Card.Text>
                                    </Col>
                                    <Col className="col-md-5 col-12">
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Col>
    );
};

export default ProductsItem;
