import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProductsItem from './ProductsItem';
import { fetchProducts, createItemByProduct, searchProductsByString, getProductsCategories } from '../../utils/GoMarketApi';
import SearchBarComponent from '../../components/Global/SearchBar/SearchBarComponent';
import EditProductModal from './Modals/EditProductModal';
import GoPagination from '../Global/Pagination/GoPagination'; // Import the new pagination component
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductsItemList = () => {
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // You can adjust the limit as needed
    const [pagination, setPagination] = useState({ totalProducts: 0, totalPages: 1 });
    const [loading, setLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);
    const [uploading, setUploading] = useState(false); // Add this state
    const [fileName, setFileName] = useState('Subir imagen'); // Add this state
    const [imageFile, setImageFile] = useState(null); // Add this state
    const [imagePreview, setImagePreview] = useState(''); // Add this state
    const [productCategories, setProductCategories] = useState([]); // Add this state


    useEffect(() => {
        const loadProducts = async () => {
            setLoading(true);
            try {
                const data = searchTerm.length >= 3 
                    ? await searchProductsByString(searchTerm, page, limit)
                    : await fetchProducts(page, limit);
    
                if (data) {
                    setProducts(data.products);
                    setPagination(data.pagination);
                } else {
                    console.error('Failed to load products');
                }
            } catch (error) {
                console.error(`Error ${searchTerm.length >= 3 ? 'searching' : 'fetching'} products:`, error);
            }
            setLoading(false);
        };

        const fetchCategories = async () => {
            const fetchedCategories = await getProductsCategories();
            setProductCategories(fetchedCategories);
        };

        fetchCategories();
    
        loadProducts();
    }, [searchTerm, page, limit]);
    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(1); // Reset to the first page when searching
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setFileName(file.name); // Set the file name
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
               
            };
            reader.readAsDataURL(file);
        }
    };
    

    const handleSaveProduct = async (productId, productData) => {
        setModalLoading(true);
        try {
            const response = await createItemByProduct(productId, {
                item_name: productData.item_name,
                item_brand: productData.item_brand,
                item_barcode: productData.item_barcode,
                item_description: productData.item_description,
                item_bulk: productData.item_bulk,
                item_image: productData.item_image,
                item_favorite: productData.item_favorite,
                item_buy_price: productData.item_buy_price,
                item_sell_price: productData.item_sell_price,
                item_stock: {
                    item_stock_stock: productData.item_stock_stock,
                    item_stock_critical_stock: productData.item_stock_critical_stock
                }
            });

            if (response) {
                toast.success(`Producto creado con éxito`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                const newProducts = await fetchProducts(page, limit);
                if (newProducts) {
                    setProducts(newProducts.products);
                    setPagination(newProducts.pagination);
                    
                } else {
                    console.error('Failed to fetch products');
                }
             
            } else {
                console.error('Failed to create item');
             

            }
        } catch (error) {
            console.error('Error creating item:', error);
        }
        setModalLoading(false);

        handleCloseModal();
    };

    const renderSkeletons = () => {
        const skeletons = [];
        for (let i = 0; i < 10; i++) {
          skeletons.push(
            <Col key={i} xs={12} sm={6} md={6} className='pb-3'>
              <Skeleton height={162} className='go-skeleton go-skeleton--card pt-3'/>
            </Col>
          );
        }
        return skeletons;
    };

    return (
        <>
            <Row className="mb-4">
                    <div className='go-page-title pb-4'>
                        <h4 className='go-text'><img src='/assets/icons/productos.svg' className='go-filter--black' /> Listado de Productos</h4>
                    </div>
                <Col md={6}>
                    <SearchBarComponent searchTerm={searchTerm} handleSearchChange={handleSearchChange} placeholder="Busca productos disponibles"/>
                </Col>
            </Row>
            {loading ? (
                <Row>
                    {renderSkeletons()}
                </Row>
            ) : (
                <>
                    <Row>
                        {products.map(product => (
                            <ProductsItem key={product.id} product={product} onClick={() => handleProductClick(product)} />
                        ))}
                    </Row>
                    <Row className="mt-3">
                        <Col className="">
                            <GoPagination
                                page={page}
                                totalPages={pagination.totalPages}
                                onPageChange={handlePageChange}
                            />
                        </Col>
                    </Row>
                    {selectedProduct && (
                        <EditProductModal
                            show={showModal}
                            handleClose={handleCloseModal}
                            product={selectedProduct}
                            handleSave={handleSaveProduct}
                            handleFileChange={handleFileChange} // Pass the handleFileChange function
                            handleCloseModal={handleCloseModal} // Pass the handleCloseModal function
                            fileName={fileName}
                            imagePreview={imagePreview} // Pass the imagePreview state
                            productCategories={productCategories}
                            modalLoading={modalLoading}
                        />
                    )}
                </>
            )}
        </>
    );
    
};

export default ProductsItemList;
