
import React, { useState, useEffect } from 'react';
import { getUserProfile } from '../../utils/GoMatchApi';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SpinnerOverlay from '../../components/GoMatch/Spinner/SpinnerOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './ProfileScreen.scss';

const ProfileScreen = () => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getUserProfile()
            .then(data => {
                if (data && data.id) {
                    setUserData(data);
                } else {
                    console.error('Invalid user data received:', data);
                }
                setIsLoading(false);
            });
    }, []);

    const navigateToEdit = () => {
        navigate('/edit-profile'); 
    };

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        navigate('/admin_login');
        window.location.reload();
      };

    if (isLoading) return <SpinnerOverlay />;

    return (
        <Container className='go-container'>
            <h1 className='pdl-title-main'></h1>
          

            <Row>
                <Col className="col-4">
                    <div className='pdl-profile__img'>
                        <img src={userData?.profilePicture} alt="profile" />
                    </div>
                </Col>
                <Col className="col-8 my-auto">
                    <p className='pdl-profile__name'> {userData?.name}{" "}{userData?.lastname}</p>
                    <p className='pdl-profile__rank'>Ranking GoMatch: #720 <FontAwesomeIcon icon={faArrowUp} /> <FontAwesomeIcon icon={faArrowUp} /> </p>
                   

                </Col>
            </Row>

            <Row>
                <Col className='pdl-profile__ranking'>
                </Col>
            </Row>

            <Row>
                <Col className="col-4">
                    <h4>📊 Datos </h4>
                    <p>{userData?.birthdate}</p>
                    <p>{userData?.padelLeague}</p>
                    <p>{userData?.padelPosition}</p>
                </Col>
                <Col>
                    <h4>🏆 Medallero </h4>

                    <p>🥇 <a href='#'>5ta Santiago Padel </a> </p>
                    <p>🥈 <a href='#'>5ta Pro Padel </a></p>
                    <p>🥇 <a href='#'>6ta Santiago Padel</a> </p>

                </Col>

            </Row>

            
            
            

            <Button variant="primary" className="w-100 mt-2" onClick={navigateToEdit}>
                Editar Perfil
            </Button>
            <Button 
                variant="secondary" 
                className="w-100 mt-2"
                onClick={handleLogout}
            >
                Cerrar Sesión
            </Button>
        </Container>
    );
};

export default ProfileScreen;

