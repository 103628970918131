import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Breadcrumb/Breadcrumb.scss';

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const formatBreadcrumbText = (string) => {
    return string
      .replace(/[-_]/g, ' ') // Replace underscores and dashes with spaces
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words back into a single string
  };

  const replaceGhostWithNuevaReserva = (string) => {
    if (string.includes("ghost")) {
      return string.replace(/ghost.*/, "Nueva reserva");
    }
    if (string.includes("admin_dashboard")){
      return string.replace(/admin_dashboard/, "Home");
    }

    return string;
  };

  const customizeSpecialRoutes = (string) => {
    if (string === "gomarket") {
      return "GoMarket";
    }
    if (string === "gocalendar") {
      return "GoCalendar";
    }

    return string;
  };

  return (
    <nav className="go-breadcrumb">
      <ul>
        <li>
          <Link to="/admin_dashboard">Dashboard</Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          // Exclude the base 'admin' route and the 'Home' route
          if (to === '/admin' || to === '/' || to === '/user') {
            return null;
          }

          let breadcrumbText = value;
          breadcrumbText = isLast ? replaceGhostWithNuevaReserva(breadcrumbText) : breadcrumbText;
          breadcrumbText = customizeSpecialRoutes(breadcrumbText);

          return (
            <li key={to}>
              {isLast ? (
                <span>{formatBreadcrumbText(breadcrumbText)}</span>
              ) : (
                <>
                  <Link to={to}>{formatBreadcrumbText(breadcrumbText)}</Link>
                  {index !== 0 && <span> </span>}
                </>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
