import React, { useState, forwardRef } from 'react';
import { Col, Row, Button, Dropdown, Container, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import "./AdminUserList.scss";
import SpinnerOverlay from "../../GoMatch/Spinner/SpinnerOverlay";
import CreateUserModal from '../Modals/CreateUserModal';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { bucketUrl } from '../../../utils/Constants';

function AdminUserList({ clubUserList, pagination, setPage, findUserText, isSearching, handleFindUsers, setLoading, triggerRefresh }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // Simplify the pagination buttons to avoid overflow
  const maxPagesToShow = 3; // You can adjust this number based on your design
  let items = [];
  let startPage = Math.max(pagination.page - Math.floor(maxPagesToShow / 2), 1);
  let endPage = Math.min(startPage + maxPagesToShow - 1, pagination.pageCount);

  // Adjust startPage if we're at the last few pages
  if (pagination.pageCount - startPage < maxPagesToShow) {
    startPage = Math.max(pagination.pageCount - maxPagesToShow + 1, 1);
  }

  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number === pagination.page} onClick={() => handleChangePage(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  const redirectToUserProfile = (username) => {
    navigate(`/${username}`);
  };

  console.log(pagination)

  return (

    <>

    <div className="pdl-section mb-3" aria-current="true">
        <div className=" w-100 justify-content-between">
        <Row>
          <Col className="col-9">
          <input
            className="user-list-search"
            onChange={(e) => handleFindUsers(e.target.value)}
            value={findUserText}
            placeholder="Nombre o correo"
          />
          </Col>
          <Col className="col-3">
          <Button variant="primary" className="add-user-button pdl-user-list-add-user" onClick={handleShowModal}><FontAwesomeIcon icon={faUserPlus} /></Button>
       
          </Col>
        </Row>

        </div>
    </div>

    
      {/* <Row>
        <Col className="col-6">
          <div className="pdl-section mb-3">
            <h4 className="mb-0">Usuarios: <b> {pagination.total}</b></h4>
          </div>
        </Col>
      </Row> */}
  

    <div className="list-group pdl-section">
      

      <CreateUserModal show={showModal} handleClose={handleCloseModal} triggerRefresh={triggerRefresh} setLoading={setLoading} />
      
     

      {isSearching ? (
        <SpinnerOverlay />
      ) : !clubUserList.length ? (
        <div className="list-group-item list-group-item-action pdl-user-list-item" aria-current="true">
          <h5 className="mb-1">No hay registros para mostrar.</h5>
        </div>
      ) : (
        clubUserList.map((user, index) => (
          <div key={index} className="list-group-item list-group-item-action pdl-user-list-item" onClick={() => redirectToUserProfile('user/'+user.username)} > 
            <Row className="pt-2 pb-2">
              <Col className="col-3 my-auto">
                <div className="user-list-row-col">
                  <img
                    className="user-list-row-img_circle"
                    src={
                      user.profile_picture_url
                        ? user.profile_picture_url
                        : bucketUrl+"/generic_player_gomatch.png"
                    }
                    alt="profile"
                  />
                </div>
              </Col>
              <Col className="col-9 my-auto">
                <h5 className="mb-1">
                {user.name ? user.name : user.username} {user.lastname ? user.lastname : " "}
                </h5>
                <small className="text-body-secondary">
              {user.phone ? user.phone : "sin teléfono"} <br></br>
               {user.email ? user.email : "sin correo"}
                </small>
              </Col>
              
            </Row>
          </div>
        ))
      )}
         {!isSearching && findUserText === "" && (
          <Row>
            <Col>
              <Pagination className="justify-content-center mt-4 pdl-pagination">
                <Pagination.First onClick={() => handleChangePage(1)} disabled={pagination.page === 1} />
                <Pagination.Prev onClick={() => handleChangePage(pagination.page - 1)} disabled={pagination.page === 1} />
                {items}
                <Pagination.Next onClick={() => handleChangePage(pagination.page + 1)} disabled={pagination.page >= pagination.pageCount} />
                <Pagination.Last onClick={() => handleChangePage(pagination.pageCount)} disabled={pagination.page >= pagination.pageCount} />
              </Pagination>
            </Col>
          </Row>
        )}

    </div>
    </>
  );
}

export default AdminUserList;
