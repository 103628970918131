import moment from 'moment';
import { mapBackendToFrontend, mapFrontendToBackend } from './Functions';
import { toast } from 'react-toastify';

export const createShareText = (schedule, category) => {
    let slotCounter = 1;
    let playersList = [];

    schedule.bookings.forEach((booking) => {
        playersList.push(`${slotCounter++}️⃣ ${booking.name || booking.user?.name }`);
        for (let i = 1; i < booking.slots; i++) {
            playersList.push(`${slotCounter++}️⃣ ${booking.name || booking.user?.name} - Partner`);
        }
    });

    while (slotCounter <= schedule.total_slots) {
        playersList.push(`${slotCounter++}️⃣`);
    }

    let playersText = playersList.join('\n');
    

    return `🚨 Nuevo Match 
🗓 ${moment(schedule.date).format("DD/MM/YYYY")}
⏰ ${schedule.start_time} - ${schedule.end_time}
🏟 ${schedule.clubName || schedule?.club?.name} 
📍 ${schedule.clubAddress || schedule?.club?.address} 
🎾 ${schedule.courtName || schedule?.court?.name} 
🏅 ${mapBackendToFrontend(schedule.category) === "Sin categoría" ? schedule.category : mapBackendToFrontend(schedule.category)}


${playersText}

¿Quién se apunta? 🔥`;

};

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            toast.success('Partido copiado ¡Compártelo!');
        })
        .catch(err => {
            toast.error('Error al copiar el partido: ' + err);
        });
};
