import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, onHide, onConfirm }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>¿Seguro que deseas anular esta reserva?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Volver
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Anular 
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;