import React from "react";
import SpinnerOverlay from "../../GoMatch/Spinner/SpinnerOverlay";
import DatePicker from "react-datepicker";
import { Row, Col } from 'react-bootstrap';
import moment from "moment";
import { toast } from "react-toastify";
import "./AdminPayment.scss";
import { formatCurrencyCLP } from "../../../utils/Functions";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function AdminPayment({
  isLoading,
  selectedStartDate,
  selectedEndDate,
  paymentData,
  handleFetchPaymentsData,
}) {
  const handleSelectedStartDate = (date) => {
    if (!validateDateFormat(date)) {
      toast.error("El formato de la fecha es incorrecto");
      return;
    } else if (date > selectedEndDate) {
      toast.error("La fecha seleccionada no puede ser mayor a la fecha final");
      return;
    }
    handleFetchPaymentsData(date, selectedEndDate);
  };

  const handleSelectedEndDate = (date) => {
    if (!validateDateFormat(date)) {
      toast.error("El formato de la fecha es incorrecto");
      return;
    } else if (date < selectedStartDate) {
      toast.error("La fecha seleccionada no puede ser menor a la fecha inicial");
      return;
    }
    handleFetchPaymentsData(selectedStartDate, date);
  };

  // validateDateFormat function returns true if the format date is strict to the format 'DD/MM/yyyy'
  const validateDateFormat = (date) => {
    const format = "DD/MM/yyyy";
    return moment(date, format, true).isValid();
  };

  const startDate = moment(selectedStartDate).format("DD/MM/yyyy");
  const endDate = moment(selectedEndDate).format("DD/MM/yyyy");

  const datePickerSection = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div className="admin-payment">
            <Row>
              <Col className="col-3">
                  <p><b>Desde:</b></p>
              </Col>
              <Col className="col-9">
              <div className="admin-payment-datepicker">
              <DatePicker
                selected={selectedStartDate}
                onChange={(date) => handleSelectedStartDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="col-12">
          <div className="admin-payment">
          
       
              <Row>
              <Col className="col-3">
                  <p><b>Hasta:</b></p>
              </Col>
              <Col className="col-9">
              <div className="admin-payment-datepicker">
              <DatePicker
                selected={selectedEndDate}
                onChange={(date) => handleSelectedEndDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
              </Col>
            </Row>
     
          </div>
        </div>
      </div>
    );
  };

  const paymentDash = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div className="admin-payment-card validated">
            <div className="admin-payment-card-text-w">Pagos validados</div>
            <div className="admin-payment-card-number-w">{formatCurrencyCLP(paymentData?.sumOfValidatedPayments || 0)}</div>
          </div>
        </div>
        <div className="col-12">
          <div className="admin-payment-card to-validate">
            <div className="admin-payment-card-text-w">Pagos por validar</div>
            <div className="admin-payment-card-number-w">
              {formatCurrencyCLP(paymentData?.sumOfPendingSchedulesPayments || 0)}
            </div>
          </div>
        </div>
        {/* <div className="col-6">
          <div className="admin-payment-card rejected">
            <div className="admin-payment-card-text-w">Pagos rechazados</div>
            <div className="admin-payment-card-number-w">$24.000</div>
          </div>
        </div> */}
      </div>
    );
  };

  if (isLoading) {
    // Skeleton loading state
    return (
        <> 
            {/* Profile card skeleton */}
            
            <Row className='pt-2 pb-2'>
                <Col className='col-12 text-center'>
                    <Skeleton height={366} className='text-center pdl-section'>
                    </Skeleton>
                </Col>
            </Row>
            {/* User data skeleton */}
         
        </>
    );
}

  return (
    <Row className='py-2'>
    <Col className='col-12'>
        <div className='pdl-section'>
            
      {isLoading ? (
        <SpinnerOverlay />
      ) : (
        <div>
          {/* {startDate === endDate ? (
            <h5 className="mb-1">
              Mostrando: {moment(startDate, "DD/MM/yyyy").format("DD [de] MMMM [del] YYYY")}
            </h5>
          ) : (
            <h5 className="mb-1">
              Mostrando: {startDate} al {endDate}
            </h5>
          )} */}
          <p className="admin-payment-p">
            Seleccione un periodo de calendario para mostrar lo datos de pagos seleccionados
          </p>
          <div>{datePickerSection()}</div>
          <div>{paymentDash()}</div>
        </div>
      )}
   
    </div>
    </Col>
    </Row>
  );
}

export default AdminPayment;
