import React, { createContext, useState, useEffect } from 'react';
import { globalAdminState } from '../../utils/GlobalApi';

export const CashRegisterContext = createContext();

export const CashRegisterProvider = ({ children }) => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [cashRegisters, setCashRegisters] = useState([]);
  const [roleType, setRoleType] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUsername, setCurrentUsername] = useState(null);
  const [currentPendingOrders, setCurrentPendingOrders] = useState()
  const [currentDailyBookings, setCurrentDailyBookings] = useState();

  const fetchAdminState = async () => {
    const response = await globalAdminState();
    if (response.ok && response.data) {
      const adminState = response.data;
      setCashRegisters(adminState.cash_register);
      setCurrentUserId(adminState.user_id);
      setRoleType(adminState.user_role_type);
      setCurrentUsername(adminState.username);
      setCurrentPendingOrders(adminState.pending_orders);
      setCurrentDailyBookings(adminState.daily_pending_orders);
    } else if (response.ok === false) {
      setRoleType('unauthorized');
    } else {
      console.error("Failed to fetch admin state:", response.message);
    }
  };

  useEffect(() => {
    fetchAdminState();
  }, []);

  useEffect(() => {
    if (currentUserId && cashRegisters.length > 0) {
      const currentRegisterOpen = cashRegisters.some(cr => cr.users_permissions_user?.id === currentUserId && cr.cash_register_open);
      setIsRegisterOpen(!!currentRegisterOpen);
    }
  }, [cashRegisters, currentUserId]);

  const refreshCashRegisters = async () => {
    await fetchAdminState();
  };

  return (
    <CashRegisterContext.Provider value={{ 
      isRegisterOpen, 
      setIsRegisterOpen, 
      cashRegisters, 
      setCashRegisters, 
      currentUserId, 
      setCurrentUserId,
      roleType,
      currentUsername,
      currentPendingOrders,
      currentDailyBookings,
      refreshCashRegisters // Provide the refresh function to the context
    }}>
      {children}
    </CashRegisterContext.Provider>
  );
};
