import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const PublicTypeModal = ({ show, onHide, handleButtonClick, selectedPublicType }) => {
    return (
        <Modal show={show} onHide={onHide} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Seleccionar tipo de partido</h4>

                <Row>
                   
                    <Col xs={12}>
                        <Button className={`go-btn go-btn-select--private ${selectedPublicType === true ? 'selected' : ''}`} onClick={() => handleButtonClick(true)}>
                            <h4> <img src="/assets/icons/people.svg" alt="people" /> Horario Público</h4>
                            <p>Este partido es público, todos los usuarios de GoMatch podrán unirse.</p>
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button className={`go-btn go-btn-select--private ${selectedPublicType === false ? 'selected' : ''}`} onClick={() => handleButtonClick(false)}>
                            <h4> <img src="/assets/icons/lock.svg" alt="lock" /> Horario Bloqueado</h4>
                            <p>Este partido es privado, los usuarios no verán este bloque de horario en la busqueda</p>
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default PublicTypeModal;
