import React from 'react';
import Court from '../../components/GoMatch/Court/Court';

function CourtScreen() {
  return (
    <div className='container go-container'>
      <h1 className='pdl-title-main'>Canchas</h1>
      <Court />
    </div>
  );
}

export default CourtScreen;
