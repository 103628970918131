import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, ListGroup, Form, Button } from 'react-bootstrap';
import SearchBarComponent from '../../../Global/SearchBar/SearchBarComponent';
import { findClubUserListFilterByString, getUserData } from '../../../../utils/GoMatchApi';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import 'react-loading-skeleton/dist/skeleton.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './BookingsModal.scss';

const invitedImg = "/assets/illustrations/invited.png";

const BookingsModal = ({ show, onHide, selectedBookings, handleButtonClick, totalSlots, fullUserList }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [guestData, setGuestData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedBookings, setUpdatedBookings] = useState([...selectedBookings]);
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const response = await getUserData();
            if (response && response.role && response.username) {
                setGuestData(response);
            } else {
                console.error('Failed to fetch user data:', response.message);
            }
            setIsLoading(false);
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        setUpdatedBookings(selectedBookings);
    }, [selectedBookings]);

    const handleSearchChange = async (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        if (value.trim().length < 2) {
            setUserList([]);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const response = await findClubUserListFilterByString(value);
        if (response.ok) {
            const filteredUsers = response.data.filter(user => !updatedBookings.some(booking => booking.userId === user.id));
            setUserList(filteredUsers);
        } else {
            setUserList([]);
        }

        setIsLoading(false);
    };

    const handleAddGuest = () => {
        if (updatedBookings.length < totalSlots) {
            if (guestData) {
                const guest = {
                    userId: guestData.id, // Unique key for the guest
                    name: "Invitado",
                    lastname: guestData.lastname,
                    email: guestData.email,
                    phone: guestData.phone,
                    profile_picture: invitedImg,
                    payment_percentage: 'p0', // Default to p0
                    is_guest: true,
                };
                const newBookings = [...updatedBookings, guest];
                setUpdatedBookings(newBookings);
                setSearchTerm(''); // Clear the search bar
                setUserList([]); // Clear the user list
                handleButtonClick(newBookings); // Update formData
            } else {
                toast.error('Failed to fetch user data');
            }
        } else {
            toast.error(`Puedes seleccionar hasta ${totalSlots} usuarios`);
        }
    };

    const handleCompleteGuests = () => {
        if (updatedBookings.length < totalSlots) {
            if (guestData) {
                let newBookings = [...updatedBookings];
                while (newBookings.length < totalSlots) {
                    const guest = {
                        userId: guestData.id, // Unique key for the guest
                        name: "Invitado",
                        lastname: guestData.lastname,
                        email: guestData.email,
                        phone: guestData.phone,
                        profile_picture: invitedImg,
                        payment_percentage: 'p0', // Default to p0
                        is_guest: true,
                    };
                    newBookings = [...newBookings, guest];
                }
                setUpdatedBookings(newBookings);
                setSearchTerm(''); // Clear the search bar
                setUserList([]); // Clear the user list
                handleButtonClick(newBookings); // Update formData
            } else {
                toast.error('Failed to fetch user data');
            }
        } else {
            toast.error(`Puedes seleccionar hasta ${totalSlots} usuarios`);
        }
    };

    const handleUserToggle = (user) => {
        let newBookings = [...updatedBookings];
        const userId = user.userId || user.id;
        const userIndex = newBookings.findIndex(u => u.userId === userId);
    
        if (userIndex !== -1) {
            newBookings.splice(userIndex, 1);
            // Add user back to the user list with full details
            const fullUserDetails = fullUserList.find(u => u.userId === userId);
            setUserList(prevUserList => [...prevUserList, fullUserDetails || user]);
        } else {
            if (newBookings.length < totalSlots) {
                newBookings.push({
                    userId: userId,
                    name: user.name,
                    lastname: user.lastname,
                    email: user.email,
                    profile_picture: user.profile_picture?.formats?.thumbnail?.url || user.profile_picture_url || '',
                    payment_percentage: 'p0', // Default to p0
                    is_guest: user.is_guest || false,
                    padel_league: user.padel_league || '',
                });
                // Remove user from the user list
                setUserList(prevUserList => prevUserList.filter(u => u.id !== user.id));
            } else {
                toast.error(`Puedes seleccionar hasta ${totalSlots} usuarios`);
            }
        }
    
        setUpdatedBookings(newBookings);
        setSearchTerm(''); // Clear the search bar
        document.getElementById('searchBar').focus();
        setUserList([]); // Clear the user list
        handleButtonClick(newBookings); // Update formData
    };
    
    const renderSkeletons = () => {
        return (
            <Row className='pt-3'>
                <Col className='col-md-12'>
                    <p className=' go-text text-muted mb-2'>Seleccione un usuario de la lista</p>
                    <Skeleton height={172} className='go-skeleton go-skeleton--card'/>
                </Col>
            </Row>
        );
    };

    const handleClose = () => {
        setSearchTerm('');
        setUserList([]);
        onHide();
    };

    const getUserProfilePicture = (user) => {
        if(user.is_guest) {
            return invitedImg;
        } else {
            return user.profile_picture_url || user.profile_picture || invitedImg;
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="go-modal go-modal--select">
            <Modal.Header closeButton />
            <Modal.Body>
                <h4 className="title mb-4">Seleccionar Jugadores</h4>

                {updatedBookings.length > 0 && (
                    <div className='selected-users'>
                        <h5 className="subtitle mb-3">Usuarios Seleccionados</h5>
                        <ListGroup className='go-user-list mb-4'>
                        {updatedBookings.map(user => (
                            <ListGroup.Item
                                action
                                key={user.id} // Use booking ID for the key
                                onClick={() => handleUserToggle(user)}
                                className='go-user-list-item selected'
                            >
                                <Row>
                                    <Col md={1} xs={1} className="my-auto">
                                        <Form.Check
                                            className='checkbox'
                                            type="checkbox"
                                            checked={true}
                                            onChange={() => handleUserToggle(user)}
                                        />
                                    </Col>
                                    <Col md={2} xs={2} className='text-center my-auto'>
                                        <div className='img-div'>
                                            <LazyLoadImage 
                                                effect="blur" 
                                                src={getUserProfilePicture(user)} 
                                                alt="img" 
                                            />
                                        </div>
                                    </Col>
                                    <Col md={9} xs={8} className="my-auto overflow-hidden ps-md-2">
                                        <p className='mb-0 name'>{user.is_guest ? "Invitado" : `${user.name} ${user.lastname}`}</p>
                                        <p className='mb-0 email'>{user.email}</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>

                    </div>
                )}

                <SearchBarComponent 
                    searchTerm={searchTerm}
                    handleSearchChange={handleSearchChange}
                    placeholder="Ingrese nombre de usuario"
                    extraClassName='go-searchbar--user'
                    id='searchBar'
                />

                {/* invitado */}
                <Row>
                    <Col md={6} xs={12}>
                        <ListGroup className='go-user-list mt-3'>
                            <ListGroup.Item
                                action
                                onClick={() => handleAddGuest()}
                                className='go-user-list-item selected'
                                key={`guest-${updatedBookings.length + 1}`} // Unique key for the guest
                            >
                                <Row>
                                    <Col md={4} xs={4} className='text-center my-auto'>
                                        <div className='img-div'>
                                                <LazyLoadImage 
                                                effect="blur" 
                                                src={invitedImg}
                                                alt="img" 
                                                />
                                        </div>
                                    </Col>
                                    <Col md={8} xs={8} className="my-auto ps-md-2 text-center">
                                        <p className='mb-0 name'>Agregar invitado</p>
                                        <p className='mb-0 email'></p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={6} xs={12}>
                    <ListGroup className='go-user-list mt-3'>
                            <ListGroup.Item
                                action
                                onClick={() => handleCompleteGuests()}
                                className='go-user-list-item selected'
                                key={`guest-${updatedBookings.length + 1}`} // Unique key for the guest
                            >
                                <Row>
                                    <Col md={4} xs={4} className='text-center my-auto'>
                                        <div className='img-div img-div--complete'>
                                                <LazyLoadImage 
                                                effect="blur" 
                                                src={invitedImg}
                                                className='img-1'
                                                alt="img" 
                                                />
                                                  <LazyLoadImage 
                                                effect="blur" 
                                                className='img-2'

                                                src={invitedImg}
                                                alt="img" 
                                                />
                                                  <LazyLoadImage 
                                                effect="blur" 
                                                className='img-3'
                                                src={invitedImg}
                                                alt="img" 
                                                />
                                            
                                        </div>
                                    </Col>
                                    <Col md={8} xs={8} className="my-auto ps-md-2 text-center">
                                        <p className='mb-0 name'>Completar invitados</p>
                                        <p className='mb-0 email'></p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>

                {isLoading ? (
                    renderSkeletons()
                ) : (
                    <Row className='pt-3 pb-1'>
                        <Col className='col-md-12'>
                        <p className='go-text text-muted mb-2'>Seleccione un usuario de la lista</p>

                        <ListGroup className='go-user-list go-user-list--result'>
                            {userList.map(user => (
                                <ListGroup.Item
                                    action
                                    key={user.id}
                                    onClick={() => handleUserToggle(user)}
                                    className={`go-user-list-item ${updatedBookings.some(u => u.id === user.id) ? 'selected' : ''}`}
                                >
                                    <Row>
                                        <Col md={1} xs={1} className="my-auto">
                                            <Form.Check 
                                                type="checkbox"
                                                checked={updatedBookings.some(u => u.id === user.id)}
                                                onChange={() => handleUserToggle(user)}
                                            />
                                        </Col>
                                        <Col md={2} xs={2} className='my-auto text-center'>
                                            <div className='img-div'>
                                                <LazyLoadImage 
                                                    effect="blur" 
                                                    src={getUserProfilePicture(user)} 
                                                    alt="img" 
                                                />
                                            </div>
                                        </Col>
                                        <Col md={9} xs={8} className='my-auto overflow-hidden ps-md-2'>
                                            <p className='mb-0 name'>{user.name} {user.lastname}</p>
                                            <p className='mb-0 email'>{user.email}</p>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default BookingsModal;
