
import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './GoButton.scss'; 

const GoButton = ({ onClick, className, iconSrc, children }) => {
    return (
        <BootstrapButton onClick={onClick} className={className}>
            {iconSrc && <img src={iconSrc} alt="" className="go-btn__icon" />}
            {children}
        </BootstrapButton>
    );
};

GoButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    iconSrc: PropTypes.string,
    children: PropTypes.node
};

export default GoButton;
