import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [cartBookings, setCartBookings] = useState([]);

  useEffect(() => {
    // Recalculate total whenever cartItems changes
    const newTotal = cartItems.reduce((acc, item) => {
      const itemPrice = parseFloat(item.item_sell_price);
      const itemQuantity = parseInt(item.quantity, 10);
      if (!isNaN(itemPrice) && !isNaN(itemQuantity)) {
        return acc + itemPrice * itemQuantity;
      }
      return acc;
    }, 0);
    setTotal(newTotal);
  }, [cartItems]);

  const addToCart = (item, quantity = 1) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(i => i.id === item.id);
      if (existingItem) {
        return prevItems.map(i => 
          i.id === item.id ? { ...i, quantity: i.quantity + quantity } : i
        );
      } else {
        return [...prevItems, { ...item, quantity, price: item.item_sell_price }];
      }
    });
  };

  const removeFromCart = (itemId, quantity = 1) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(i => i.id === itemId);
      if (existingItem.quantity > quantity) {
        return prevItems.map(i =>
          i.id === itemId ? { ...i, quantity: i.quantity - quantity } : i
        );
      } else {
        return prevItems.filter(item => item.id !== itemId);
      }
    });
  };

  const handleRemoveBooking = (bookingId) => {
    setCartBookings(prevBookings => prevBookings.filter(booking => booking.id !== bookingId));
  };

  const clearCart = () => {
    setCartItems([]);
    setTotal(0);
    setCartBookings([]); // Clear bookings as well
  };

  return (
    <CartContext.Provider value={{ cartItems, cartBookings, setCartBookings, addToCart, removeFromCart, handleRemoveBooking, clearCart, total }}>
      {children}
    </CartContext.Provider>
  );
};
