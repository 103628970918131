 
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Row";
import { toast } from "react-toastify";
import "./AddPlayerModal.scss";

function AddPlayerModal(props) {
  const handleClose = () => props.setCloseAddPlayerModal();
  const addPlayer = () => props.addPlayer();
  const handleCopyLink = () => {
    const urlToCopy = window.location.href;

    try {
      toast.success("Enlace copiado con éxito. ¡Compártelo!");
      copyToClipboard(urlToCopy);
    } catch (err) {
      toast.error("No se pudo copiar el link del partido. Intente nuevamente");
      console.log("handleCopyLink -> Error al copiar URL con SLUG", err);
    }
  };

  function copyToClipboard(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        console.log("Text copied to clipboard");
        toast.success("Texto copiado!");
      } else {
        throw new Error("Copy command unsuccessful");
      }
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast.error("Error al copiar el texto");
    }

    document.body.removeChild(textarea);
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Container className="add-player-modal">
        <Col className="add-player-modal-row">
          <Button className="add-player-modal-row-button" variant="success" onClick={addPlayer}>
            Agregar invitado al partido
          </Button>
          <Button
            className="add-player-modal-row-button"
            variant="success"
            onClick={handleCopyLink}
          >
            Copiar enlace del partido
          </Button>
        </Col>
      </Container>
    </Modal>
  );
}

export default AddPlayerModal;