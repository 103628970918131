import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { createClubUser, uploadFile } from "../../../utils/GoMatchApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatRut, validateRut } from "../../../utils/Functions"; 
import SpinnerOverlay from '../../GoMatch/Spinner/SpinnerOverlay';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { bucketUrl } from "../../../utils/Constants";


const CreateUserForm = ({ handleSuccess, setLoading, handleBack }) => {
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null); // Added for image preview
  const [isLoading, setIsLoading] = useState(false); // Added loading state
  const [selectedDate, setSelectedDate] = useState(new Date());

  const range = (start, end) => {
    let arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  };

  
  const years = range(1900, new Date().getFullYear());
  const months = [
    "Enero", "Febrero", "Marzo", "Abril",
    "Mayo", "Junio", "Julio", "Agosto",
    "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const validate = (data) => {
    let tempErrors = {};
    let isValid = true;

    // Name validation
    if (!data.name.trim()) {
      tempErrors.name = "Nombre es un campo requerido";
      isValid = false;
    }

    // Lastname validation
    if (!data.lastname.trim()) {
      tempErrors.lastname = "Apellido es un campo requerido";
      isValid = false;
    }

    // Email validation
    if (!data.email) {
      tempErrors.email = "Email es un campo requerido";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      tempErrors.email = "El Email no es válido";
      isValid = false;
    }

    // Password validation
    // if (!data.password) {
    //   tempErrors.password = "Contraseña es un campo requerido";
    //   isValid = false;
    // }

    

    // RUT validation (assuming validateRut is a utility function you have)
    if (data.rut && !validateRut(data.rut)) {
      tempErrors.rut = "RUT o formato inválido. Sin puntos y con guión";
      isValid = false;
    }

    // Phone validation (basic example, could be expanded)
    if (!data.phone.trim()) {
      tempErrors.phone = "Teléfono es un campo requerido";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null); // Clear preview if non-image file is selected
    }
  };

  const handleCreateUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    setIsLoading(true);
  
    const formData = new FormData(event.target);
    const userData = {
      name: formData.get("name").trim(),
      lastname: formData.get("lastname").trim(),
      email: formData.get("email").trim(),
      username: formData.get("name").trim() + formData.get("lastname").trim() + Math.floor(Math.random() * 100000),
      password: formData.get("name").trim().replace(/\./g, "").replace(/-/g, "").slice(0, 8) + Math.floor(Math.random() * 100000),
      birth_date: moment(selectedDate).format('YYYY-MM-DD'),
      rut: formData.get("rut").trim(),
      phone: formData.get("phone").trim(),
      profile_picture: null, // Set to null by default
      role: 4 // role for authenticated users
    };
  
    const profilePhoto = formData.get('profilePhoto'); // Get the file
  
    if (!validate(userData)) {
      setLoading(false);
      setIsLoading(false);
      console.log(userData);
      toast.error("Por favor, corrija los errores antes de continuar.");
      return; // Stop the submission if validation fails
    }
  
    try {
      // Upload the file first
      if (profilePhoto && profilePhoto.size > 0) {
        const uploadResult = await uploadFile(profilePhoto, 'profile_photos');
        console.log("Upload result:", uploadResult);
        if (uploadResult && uploadResult[0].id) {
          // Include the file ID in userData
          userData.profile_picture = uploadResult[0].id;
        }
      }
  
      // Then create the user with the file ID included
      console.log(userData);
      const result = await createClubUser(userData);
      console.log(userData);
      if (result.ok) {
        console.log("User created successfully", result.data);
        handleSuccess(result.data);
      } else {
        console.error("Failed to create user", result.data);
        // Handle error accordingly, maybe show a toast or set an error state
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error, maybe show a toast or set an error state
    }
  
    setLoading(false);
    setIsLoading(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
 
  // Render form with validation feedback
  return (
    <Form className="pdl-form" onSubmit={handleCreateUser}>
      
      {isLoading ? (
        <>
          <Skeleton className="mb-3 form-group--section" height={374} width={'100%'} />
          <SpinnerOverlay />
        </>

      ) : (
        
      <Form.Group className="mb-3 form-group--section" controlId="profilePhoto">
        { imagePreview ? (
          <div className="pdl-profile__img pdl-profile__img--edit">
            <img src={imagePreview} alt="Preview" className="img-thumbnail mt-2" style={{ width: "200px" }} />
          </div>
        ) : ( 
          <div className="pdl-profile__img pdl-profile__img--edit">
            <img src={bucketUrl+"/generic_player_gomatch.png"} alt="Preview" className="img-thumbnail mt-2" style={{ width: "200px" }} />
          </div>
        )}
        <Form.Label>Foto de Perfil</Form.Label>
        <Form.Control
          type="file"
          name="profilePhoto"
          accept="image/*"
          className="pdl-input pdl-input--file mb-0"
          onChange={handleImageChange}
          disabled={isLoading} // Optionally disable input while loading
        />
      </Form.Group>

      )}

      <div className="form-group--section">
        {isLoading ? (
          <>
            <Skeleton className="mb-3" height={50} />
            <Skeleton className="mb-3" height={50} />
            <Skeleton className="mb-3" height={50} />
            <Skeleton className="mb-3" height={50} />
            <Skeleton className="mb-3" height={50} />
          </>
        ) : (
          <>
            <Form.Group className="mb-3" controlId="name">
              <Form.Control 
                className={`pdl-input ${errors.name ? 'is-invalid' : ''}`} 
                name="name" 
                type="text" 
                required 
                placeholder="Nombre" 
                isInvalid={!!errors.name}
              />
              {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastname">
              <Form.Control 
                className={`pdl-input ${errors.lastname ? 'is-invalid' : ''}`} 
                name="lastname" 
                type="text" 
                required 
                placeholder="Apellido" 
                isInvalid={!!errors.lastname}
              />
              {errors.lastname && <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Control 
                className={`pdl-input ${errors.email ? 'is-invalid' : ''}`} 
                name="email" 
                type="email" 
                required 
                placeholder="Email" 
                isInvalid={!!errors.email}
              />
              {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="password">
              <Form.Control 
                className={`pdl-input ${errors.password ? 'is-invalid' : ''}`} 
                name="password" 
                type="password" 
                required 
                placeholder="Contraseña" 
                isInvalid={!!errors.password}
              />
              {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
            </Form.Group> */}

            <Form.Group className="mb-3" controlId="birth_date">
              <div className="pdl-datepicker pdl-datepicker--form">
              <DatePicker
                className={`pdl-input ${errors.birth_date ? 'is-invalid' : ''}`} 
                name="birth_date" 
                dateFormat="dd-MM-yyy"
                isInvalid={!!errors.birth_date}
                showYearDropdown={true}
                maxDate={new Date()}
                calendarType="ISO 8601"
                placeholderText={'Fecha de Nacimiento'} 
                renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (
                  <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                    <button className="pdl-datepicker pdl-datepicker__before" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {"<"}
                    </button>
                    <select className="pdl-datepicker pdl-datepicker__select me-2"
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(Number(value))}
                    >
                      {years.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                
                    <select className="pdl-datepicker pdl-datepicker__select"
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                
                    <button className="pdl-datepicker pdl-datepicker__next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                    </button>
                  </div>
                )}
                selected={selectedDate ? moment(selectedDate, 'yyy-MM-dd').toDate() : null} // Convert back to Date object for DatePicker
                onChange={handleDateChange}
                
              />
              </div>
              {errors.birth_date && <Form.Control.Feedback type="invalid">{errors.birth_date}</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="rut">
              <Form.Control 
                className={`pdl-input ${errors.rut ? 'is-invalid' : ''}`} 
                name="rut" 
                type="text" 
                placeholder="RUT" 
                isInvalid={!!errors.rut}
              />
              {errors.rut && <Form.Control.Feedback type="invalid">{errors.rut}</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="phone">
              <Form.Control 
                className={`pdl-input ${errors.phone ? 'is-invalid' : ''}`} 
                name="phone" 
                type="text" 
                required 
                placeholder="Teléfono" 
                isInvalid={!!errors.phone}
              />
              {errors.phone && <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>}
            </Form.Group>
          </>
        )}
      </div>

     

      <div className="pdl-form-buttons pt-3">
        <Button variant="outline-secondary" className="me-2" onClick={handleBack}>
          Volver
        </Button>
        <Button variant="outline-primary" type="submit">
          Crear usuario
        </Button>
      </div>
    </Form>
  );
};

export default CreateUserForm;
