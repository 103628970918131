import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Container, Col, Row, ButtonGroup, Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import './AdminSchedulesScreen.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ScheduleList from '../../components/Admin/AdminSchedulesNew/ScheduleList';
import PreviewModal from '../../components/Admin/AdminSchedulesNew/Modals/PreviewModal';
import ShareModal from '../../components/Admin/AdminSchedulesNew/Modals/ShareModal';
import { fetchSchedulesAndGhostSchedulesByDate, getUserClubData } from '../../utils/GoMatchApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { locale } from '../../utils/Functions';

function AdminSchedulesScreenv2() {
    
    const { state } = useLocation();
    const scrollToId = state?.scrollToId || null;
    const scrollToRef = useRef(null);
    const passedDate = state?.previuslySelectedDate || new Date();

    const [selectedDate, setSelectedDate] = useState(passedDate);
    const [schedules, setSchedules] = useState([]);
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [currentSchedulesToToggle, setCurrentSchedulesToToggle] = useState([]);
    const [clubId, setClubId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [showMatchSettingModal, setShowMatchSettingModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedDuration, setSelectedDuration] = useState(5);

    const navigate = useNavigate();

    const handleOpenModal = (scheduleId) => {
        const selectedSchedule = schedules.find(schedule => schedule.id === scheduleId);
        setCurrentSchedule(selectedSchedule);

        const selectedStartTime = moment(selectedSchedule.start_time, 'HH:mm');

        const scheduleToToggle1 = schedules.filter(schedule => 
            schedule.date === selectedSchedule.date &&
            schedule.court.id === selectedSchedule.court.id &&
            moment(schedule.start_time, 'HH:mm').isSame(selectedStartTime.clone().add(30, 'minutes'))
        );

        const scheduleToToggle2 = schedules.filter(schedule => 
            schedule.date === selectedSchedule.date &&
            schedule.court.id === selectedSchedule.court.id &&
            moment(schedule.start_time, 'HH:mm').isSame(selectedStartTime.clone().add(60, 'minutes'))
        );

        const combinedSchedulesToToggle = [...scheduleToToggle1, ...scheduleToToggle2];
        setCurrentSchedulesToToggle(combinedSchedulesToToggle);

        setShowMatchSettingModal(true);
    };

    useEffect(() => {
        if (!isLoading && scrollToId) {
            const element = document.getElementById(scrollToId);
            if (element) {
                const offset = 280;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;
    
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [isLoading, scrollToId]);
    
    const handleOpenSettings = (schedule) => {
        if(schedule?.is_ghost) {
            navigate(`/admin/gocalendar/nueva-reserva`, { state: { schedule, selectedDate, selectedDuration } });
        } else {
            setCurrentSchedule(schedule);
            setShowPreviewModal(true);
        }
    };

    const loadSchedules = useCallback(async () => {
        if (!clubId || !selectedDate) return;
        setIsLoading(true);

        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        const fetchedSchedules = await fetchSchedulesAndGhostSchedulesByDate(formattedDate, 4);

        if (fetchedSchedules.ok) {
            setSchedules([...fetchedSchedules.data]);
            setIsLoading(false);
        } else {
            console.error('Failed to fetch schedules', fetchedSchedules.error);
        }
    }, [clubId, selectedDate]);

    const schedulesByCourt = useMemo(() => {
        return schedules.reduce((acc, schedule) => {
            if (!acc[schedule.court.id]) {
                acc[schedule.court.id] = {
                    courtName: schedule.court.name,
                    schedules: []
                };
            }
            acc[schedule.court.id].schedules.push(schedule);
            return acc;
        }, {});
    }, [schedules]);

    useEffect(() => {
        const fetchClubData = async () => {
            const result = await getUserClubData();
            if (result.ok) {
                setClubId(result.data.clubs[0].id);
            } else {
                console.error('Failed to fetch club data', result.error);
            }
        };

        fetchClubData();
    }, []);

    useEffect(() => {
        loadSchedules();
    }, [loadSchedules]);

    if (isLoading) {
        return (
            <>
                <Container className="go-container">
                    <Row className='justify-content-center'>
                        <Col>
                            <Skeleton height={519} className='text-center pdl-section' />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Skeleton height={272} className='text-center pdl-section' />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Skeleton height={134} className='text-center pdl-section' />
                        </Col>
                    </Row>
                </Container>
              
            </>
        );
    }

    return (
        <Container className='go-container pt-0'>
            <div className='pdl-datepicker go-sticky-content'>
                <Row className='justify-content-center'>
                    <Col md={3} xs={12}>
                        <div className='go-datepicker go-datepicker--schedules'>
                            <ReactDatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control mb-3 w-100"
                                placeholderText="Fecha"
                                isClearable
                                locale={locale}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                        </div>
                    </Col>
                    <Col md={3} xs={9} className='mb-3 mb-md-0'>
                        <div className='go-btn go-btn--minutes'>
                            <ButtonGroup aria-label="minutes">
                                <Button
                                    onClick={() => setSelectedDuration(4)}
                                    className={selectedDuration === 4 ? 'selected' : ''}
                                >
                                    60 min
                                </Button>
                                <Button
                                    onClick={() => setSelectedDuration(5)}
                                    className={selectedDuration === 5 ? 'selected' : ''}
                                >
                                    90 min
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Col>
                    <Col md={1} xs={3}>
                        <Button className='go-btn go-btn--share main text-center' onClick={() => setShowShareModal(true)}>
                                <img src='/assets/icons/share.svg' alt='Share' />
                        </Button>
                    </Col>
                </Row>
            </div>
            <ScheduleList 
                schedulesByCourt={schedulesByCourt} 
                setHoveredItemId={setHoveredItemId} 
                handleOpenModal={handleOpenModal} 
                selectedDuration={selectedDuration} 
                hoveredItemId={hoveredItemId}
                handleOpenSettings={handleOpenSettings}
            />

            <ShareModal
                show={showShareModal}
                onHide={() => setShowShareModal(false)}
                schedules={schedules}
                selectedDate={selectedDate}
            />

            <PreviewModal
                show={showPreviewModal}
                onHide={() => setShowPreviewModal(false)}
                schedule={currentSchedule}
                selectedDate={selectedDate}
                selectedDuration={selectedDuration}
                loadSchedules={loadSchedules} // Pass the loadSchedules function
            />

        </Container>
    );
}

export default AdminSchedulesScreenv2;
