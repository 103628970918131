import React from 'react';
import { Pagination } from 'react-bootstrap';
import './GoPagination.scss';

const GoPagination = ({ page, totalPages, onPageChange }) => {
  const itemsPagination = [];
  const startPage = Math.max(1, page - 2);
  const endPage = Math.min(totalPages, page + 2);

  for (let number = startPage; number <= endPage; number++) {
    itemsPagination.push(
      <Pagination.Item key={number} active={number === page} onClick={() => onPageChange(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="justify-content-center mt-2 go-pagination">
      <Pagination.First onClick={() => onPageChange(1)} disabled={page === 1} />
      <Pagination.Prev onClick={() => onPageChange(page - 1)} disabled={page === 1} />
      {itemsPagination}
      <Pagination.Next onClick={() => onPageChange(page + 1)} disabled={page >= totalPages} />
      <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={page >= totalPages} />
    </Pagination>
  );
};

export default GoPagination;
