// src/components/GoMarket/BookingItem.js
import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getBookingStatus } from '../../utils/Functions';
import './HomeBookingItem.scss';

const HomeBookingItem = ({ booking, onAddToCart }) => {
  if (!booking || !booking.user_name || !booking.schedule_start_time) {
    return null; // Return null if the booking data is incomplete
  }
  
  return (

      <Col className="col-md-4 col-12 pb-3">
      <div className={`go-booking-item go-booking-item--${booking.booking_payment_percentage}`} >
        <Card onClick={() => onAddToCart(booking)}>
          <div className='gomarket-card-image'>
            <LazyLoadImage
              alt="test"
              effect="blur"
              src={`/assets/illustrations/padel-${booking.booking_payment_percentage}.png`}
              className="card-img-top gomarket-card__image"
            />
          </div>
          <Badge className='gomarket-card__badge gomarket-card__badge--price'>${booking.booking_price}</Badge>
          <Card.Body className='go-booking-item-body'>

            <Row className='pt-0'>
                <Col xs={12}>
                    <Badge className={`go-badge mb-2 go-badge-status go-badge-status--${booking.booking_payment_percentage}`}>{getBookingStatus(booking.booking_payment_percentage)}</Badge>
                </Col>
                <Col xs={12} className='text-start'>
                    <p className='date mb-0'>
                    <LazyLoadImage effect='blur' src='/assets/icons/menu/calendar.svg' alt='date' className='' height={'16px'} />
                      {booking.schedule_date}
                    </p>
                </Col>
                <Col xs={12} className='text-start'>
                    <p className='time mb-0'>
                    <LazyLoadImage effect='blur' src='/assets/icons/clock.svg' alt='clock' className='' height={'16px'} />
                      {booking.schedule_start_time} - {booking.schedule_end_time} Hrs
                    </p>
                </Col>
                <Col xs={12}>
                    <p className='user mb-0'>
                        <LazyLoadImage effect='blur' src='/assets/icons/profile-circle.svg' alt='profile' className='' height={'16px'} />
                        {booking.booking_is_guest ? "Invitado" : booking.user_name+' '+booking.user_lastname}
                    </p>
                </Col>
                <Col xs={12} className='text-start'>
                    <p className='court pt-0 mb-0'>
                        <LazyLoadImage effect='blur' src='/assets/icons/court.svg' alt='profile' height={'16px'} />
                        {booking.court_name}
                    </p>
                </Col>
            </Row>

          </Card.Body>
        </Card>
      </div>
      </Col>
  );
};

export default HomeBookingItem;
