import React, { useState } from 'react';
import { Container, Nav, Navbar, Col, Row, Button, Form, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChartLine, faGear, faPaperPlane, faShop, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { addToUserWaitingList } from "../../utils/GoMatchApi";

function HomeScreen() {

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const result = await addToUserWaitingList(formData);
        if (result.ok) {
            handleShowModal(); // Show the modal on successful submission
            setFormData({ name: '', email: '', phone: '', message: '' }); // Clear form
        } else {
            alert(`Failed to add to waiting list: ${result.error}`); // You might want to handle errors more gracefully
        }
    };
    


  return (

    <>
        <section id='main' className='go-section go-section--main'>
            <Container>
                {/* Navbar */}
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary go-navbar">
                <Container>
                    <Navbar.Brand href="#home">GoMatch</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#features">Características</Nav.Link>
                        <Nav.Link href="#pricing">Precios</Nav.Link>
                        <Nav.Link href="#contact">Lista de espera</Nav.Link>

                    </Nav>
                    <Nav>
                        <Nav.Link href='/admin_login'>Entrar</Nav.Link>
                        {/* <Nav.Link href="/Register">Registrarme</Nav.Link> */}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
                </Navbar>

                {/* Banner */}
                <Row className='py-5'>
                    <Col className="col-md-5 mb-5">
                        <h1 className='go-title go-title--main'>Gestión de espacios deportivos</h1>
                        <h4 className='go-title go-title--subtitle pb-2'> Arrienda tus canchas, organiza torneos, maneja tu inventario, automatiza procesos, comparte en redes sociales, ordena tus ingresos y mucho más! <b>GoMatch</b> es la aplicación que llevará tu club deportivo al siguiente nivel.</h4>
                        <h4 className='go-title go-title--subtitle pb-2'><b>Próximamente disponible</b></h4>
                        <Button href='#contact' className='go-btn go-btn--main'> <FontAwesomeIcon icon={faPaperPlane} className='pe-3' />  Avísame cuando esté listo!</Button>

                    </Col>
                    <Col className="col-md-2 col-12 text-center">
                        <div className='go-img-container--mobile img-1'>
                            <img src='mobile.png' alt='GoMatch' />
                        </div>
                    </Col>
                    <Col className="col-md-2">
                    <div className='go-img-container--mobile img-2'>
                            <img src='mobile2.png' alt='GoMatch' />
                        </div>
                    </Col>
                    <Col className='col-md-2'>
                    <div className='go-img-container--mobile img-3'>
                            <img src='mobile3.png' alt='GoMatch' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section id="features" className='go-section go-section--color'>
            <Container>
                <Row className='justify-content-center text-center py-5'>
                    <Col className='col-md-6 col-12'>
                        <div className='go-call'>
                            <h3 className='go-call--title'>Con <b>GoMatch</b> podrás gestionar tus espacios deportivos sin depender de otras herramientas, todo en el mismo lugar!</h3>
                            <Button href='#contact' className='go-btn go-btn--main'> <FontAwesomeIcon icon={faPaperPlane} className='pe-3'/>  Avísame cuando esté listo!</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* Características */}
        
            <Container>
                <Row className='py-5'>
                    <Col className='col-md-4 col-12 go-features'>
                        <div className='go-features-item'>
                            <FontAwesomeIcon icon={faCalendar} className='go-features-item--icon'/>
                            <h3 className='go-features-item--title'>Reservas</h3>
                            <p className='go-features-item--text'>Reserva tus canchas de manera rápida y sencilla, con un solo click.</p>
                        </div>
                    </Col >
                    <Col className='col-md-4 col-12 go-features'>   
                        <div className='go-features-item'>
                            <FontAwesomeIcon icon={faShop} className='go-features-item--icon'/>
                            <h3 className='go-features-item--title'>Inventario</h3>
                            <p className='go-features-item--text'>Automatiza tus procesos de pago, notificaciones y recordatorios.</p>
                        </div>               
                    </Col>
                    <Col className='col-md-4 col-12 go-features'>
                        <div className='go-features-item'>
                            <FontAwesomeIcon icon={faTrophy} className='go-features-item--icon'/>
                            <h3 className='go-features-item--title'>Torneos</h3>
                            <p className='go-features-item--text'>Organiza torneos, ligas, americanos y campeonatos</p>
                        </div>
                    </Col>
                    <Col className='col-md-4 col-12 go-features'>
                        <div className='go-features-item'>
                            <FontAwesomeIcon icon={faWhatsapp} className='go-features-item--icon'/>
                            <h3 className='go-features-item--title'>Herramientas RRSS</h3>
                            <p className='go-features-item--text'>Comparte horarios disponibles y partidos, ahorra tiempo y gestión</p>
                        </div>
                    </Col>
                    <Col className='col-md-4 col-12 go-features'>
                        <div className='go-features-item'>
                            <FontAwesomeIcon icon={faGear} className='go-features-item--icon'/>
                            <h3 className='go-features-item--title'>Automatización</h3>
                            <p className='go-features-item--text'>Automatiza procesos de gestión de partidos.</p>
                        </div>
                    </Col>
                    <Col className='col-md-4 col-12 go-features'>
                        <div className='go-features-item'>
                            <FontAwesomeIcon icon={faChartLine} className='go-features-item--icon'/>
                            <h3 className='go-features-item--title'>Estadísticas</h3>
                            <p className='go-features-item--text'>Revisa estadísticas de tus canchas y clientes</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section id="information" className='py-5'>
            <Container className='pb-5'>
                <Row className=' justify-content-center'>
                    
                    <Col className='col-md-6 my-auto text-center col-12 d-md-flex d-none'>
                        <div className='go-img-container'>
                          <img src='desktop.png' alt='GoMatch' className='w-100' />
                        </div>
                    </Col>
                    <Col className='col-md-1'></Col>
                    <Col className='col-md-5 col-12 my-auto text-center'>
                        <h4>Todo en un mismo lugar</h4>
                        <p>Con GoMatch la gestion de tu club deportivo es fácil! No importa la cantidad de canchas que tengas o las disciplinas que tu club practique, goMatch está preparado</p>
                        <Button href='#contact' className='go-btn go-btn--main mt-3 w-50'> <FontAwesomeIcon icon={faPaperPlane} className='pe-3'/>  Contáctanos</Button>

                    </Col>
                   
                </Row>
            </Container>
        </section>
    
        <section id="pricing" className='py-5'>
            <Container className='pb-5'>
                <Row className=' justify-content-center'>
                    <h2 className='text-center py-5'>Planes & precios</h2>
                    <Col className='col-md-3'>
                        <div className='go-prices-item'>
                            <h3 className='go-prices-item--title'><b>GoMatch</b> Essential</h3>
                            <p className='go-prices-item--text'>Gestion de reservas</p>
                            <p className='go-prices-item--text'>Automatización de procesos</p>
                            <p className='go-prices-item--text'>Estadísticas</p>
                            <p className='go-prices-item--text'>Herramientas redes sociales</p>
                            <p className='go-prices-item--text-disabled'>Torneos</p>
                            <p className='go-prices-item--text-disabled'>Inventario</p>

                            <Button href='#contact' className='go-btn go-btn--main mt-3 w-100'> <FontAwesomeIcon icon={faPaperPlane} className='pe-3'/>  Contáctanos</Button>
                        </div>
                    </Col>
                    <Col className='col-md-3'>
                        <div className='go-prices-item'>
                            <h3 className='go-prices-item--title'><b>GoMatch</b> Advance</h3>
                            <p className='go-prices-item--text'>Gestion de reservas</p>
                            <p className='go-prices-item--text'>Automatización de procesos</p>
                            <p className='go-prices-item--text'>Estadísticas</p>
                            <p className='go-prices-item--text'>Herramientas redes sociales</p>
                            <p className='go-prices-item--text'>Torneos</p>
                            <p className='go-prices-item--text-disabled'>Inventario</p>

                            <Button href='#contact' className='go-btn go-btn--main mt-3 w-100'> <FontAwesomeIcon icon={faPaperPlane} className='pe-3'/>  Contáctanos</Button>
                        </div>
                    </Col>
                    <Col className='col-md-3'>
                        <div className='go-prices-item'>
                            <h3 className='go-prices-item--title'><b>GoMatch</b> Plus</h3>
                            <p className='go-prices-item--text'>Gestion de reservas</p>
                            <p className='go-prices-item--text'>Automatización de procesos</p>
                            <p className='go-prices-item--text'>Estadísticas</p>
                            <p className='go-prices-item--text'>Herramientas redes sociales</p>
                            <p className='go-prices-item--text'>Torneos</p>
                            <p className='go-prices-item--text'>Inventario</p>

                            <Button href='#contact' className='go-btn go-btn--main mt-3 w-100'> <FontAwesomeIcon icon={faPaperPlane} className='pe-3'/>  Contáctanos</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section id="contact" className='go-section go-section--color py-5'>
            <Container className='pb-5'>
                <Row className='justify-content-center'>
                    <h2 className='text-center py-4'>Lista de espera</h2>

                    <Col className='col-md-4 col-12'>
                    
                    <Form onSubmit={handleSubmit}>
                        <Row className='justify-content-center'>
                            <Col className='col-12'>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        className='go-form go-form--input'
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-12'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Correo</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="correo@mail.com"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className='go-form go-form--input'
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-12'>
                                <Form.Group className="mb-3" controlId="formPhone">
                                    <Form.Label>Telefono</Form.Label>
                                    <Form.Control
                                        type="phone"
                                        placeholder="988099606"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className='go-form go-form--input'

                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-12'>
                                <Form.Group className="mb-3" controlId="formText">
                                    <Form.Label>Mensaje</Form.Label><br/>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Escribe tu mensaje aquí..."
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className='go-form go-form--textarea'

                                        style={{ height: '150px' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-12'>
                                <h4 className='pt-4'>Únete a nuestra lista de espera</h4>
                                <p>Te contactaremos lo antes posible</p>
                                <Button type='submit' className='go-btn go-btn--main w-100'>
                                    <FontAwesomeIcon icon={faPaperPlane} className='pe-3'/> Enviar
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    </Col>
                    <Col className='col-md-4 col-12'>
                        <div className='go-contact-image'>
                            <img src='contacto.jpg' alt='GoMatch' className='w-100' />
                        </div>
                    </Col>
                
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro exitoso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Ya eres parte de nuestra lista de espera!</h4>
                    <p>Te contactaremos lo antes posible</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>



        </section>

        <section id='footer' className='go-section go-section--footer'>
            <Container className='pt-5'>
                <Row>
                    <Col className='col-md-4  text-center'>
                        <h4><b>GoMatch</b> </h4>
                        <p> © 2024 </p>
                        
                    </Col>
                    <Col className='col-md-4 text-center'>
                        <h4><b> Informácion  </b></h4>
                        <a href='/terms_and_conditions'>Términos y condiciones</a><br/>
                    </Col>
                    <Col className='col-md-4  text-center'>
                        <h4><b> Contacto  </b></h4>
                        <p className='mb-0'> +569 82895821</p>
                        <p> gomatchinfo@gmail.com</p>
                    </Col>
                </Row>
            </Container>
        </section>


    </>

  );
}

export default HomeScreen;
