import React, { useEffect, useState, useRef } from "react";
import AdminUserList from "../../components/Admin/AdminUserList/AdminUserList";
import { findClubUserListFilterByString, getClubUserList } from "../../utils/GoMatchApi";
import SpinnerOverlay from "../../components/GoMatch/Spinner/SpinnerOverlay";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AdminUserListScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [clubUserList, setClubUserList] = useState([]);
  const [findUserText, setFindUserText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({ page: 1, pageSize: 10, total: 0, pageCount: 0 });

  const searchTimeout = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchClubUserList = async () => {
      try {
        const response = await getClubUserList(paginationInfo.page, paginationInfo.pageSize);
        if (response.ok) {
          setClubUserList(response.data);
          setPaginationInfo((prev) => ({
            ...prev,
            total: response.pagination.total,
            pageCount: response.pagination.totalPages, // Adjusted to match backend
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClubUserList();
  }, [refreshFlag, paginationInfo.page, paginationInfo.pageSize]);

  const handleSearchUserByText = async (textToSearch) => {
    setIsSearching(true); // Indicate search start
    try {
      const response = textToSearch
        ? await findClubUserListFilterByString(textToSearch)
        : await getClubUserList(paginationInfo.page, paginationInfo.pageSize);
      if (response.ok) {
        setClubUserList(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false); // Indicate search completion regardless of outcome
    }
  };

  const setLoading = (isLoading) => {
    setIsLoading(isLoading);
  };

  const triggerRefresh = () => {
    setRefreshFlag((prevFlag) => !prevFlag);
  };

  const handleFindUsers = (textToSearch) => {
    setFindUserText(textToSearch);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      setIsSearching(true);
      handleSearchUserByText(textToSearch);
    }, 800);
  };

  if (isLoading) {
    return (
      <>
        <SpinnerOverlay />
        <Container className="go-container">
          {/* Profile card skeleton */}
          <div className="mb-3 pdl-card">
            <Skeleton height={85} className="text-center pdl-section"></Skeleton>
          </div>
          <div className="mb-3 pdl-card">
            <Skeleton height={1000} className="text-center pdl-section"></Skeleton>
          </div>
          <SpinnerOverlay />
        </Container>
      </>
    );
  }

  return (
    <div className="container go-container">
      <div>
        <AdminUserList
          clubUserList={clubUserList}
          findUserText={findUserText}
          isSearching={isSearching}
          handleFindUsers={handleFindUsers}
          setLoading={setLoading}
          triggerRefresh={triggerRefresh}
          pagination={paginationInfo}
          setPage={(page) => setPaginationInfo((prev) => ({ ...prev, page }))}
        />
      </div>
    </div>
  );
}

export default AdminUserListScreen;
