const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:1337';

export const globalAdminState = async () => {
    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/global_state`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        return { ok: true, data: data };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
};
