import React from 'react';
import RegisterForm from '../../components/GoMatch/RegisterForm/RegisterForm';

function RegisterScreen() {
  return (
    <div className='container go-container'>
      <h1 className='pdl-title-main'>Regístrate</h1>
      < RegisterForm />
    </div>
  );
}

export default RegisterScreen;
