import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getClubCashRegistersRecords, findCashRegisterRecordsFilterByUserId, getClubAdminUsernames } from '../../utils/GoMarketApi';
import CashRegisterRecordList from '../../components/GoMarket/CashRegisterRecordList';
import GoPagination from '../../components/Global/Pagination/GoPagination';
import { CashRegisterContext } from '../../contexts/GoMarket/CashRegisterContext';


const GoMarketCashRegisterRecordsScreen = () => {
  const [cashRegisterRecords, setCashRegisterRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({ totalRecords: 0, totalPages: 1 });
  const [filters, setFilters] = useState({ startDate: new Date(), actionType: '', selectedUser: '' });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cashRegisters } = useContext(CashRegisterContext);


  useEffect(() => {
  
    const loadClubAdminUsernames = async () => {
      const data = await getClubAdminUsernames();
      setUsers(data);
   
    };

    loadClubAdminUsernames();
  }, []);

  const loadCashRegisterRecords = useCallback(async () => {
    setLoading(true);
    const data = await getClubCashRegistersRecords(page);
    setCashRegisterRecords(data.records);
    setPagination(data.pagination);
    setLoading(false)
  }, [page]);

  const loadFilteredRecords = useCallback(async () => {
    if (filters.selectedUser) {
      setLoading(true);
      const data = await findCashRegisterRecordsFilterByUserId(filters.selectedUser, 10, page);
      setCashRegisterRecords(data.records); // use 'records' instead of 'cash_register_records' to match the structure
      setPagination(data.pagination);
      setLoading(false)

    } else {
      loadCashRegisterRecords();
    }
  }, [filters, page, loadCashRegisterRecords]);

  useEffect(() => {
    loadCashRegisterRecords();
  }, [loadCashRegisterRecords, cashRegisters]);

  useEffect(() => {
    loadFilteredRecords();
  }, [filters, loadFilteredRecords]);


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); // Reset to first page on filter change
  };

  return (
    <Container className='go-container'>
      <div className="go-market-cash-register-records-screen">
      
          <>
            <CashRegisterRecordList 
              records={cashRegisterRecords} 
              pagination={pagination} 
              onPageChange={handlePageChange} 
              currentPage={page}
              onFilterChange={handleFilterChange}
              users={users}
          screenLoading={loading}

            />

            <Row>
              <Col>
                <GoPagination
                  page={page}
                  totalPages={pagination.totalPages}
                  onPageChange={handlePageChange}
                />
              </Col>
            </Row>
          </>
     
      </div>
    </Container>
  );
};

export default GoMarketCashRegisterRecordsScreen;
