import React, { useEffect, useState } from 'react';
import { Table, Button, Badge, Form, InputGroup } from 'react-bootstrap';
import { getBookingStatus } from '../../utils/Functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { removePendingBookingOrItemFromOrder, updateOrder, updatePendingOrderSource } from '../../utils/GoMarketApi';
import { toast } from 'react-toastify';

const OrderReceiptContent = ({ order, reloadOrder, setLoading, setTrashClicked, handleConfirmAction, currentCashRegister  }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [cashPayed, setCashPayed] = useState('');
  const [showCashPayedField, setShowCashPayedField] = useState(false);

  useEffect(() => {
    if (order?.order_source) {
      setSelectedPaymentMethod(order.order_source);
      setCashPayed(order.order_cash_payed || '');
      setShowCashPayedField(order.order_source === 'Efectivo');
    }

   
  }, [order]);

  if (!order) {
    return <div>Error: No se ha encontrado la orden</div>;
  }

  const handleTrashClick = async (id, type) => {
    if (order.items?.length + order.bookings?.length === 1) {
      handleConfirmAction('Anulado');
    } else {
      const dataToRemove = {
        order_id: order.id,
        element_to_delete: {
          type,
          id,
        },
      };
      setLoading(true);
      setTrashClicked(true);
    
      const result = await removePendingBookingOrItemFromOrder(dataToRemove);

      if (result) {
   
        if (type === 'item') toast.success('Producto eliminado');
        if (type === 'booking') toast.success('Reserva eliminada');
        await reloadOrder();
      } else {
        console.error(`Failed to remove ${type} with ID: ${id}`);
      }

      setLoading(false);
    }
  };

  const handlePaymentMethodChange = (event) => {
    const paymentMethod = event.target.value;
    setSelectedPaymentMethod(paymentMethod);
    setShowCashPayedField(paymentMethod === 'Efectivo');
    
    if (paymentMethod !== 'Efectivo') {
      handleUpdatePaymentMethod(paymentMethod);
    }
  };

  const handleUpdatePaymentMethod = async (paymentMethod, orderCashPayed = null) => {
    
    if (currentCashRegister !== undefined) {
      const data = {
        cash_register: currentCashRegister.id,
        order_source: paymentMethod,
      };
    
      if (orderCashPayed) {
        data.order_cash_payed = orderCashPayed;
      }

      setLoading(true);
      const result = await updatePendingOrderSource(order.id, data);
      if (result) {
        toast.success('Método de pago actualizado exitosamente');
        await reloadOrder();
      } else {
        toast.error('Error al actualizar el método de pago');
      }
      setLoading(false);
    } else {
      toast.error('Debe abrir una caja para modificar esta orden');
    }
    
  };

  const handleSaveCashPayed = () => {
    handleUpdatePaymentMethod('Efectivo', cashPayed);
  };

  const renderOrderDetails = () => (
    <Table borderless responsive hover className="go-table go-table--orders go-table--orders--details">
      <tbody>
        <tr>
          <th className="go-text ps-3">Estado</th>
          <td className="go-text">
            <Badge className={`go-badge go-badge--${order.order_status.toLowerCase()}`}>
              {order.order_status}
            </Badge>
          </td>
          <th className="go-text">Venta</th>
          <td className="go-text">#{order.id}</td>
        </tr>
        <tr>
          <th className="go-text ps-3">Fecha</th>
          <td className="go-text">{order.order_date.date}</td>
          <th className="go-text">Hora de venta</th>
          <td className="go-text">{order.order_date.timeWithoutSeconds} Hrs</td>
        </tr>
        <tr>
          <th className="go-text border-bottom-none ps-3" width={'25%'}>Método de pago 
          {order.order_status === 'Pendiente' && <img src='/assets/icons/edit.svg' height={'18px'} width={'18px'} className='ms-2' alt='edit'/>} </th>
          <td className="go-text payment-methods" width={'30%'}>
           {order.order_status === 'Pendiente' ? (
              <Form.Select 
              aria-label="Seleccione método de pago" 
              value={selectedPaymentMethod} 
              onChange={handlePaymentMethodChange}
            >
              <option value="">Seleccionar</option>
              <option value="Débito">Débito</option>
              <option value="Crédito">Crédito</option>
              <option value="Transferencia">Transferencia</option>
              <option value="Efectivo">Efectivo</option>
            </Form.Select>  
          ) : ( <>{order.order_source}</>)}
          </td> 
          <th className="go-text border-bottom-none">Caja</th>
          <td className="go-text">{order.order_cash_register}</td>
          
        </tr>
        {showCashPayedField && order.order_status === 'Pendiente' && (
          <tr>
            <th className="go-text ps-3" width={'25%'}>Efectivo recibido 
              {order.order_status === 'Pendiente' && <img src='/assets/icons/edit.svg' height={'18px'} width={'18px'} className='ms-2' alt='edit'/>}
            </th>
            <td className="go-text" width={'30%'}>
           
              <InputGroup className=" go-input-group go-input-group--cash-payed w-80">
                <Form.Control
               
                  type="number"
                  value={cashPayed}
                  onChange={(e) => setCashPayed(e.target.value)}
                  placeholder="5000"
                  aria-label="cashPayed"
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={handleSaveCashPayed}>
                  Guardar
                </Button>
              </InputGroup>
            </td>
            <th className='go-text'>Vuelto</th>
            <td className='go-text'> ${order.order_cash_change} </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  const renderOrderItems = () => (
    order.items?.length > 0 && (
      <Table borderless responsive hover className='go-table go-table--orders'>
        <thead>
          <tr>
            <th></th>
            <th>Producto</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Precio unitario</th>
            <th className="text-center">Precio</th>
            {order.order_status === 'Pendiente' && <th></th>}
          </tr>
        </thead>
        <tbody>
          {order.items.map(item => (
            <tr key={item.item_id}>
              <td className='text-center'>
                <div className='item-image'>
                  <LazyLoadImage effect="blur" src={item.item_image} alt={item.item_name} height="50px" width="70px" />
                </div>
              </td>
              <td>{item.item_name || 'N/A'}</td>
              <td className="text-center">{item.item_quantity}</td>
              <td className="text-center">${item.item_sell_price || 0}</td>
              <td className="text-center">${(item.item_sell_price || 0) * parseInt(item.item_quantity, 10)}</td>
              {order.order_status === 'Pendiente' && (
                <td className='text-center'>
                  <Button variant="warning" className='go-btn go-btn--cash-trash' onClick={() => handleTrashClick(item.item_id, 'item')}>
                    <img src='/assets/icons/trash.svg' alt='trash' height={'20px'} width={'21px'} />
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );

  const renderBookings = () => (
    order.bookings?.length > 0 && (
      <Table borderless hover responsive className='go-table go-table--orders'>
        <thead>
          <tr>
            <th className="text-center">Reserva</th>
            <th className='text-center'>Fecha</th>
            <th className="text-center">Cancha</th>
            <th className="text-center">Horario</th>
            <th className="text-center">Duración</th>
            <th className="text-center">Precio</th>
            {order.order_status === 'Pendiente' && <th></th>}
          </tr>
        </thead>
        <tbody>
          {order.bookings.map(booking => (
            <tr key={booking.id}>
              <td className="text-center">
                <Badge className={`go-badge go-badge-status go-badge-status--${booking.payment_percentage}`}>
                  {getBookingStatus(booking.payment_percentage)}
                </Badge>
              </td>
              <td className='text-center'>{booking.date}</td>
              <td className="text-center">{booking.court}</td>
              <td className="text-center">{booking.start_time} - {booking.end_time} Hrs</td>
              <td className="text-center">{booking.duration_int} minutos</td>
              <td className="text-center">${booking.price || 0}</td>
              {order.order_status === 'Pendiente' && (
                <td className='text-center'>
                  <Button variant="warning" className='go-btn go-btn--cash-trash' onClick={() => handleTrashClick(booking.id, 'booking')}>
                    <img src='/assets/icons/trash.svg' alt='trash' height={'20px'} width={'21px'} />
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );

  const renderTotalAmount = () => (
    order.club && (
      <Table borderless responsive hover className='go-table go-table--orders'>
        <tbody>
          <tr>
            <td width={order.order_status === 'Pendiente' ? '70%' : '90%'} className='text-end'>
              <span className="go-text total-sell">Total</span>
            </td>
            <td width={order.order_status === 'Pendiente' ? '15%' : '10%'} className='text-start pe-3'>
              <span className="font-weight-bold go-text">${order.order_amount}</span>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  );

  return (
    <div>
        <div className="mb-3">
          <div className="pb-3">
            <h4 className="title">
              {order.order_status === 'Pendiente' ? `Pendiente #${order.id}` : `Venta #${order.id}`}
            </h4>
          </div>
          {renderOrderDetails()}
        </div>
        {renderOrderItems()}
        {renderBookings()}
        {renderTotalAmount()}
    </div>
  );
};

export default OrderReceiptContent;
