import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Modal, Badge } from 'react-bootstrap';
import { fetchSharedUserProfile, fetchLoggedInUser } from '../../utils/GoMatchApi';
import { useNavigate, useParams } from 'react-router-dom';
import './SharedProfileScreen.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faLock, faScrewdriverWrench, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import UserMatches from '../../components/GoMatch/UserMatches/UserMatches';
import Confetti from 'react-confetti';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SharedProfileScreen = () => {
    const navigate = useNavigate();
    const { username } = useParams();
    const [userData, setUserData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '', confirmAction: () => {} });
    const [error, setError] = useState(null);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showModal = (title, body, confirmAction) => {
        setModalInfo({ show: true, title, body, confirmAction });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userProfileData, loggedInUserData] = await Promise.all([
                    fetchSharedUserProfile(username),
                    fetchLoggedInUser(),
                ]);

                if (userProfileData) {
                    setUserData(userProfileData);
                } else {
                    throw new Error(`No hay datos de este usuario: ${username}`);
                }

                if (loggedInUserData) {
                    setLoggedInUser(loggedInUserData);
                } else {
                    throw new Error('No data returned for the logged-in user');
                }
            } catch (err) {
                console.error(err);
                setError(err.message);
            }
        };

        fetchData();
    }, [username]);

    if (error) {
        return (
            <Container className='go-container'>
                <Row className='pt-4 pb-2'>
                    <Col className='col-12 text-center'>
                        <p>{error}</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!userData || !loggedInUser) {
        return (
            <Container className='go-container'>
                {/* Profile card skeleton */}
                <Row className='pt-4 pb-2'>
                    <Col className='col-12 text-center'>
                        <Skeleton height={295} className='text-center pdl-section'>
                        </Skeleton>
                    </Col>
                </Row>

                {/* Basic stats skeleton */}
                <Row className='py-2'>
                    <Col className='col-4 pe-2'>
                        <Skeleton height={78} className='pdl-section pdl-section--small'>
                        </Skeleton>
                    </Col>
                    <Col className='col-4 px-2'>
                        <Skeleton height={78} className='pdl-section pdl-section--small'>
                        </Skeleton>
                    </Col>
                    <Col className='col-4 ps-2'>
                        <Skeleton height={78} className='pdl-section pdl-section--small'>
                        </Skeleton>
                    </Col>
                </Row>

                <Row className='py-2'>
                    <Col className='col-12'>
                        <Skeleton height={193} className='pdl-section'>
                        </Skeleton>
                    </Col>
                </Row>

                <Row className='py-2'>
                    <Col className='col-12'>
                        <Skeleton height={400} className='pdl-section'>
                        </Skeleton>
                    </Col>
                </Row>
            </Container>
        );
    }

    const isUserBirthdayToday = moment().format('DD/MM') === moment(userData.birthday, 'DD/MM/YYYY').format('DD/MM');

    const handleEditProfileClick = () => {
        if (userData.hasOwnership) {
            navigate(`/edit-profile/${userData.username}`);
        } else {
            console.log("No tienes permisos para editar este perfil");
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        navigate('/admin_login');
    };

    const privateInfoClass = userData.hasOwnership ? '' : 'd-none';

    const ConfirmationModal = ({ show, handleClose, handleConfirm, title, body }) => (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="pdl-btn" onClick={handleClose}>Cancelar</Button>
                <Button variant="primary" className="pdl-btn" onClick={handleConfirm}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <Container className='go-container'>
            {isUserBirthdayToday && <Confetti width={windowSize.width} height={windowSize.height} />}
            {/* Profile card */}
            <Row className='pb-2'>
                <Col className='col-12'>
                    <div className='text-center pdl-section'>
                        <div className="pdl-profile__img pdl-profile__img--shared">
                            <img
                                src={userData.profilePicture}
                                alt={userData.username}
                                className='pdl-section__image'
                            />
                        </div>
                        <h2 className='pdl-shared-title mb-0'>{userData.name} {userData.lastname}</h2>
                        <p className='pdl-shared__username'>@{userData.username}</p>
                        <Button className={`pdl-shared__settings ${privateInfoClass}`} onClick={handleEditProfileClick}>
                            <FontAwesomeIcon icon={faGear} />
                        </Button>
                        {userData.username === loggedInUser.username && (
                            <Button className="pdl-shared__logout" onClick={() => showModal('Cerrar sesión', '¿Estás seguro de que quieres cerrar sesión?', handleLogout)}>
                                <FontAwesomeIcon icon={faPowerOff} />
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>

            <ConfirmationModal show={modalInfo.show} handleClose={() => setModalInfo({ ...modalInfo, show: false })} handleConfirm={() => { modalInfo.confirmAction(); setModalInfo({ ...modalInfo, show: false }); }} title={modalInfo.title} body={modalInfo.body} />

            {/* Basic stats */}
            <Row className='py-2'>
                <Col className='col-4 pe-2'>
                    <div className='pdl-section pdl-section--small'>
                        <h2>Rank</h2>
                        <p>#24</p>
                    </div>
                </Col>
                <Col className='col-4 px-2'>
                    <div className='pdl-section pdl-section--small'>
                        <h2>Clubs</h2>
                        <p>{userData.clubs.length}</p>
                    </div>
                </Col>
                <Col className='col-4 ps-2'>
                    <div className='pdl-section pdl-section--small'>
                        <h2>Matches</h2>
                        <p>{userData.bookings.length}</p>
                    </div>
                </Col>
            </Row>

            {/* Private information */}
            <Row className={`py-2 ${privateInfoClass}`}>
                <Col className='col-12'>
                    <div className='pdl-section'>
                        <h2 className='pdl-shared-title'>Mis Datos <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faLock} /></Badge></h2>
                        <p><b>Nombre: </b>{userData.name} {userData.lastname}</p>
                        <p><b>Correo: </b>{userData.email}</p>
                        <p><b>Teléfono: </b>{userData.phone}</p>
                        <p><b>Género: </b>{userData.gender}</p>
                        <p><b>Fecha de Nacimiento: </b>{userData.birthday}</p>
                    </div>
                </Col>
            </Row>

            {/* Private User bookings */}
            <div className='pdl-section'>
                <Row>
                    <Col>
                        <h2 className='pdl-shared-title pb-2'>Partidos </h2>
                    </Col>
                    {userData.username === loggedInUser.username && (
                        <Col className='text-end'>
                            <Link className="pdl-btn pdl-btn--more" to='/reservas'>Ver todos</Link>
                        </Col>
                    )}
                </Row>
                <Row className={`py-2 ${privateInfoClass}`}>
                    <Col className='col-12'>
                        <UserMatches bookings={userData.bookings} hasOwnership={userData.hasOwnership} />
                    </Col>
                </Row>
            </div>

            {/* User clubs */}
            <Row className='py-2'>
                <Col className='col-12'>
                    <div className='pdl-section'>
                        <h2 className='pdl-shared-title'>Mis Clubs </h2>
                        <div className='pdl-shared-clubs'>
                            {userData.clubs.map((club, index) => (
                                <div key={index} className='pdl-shared-club'>
                                    <Row className='py-2'>
                                        <Col className='col-4'>
                                            <div className='pdl-shared-club__image'>
                                                <img
                                                    src={club.logoUrl}
                                                    alt={club.name}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-8 my-auto'>
                                            <h3 className='pdl-shared-club__name'>{club.name}</h3>
                                            <p className='pdl-shared-club__address'>{club.address}</p>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>

            {/* User certificates */}
            <Row className={`py-2 ${privateInfoClass} pdl-developing`}>
                <Col className='col-12'>
                    <div className='pdl-section'>
                        <h2 className='pdl-shared-title pb-2'>Mis Certificados <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                            {/* Certificates can be added here */}
                        </div>
                    </div>
                </Col>
            </Row>

            {/* User tournaments */}
            <Row className={`py-2 ${privateInfoClass} pdl-developing`}>
                <Col className='col-12'>
                    <div className='pdl-section'>
                        <h2 className='pdl-shared-title pb-2'>Mis Torneos <Badge bg="secondary" className='pdl-shared-private'><FontAwesomeIcon icon={faScrewdriverWrench} /></Badge></h2>
                        <div className='pdl-section pdl-section--tournament'>
                            {/* Tournaments can be added here */}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default SharedProfileScreen;
