import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ScheduleInformation from "../../components/GoMatch/BuildingMatch/ScheduleInformation";
import BuildingMatch from "../../components/GoMatch/BuildingMatch/BuildingMatch";
import ShareAndPayButtons from "../../components/GoMatch/BuildingMatch/ShareAndPayButtons";
import {
  createBooking,
  deleteBooking,
  findScheduleBySlug,
  findScheduleSlotsById,
  getUserId,
  getBuildingMatchUserData,
  handleToggleSchedules,
} from "../../utils/GoMatchApi";
import { splitSlug } from "../../utils/Functions";

function BuildingMatchScreen() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { pathname } = useLocation();
  const [scheduleInfo, setScheduleInfo] = useState({});
  const [scheduleSlots, setScheduleSlots] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userDataBuildingMatch, setUserDataBuildingMatch] = useState(null);
  const [userBookingIndex, setUserBookingIndex] = useState(-1); // -1 means the user is not in the bookings array
  const selectedScheduleSlug = slug;
  const selectedScheduleId = splitSlug(slug)?.id;
  const [loggedUser, setLoggedUser] = useState(true);

  const fetchScheduleInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const scheduleInfo = selectedScheduleSlug ? await findScheduleBySlug(selectedScheduleSlug) : null;
      const scheduleSlots = selectedScheduleSlug ? await findScheduleSlotsById(selectedScheduleId) : null;
      const userId = await getUserId();
      if (!userId) setLoggedUser(false);
      if (scheduleInfo && scheduleSlots) {
        setScheduleInfo(scheduleInfo.data);
        setScheduleSlots(scheduleSlots.data);
        setUserId(userId?.id);
        // Return the index of the user booking in the bookings array. If the user is not in the bookings array, return -1
        setUserBookingIndex(scheduleInfo?.data?.bookings?.findIndex((booking) => booking.user.id === userId?.id));
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      // navigate("/login", { state: { selectedScheduleId, selectedScheduleSlug } });
    }
  }, [selectedScheduleId, selectedScheduleSlug]);

  const getUserDataBuildingMatch = async () => {
    try {
      const response = await getBuildingMatchUserData();
      setUserDataBuildingMatch(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchScheduleInfo();
    getUserDataBuildingMatch();
  }, [fetchScheduleInfo]);

  const handleUpdateScheduleInfo = (scheduleInfo) => {
    setScheduleInfo(scheduleInfo);
    setUserBookingIndex(scheduleInfo?.bookings?.findIndex((booking) => booking.user.id === userId));
  };

  const confirmPayment = async () => {
    setIsLoadingButton(true);
    if (!loggedUser) {
      handleRedirectToLogin();
      return;
    }

    // userBookingIndex -1 Means the logged user is not in the response of the bookings array, so we need to create a new booking
    // userBookingIndex -1 also could mean that the logged user is "visualy" in the bookings array, but not in the database
    // userBookingIndex >= 0 Means the logged user is in the bookings array, so we need to update the booking slots
    let bookingId = scheduleInfo?.bookings[userBookingIndex]?.bookingId;

    // Here we create many bookings as players in the schedule. We need to wait for each booking to be created before continue, so we use Promise.all
    await Promise.all(
      Array.from(scheduleInfo?.bookings ?? 0).map(async (booking, i) => {
        if (!booking.bookingId) {
          // The following setTimeout exist to avoid parallel requests to the server. The afterCreate lifecyle booking on backend works better with this approach
          // Also avoid duplicate sending emails requests
          await new Promise((resolve) => {
            setTimeout(async () => {
              const response = await handleCreateBooking(booking.bookingIsGuest);
              if (i === 0) bookingId = response.data.id; // The first bookingId is the one we need to use for the payment and the logged player
              resolve();
            }, i * 1000);
          });
        }
      })
    );

    const paymentState = {
      payingAmount: scheduleInfo?.schedulePrice / scheduleInfo?.scheduleTotalSlots,
      payingSlots: 1,
      bookingId: bookingId,
      scheduleId: selectedScheduleId,
    };
    handleToggleSchedules(selectedScheduleId);
    navigate(`/payment/${slug}`, { state: paymentState });
    setIsLoadingButton(false);
  };

  const handleCreateBooking = async (isGuest) => {
    const bookingData = {
      bookingSlots: 1,
      scheduleId: selectedScheduleId,
      slug: selectedScheduleSlug,
      userId: userId,
      isGuest: isGuest,
    };
    const response = await createBooking(bookingData);
    return response.data;
  };

  const handleDeleteBooking = async (bookingId) => {
    setIsLoadingButton(true);
    if (userBookingIndex === -1 || !scheduleInfo?.bookings[userBookingIndex].bookingId) {
      setScheduleInfo(scheduleInfo.bookings.pop());
    } else {
      await deleteBooking(bookingId);
      handleToggleSchedules(selectedScheduleId);
      // Update scheduleInfo and scheduleSlots
      // * Consider to create a custom api just to update the booking information, so we don't need to reload the entire screen
      fetchScheduleInfo();
    }
    setIsLoadingButton(false);
  };

  const handleRedirectToLogin = () => {
    localStorage.setItem("redirect_to", pathname);
    navigate("/Login");
  };

  return (
    !isLoading && (
      <div className="container go-container">
        <h1 className="pdl-title-main">Building Match</h1>
        <ScheduleInformation scheduleInfo={scheduleInfo} />
        <BuildingMatch
          scheduleInfo={scheduleInfo}
          scheduleSlots={scheduleSlots}
          userId={userId}
          userDataBuildingMatch={userDataBuildingMatch}
          loggedUser={loggedUser}
          disableDeleteButton={isLoadingButton}
          handleUpdateScheduleInfo={handleUpdateScheduleInfo}
          handleDeleteBooking={handleDeleteBooking}
          handleRedirectToLogin={handleRedirectToLogin}
        />
        <ShareAndPayButtons
          userSlots={scheduleInfo?.bookings[userBookingIndex]?.bookingSlots}
          disablePayButton={userBookingIndex === -1 || isLoadingButton} // Send true if the user is not in the bookings array or if the button is loading
          loggedUser={loggedUser}
          confirmPayment={confirmPayment}
        />
      </div>
    )
  );
}
export default BuildingMatchScreen;
