import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { handleLogin } from "../../../utils/GoMatchApi"; // Adjust path as necessary
import "./LoginForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function LoginForm({ registeredEmail }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // New state for toggling password visibility
  const navigate = useNavigate();

  useEffect(() => {
    if (registeredEmail) {
      setUsername(registeredEmail);
    }
  }, [registeredEmail]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const login = async () => {
    const result = await handleLogin(username, password);
    if (result.ok) {
      const redirect = localStorage.getItem("redirect_to");
      if (redirect) {
        localStorage.removeItem("redirect_to");
        navigate(redirect);
        window.location.reload();
        return;
      }
      // navigate("/feed");
      navigate("/admin_dashboard");
      window.location.reload();
    } else {
      toast.error(`Correo y/o contraseña incorrectos`);
    }
  };

  return (
    
      <div className="login-form">
        <div className='pdl-section my-3'>
        <input
          className="pdl-input"
          type="text"
          placeholder="Correo"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <div className="password-input-container">
          <input
            className="pdl-input"
            type={showPassword ? "text" : "password"}
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </span>
        </div>
        <div>
          <Link to="/password_reset">Olvidaste tu contraseña?</Link>
        </div>
        <button className="btn btn-success mt-3 w-100 text-center" onClick={login}>
          Ingresar
        </button>
        </div>

        <div className='pdl-section my-3'>
            <div className="">
              <h4 className="text-center">¿Aún no tienes cuenta?</h4>
              <Link to="/Register" className="btn btn-primary w-100 text-center">
                Regístrate aquí
              </Link>
            </div>
        </div>
        <div className='pdl-section my-3'>

          <h6 className="text-center">
            ¿Eres administrador?{" "}
            <div className="admin-loggin-button" onClick={() => navigate("/admin_login")}>
              Inicia sesión aquí
            </div>{" "}
          </h6>
        </div>
      </div>
  
  );
}

export default LoginForm;
