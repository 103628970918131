import React, { useEffect, useState } from "react";
import "./FeedScreen.scss";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "../../utils/GoMatchApi";
import { user_role_type } from "../../utils/Constants";
import SpinnerOverlay from "../../components/GoMatch/Spinner/SpinnerOverlay";

const FeedScreen = () => {
  const [userRole, setUserRole] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const padelClubs = [
    { name: "Club A", location: "City A" },
    { name: "Club B", location: "City B" },
    { name: "Club C", location: "City C" },
    { name: "Club D", location: "City D" },
    { name: "Club E", location: "City E" },
  ];

  const handleCourtsScreen = () => {
    navigate("/court");
  };

  useEffect(() => {
    const getUserRoleFunction = async () => {
      try {
        const response = await getUserRole();
        setUserRole(response?.data?.user === user_role_type.CLUB_ADMIN);
        setIsLoading(false);
      } catch (err) {
        console.log("Error: ", err.message);
        setIsLoading(false);
      }
    };
    getUserRoleFunction();
  }, []);

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <div className="container  go-container">
      <h1 className="pdl-title-main">Feed Screen</h1>
      <div className="pb-3">
        <button className="btn btn-success w-100 mt-2" onClick={handleCourtsScreen}>
          Canchas disponibles
        </button>
      </div>
      {userRole && (
        <div className="pb-3">
          <button
            className="btn btn-success w-100 mt-2"
            onClick={() => navigate("/admin_dashboard")}
          >
            Dashboard Administrador
          </button>
        </div>
      )}
      <ul>
        {padelClubs.map((club, index) => (
          <li key={index}>
            <h2>{club.name}</h2>
            <p>{club.location}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeedScreen;
