import React, { useState, useEffect, useContext } from 'react';
import './OrderList.scss';
import '../../components/Global/Datepicker/Datepicker.scss';
import { Col, Row, Form, Modal, Button, Badge, Table } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { getOrderById, updateOrder, cancelOrderByOrderId } from '../../utils/GoMarketApi';
import { Link } from 'react-router-dom';
import OrderReceiptContent from './Order';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../Global/Loader/Loader';
import useMediaQuery from '../../hooks/useMediaQuery';
import { locale, getBookingStatus } from '../../utils/Functions';
import { CashRegisterContext } from '../../contexts/GoMarket/CashRegisterContext';

const OrderList = ({
  setPage,
  orders,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setStatus,
  reloadOrders,
  screenLoading
}) => {
  const [activeOrder, setActiveOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const { id_order } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isRegisterOpen, cashRegisters, currentUserId } = useContext(CashRegisterContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const currentRegisterOpen = cashRegisters.some(cr => cr.user_id === currentUserId?.id && cr.cash_register_open);
    setIsButtonDisabled(!currentRegisterOpen);
  }, [isRegisterOpen, cashRegisters, currentUserId]);

  useEffect(() => {
    if (id_order) {
      handleShow(id_order);
    }
  }, [id_order]);

  const handleShow = async (orderId) => {
    setLoading(true);
    setActiveOrder({});
    navigate(`/admin/ventas/${orderId}`);

    const order = await getOrderById(orderId);
    setActiveOrder(order);
    setLoading(false);
  };

  const handleClose = () => {
    setActiveOrder(null);
    setShowConfirm(false);
    navigate(`/admin/ventas`);
  };

  const handleUpdateOrderStatus = async (orderId, status) => {
    setLoading(true);
    const response = await updateOrder(orderId, { order_status: status });
    if (response) {
      setActiveOrder(prevOrder => ({ ...prevOrder, order_status: status }));
      toast.success(`Venta #${orderId} ${status === "Anulado" ? "Anulada" : "Validada"}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      reloadOrders();
    }
    setLoading(false);
    handleClose();
  };

  const handleCancelOrder = async (orderId) => {
    setLoading(true);
    const response = await cancelOrderByOrderId(orderId);
   
    if (response) {
      console.log(response);
      toast.success(`Venta #${orderId} anulada`, {
        position: toast.POSITION.TOP_RIGHT
      });
      reloadOrders();
    }
    setLoading(false);
    handleClose();
  };

  const handleConfirmAction = (action) => {
    setConfirmAction(action);
    setShowConfirm(true);
  };

  const handleConfirmCancel = () => {
    setShowConfirm(false);
    setConfirmAction(null);
  };

  const handleConfirmAccept = () => {


    if(confirmAction === 'Anulado' && activeOrder) {
      handleCancelOrder(activeOrder.id);
    
    } else if (confirmAction === 'Pagado' && activeOrder) {
      handleUpdateOrderStatus(activeOrder.id, confirmAction);
    }
  
    setShowConfirm(false);
    setConfirmAction(null);
  };

  const groupBookingsByPaymentPercentage = (bookings) => {
    return Object.values(
      bookings.reduce((acc, booking) => {
        if (acc[booking.payment_percentage]) {
          acc[booking.payment_percentage].count += 1;
        } else {
          acc[booking.payment_percentage] = {
            status: getBookingStatus(booking.payment_percentage),
            payment_percentage: booking.payment_percentage,
            count: 1
          };
        }
        return acc;
      }, {})
    );
  };

  const renderSkeleton = () => (
    <Row>
      <Col xs={12} sm={12} md={12} className='pb-3'>
        <Skeleton height={566} className='go-skeleton go-skeleton--card pt-3'/>
      </Col>
    </Row>
  );

  const renderOrdersAsTable = () => (
    <Table borderless responsive hover className='go-table go-table--orders'>
      <thead>
        <tr>
          <th>#</th>
          <th>Estado</th>
          <th>Fecha</th>
          <th>Hora</th>
          <th>Pagador</th>
          <th>Precio</th>
          <th>Contenido</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={order.id} onClick={() => handleShow(order.id)}>
            <td>#{order.id}</td>
            <td>
              <Badge className={`go-badge go-badge--${order.order_status.toLowerCase()}`}>
                {order.order_status}
              </Badge>
            </td>
            <td>{order.order_date}</td>
            <td>{order.order_date_time} Hrs</td>
            <td>{order.buyer_name ? `${order.buyer_name} ${order.buyer_lastname}` : "Invitado"}</td>
            <td>${order.order_amount}</td>
            <td>
              {groupBookingsByPaymentPercentage(order.bookings).map((group, index) => (
                <React.Fragment key={index}>
                  <Badge className={`go-badge me-2 mb-1 go-badge-status go-badge-status--${group.payment_percentage}`}>
                    {group.count > 1 ? `${group.count} x ` : ''} {group.status}
                  </Badge>
                  <br />
                </React.Fragment>
              ))}
              {order.item_order_quantities > 0 && (
                <Badge className="go-badge go-badge-status go-badge-status--items">
                  {order.item_order_quantities > 1 ? `${order.item_order_quantities} x Productos` : 'Producto'}
                </Badge>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const renderModalContent = () => {
    if (showConfirm) {
      // Get unique payment_percentage values
      const uniquePaymentPercentages = [...new Set(activeOrder.bookings.map(booking => booking.payment_percentage))];
      
      // Determine if there are p0 and non-p0 bookings
      const hasP0 = uniquePaymentPercentages.includes('p0');
      const hasNonP0 = uniquePaymentPercentages.some(percentage => percentage !== 'p0');
  
      return (
        <div className=''>
          <h4 className='title'>{confirmAction === 'Anulado' ? 'Anular' : 'Validar'} venta #{activeOrder.id}</h4>
          <p className='go-text'>
            ¿Estás seguro que quieres {confirmAction === 'Anulado' ? 'anular' : 'validar'} esta venta?
          </p>
  
          {/* Only render the first message if there are p0 bookings */}
          {confirmAction === 'Anulado' && hasP0 && (
            <div className='go-alert go-alert--info mb-3'>
              <Row>
                <Col md={12}>
                  <p className='go-text'>
                    Los siguientes elementos volverán a la pestaña reservas en <Link className='go-link' to='/admin/gomarket'>GoMarket</Link>
                  </p>
                  <div className='pb-3'>
                    {uniquePaymentPercentages.filter(percentage => percentage === 'p0').map((percentage, index) => (
                      <Badge key={index} className={`go-badge go-badge-status go-badge-status--${percentage} me-2`}>{getBookingStatus(percentage)} </Badge>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          )}
  
          {/* Only render the second message if there are non-p0 bookings */}
          {confirmAction === 'Anulado' && hasNonP0 && (
            <div className='go-alert go-alert--info'>
              <Row>
                <Col md={12}>
                  <p className='go-text'>
                    Las siguientes reservas serán eliminadas y sus respectivos bloques horarios quedarán disponibles, revisa la disponibilidad en <Link className='go-link' to='/admin/gocalendar'>GoCalendar</Link>
                  </p>
                  <div className='pb-3'>
                    {uniquePaymentPercentages.filter(percentage => percentage !== 'p0').map((percentage, index) => (
                      <Badge key={index} className={`go-badge go-badge-status go-badge-status--${percentage} me-2`}>{getBookingStatus(percentage)} </Badge>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          )}
  
          <Row className="pt-4">
            <Col md={4} xs={6} className="ms-auto">
              <Button variant="secondary" className="go-btn go-btn--form-cancel" onClick={handleConfirmCancel}>
                No, cancelar
              </Button>
            </Col>
            <Col md={4} xs={6}>
              <Button variant="danger" className={`go-btn ${confirmAction === 'Anulado' ? 'go-btn--danger' : 'go-btn--add'}`} onClick={handleConfirmAccept}>
                {confirmAction === 'Anulado' ? 'Sí, anular' : 'Sí, validar'}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  
    return (
      <div className=''>
        <OrderReceiptContent order={activeOrder} />
        <Row className="pt-4">
          <Col xs={12} md={4} className="ms-auto">
            <Button variant='secondary' className="go-btn go-btn--close mb-3 mb-md-0" onClick={handleClose}>Cerrar</Button>
          </Col>
          {activeOrder.order_status !== 'Anulado' && (
            <Col xs={12} md={4}>
              <Button variant="secondary" className="go-btn go-btn--delete mb-3 mb-md-0" onClick={() => handleConfirmAction('Anulado')}>
                Anular venta
              </Button>
            </Col>
          )}
          {activeOrder.order_status === 'Pendiente' && (
            <Col xs={12} md={4}>
              <Button variant="secondary" className={`go-btn go-btn--add ${isButtonDisabled ? 'go-disabled' : ''}`} onClick={() => handleConfirmAction('Pagado')} disabled={isButtonDisabled}>
                Validar venta
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  };
  
  
  

  return (
    <>
      <Row>
        <div className='go-page-title'>
          <h4 className='go-text'><img src='/assets/icons/menu/ventas.svg' alt="Ventas" /> Historial de ventas</h4>
        </div>
        <Col xs={12}>
          <p className='go-text go-text--light mb-1'>Se muestran fechas y horas del momento de la venta.</p>
          <p className='go-text go-text--light'>Seleccione los filtros correspondientes para una búsqueda más precisa en el historial de ventas.</p>
          </Col>
          <Col md={3} xs={6}>
            <div className='go-datepicker'>
            <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setPage(1); // Reset to page 1
                  }}
                  dateFormat="dd/MM/yyyy"
                  className="form-control mb-3 w-100"
                  placeholderText="Fecha inicial"
                  maxDate={new Date()}
                  isClearable
                  locale={locale}
                  onKeyDown={(e) => e.preventDefault()}
                />

            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className='go-datepicker'>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setPage(1); // Reset to page 1
                }}
                dateFormat="dd/MM/yyyy"
                className="form-control mb-3 w-100"
                placeholderText="Fecha final"
                isClearable
                locale={locale}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </Col>
          <Col md={3} xs={12}>
            <div className='go-form'>
              <Form.Select aria-label="Estado de Pago"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setPage(1); // Reset to page 1
                }}>
                <option value="">Estado de Pago</option>
                <option value="Pagado">Pagado</option>
                <option value="Anulado">Anulado</option>
                <option value="Error">Error</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
  
        {screenLoading ? renderSkeleton() : (
          orders.length > 0 ? (
            <>
              {renderOrdersAsTable()}
  
              {activeOrder && (
                <Modal show={true} size={showConfirm ? "md" : "lg"} centered onHide={handleClose} className='go-modal go-modal--order'>
                  <Modal.Header closeButton />
                  <Modal.Body>
                    <div className={showConfirm ? "go-confirm-dialog" : "go-order-detail"}>
                      {loading ? <Loader insideModal={true} /> : renderModalContent()}
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className='text-center text-muted py-5'>
                  <p className='go-text mb-0'>No se han encontrado ventas con estos parámetros</p>
                  <p className='go-text'>Genera ventas en <a href="/admin/gomarket" className='go-link'>GoMarket</a></p>
                </div>
              </Col>
            </Row>
          )
        )}
      </>
    );
  };
  
  export default OrderList;
  