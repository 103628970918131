import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "./ShareAndPayButtons.js";

function ShareAndPayButtons({ disablePayButton, confirmPayment, loggedUser }) {
  const handleCopyLink = () => {
    const urlToCopy = window.location.href;

    try {
      toast.success("Enlace copiado con éxito. ¡Compártelo!");
      copyToClipboard(urlToCopy);
    } catch (err) {
      toast.error("No se pudo copiar el link del partido. Intente nuevamente");
      console.log("handleCopyLink -> Error al copiar URL con SLUG", err);
    }
  };

  function copyToClipboard(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        console.log("Text copied to clipboard");
        toast.success("Texto copiado!");
      } else {
        throw new Error("Copy command unsuccessful");
      }
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast.error("Error al copiar el texto");
    }

    document.body.removeChild(textarea);
  }

  return (
    <div>
      <Row className="share-and-pay">
        <Col xs="4">
          <Button variant="success" className="share-and-pay-button" onClick={handleCopyLink}>
            <FontAwesomeIcon icon={faLink} />
          </Button>
        </Col>
        <Col xs="8">
          <Button
            variant="success"
            className="share-and-pay-button"
            onClick={confirmPayment}
            disabled={loggedUser ? disablePayButton : loggedUser}
          >
            {loggedUser ? "Asegura tu cancha" : "Inicia sesión para reservar"}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ShareAndPayButtons;