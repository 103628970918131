import React from 'react';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import './ItemForm.scss';
import Button from '../../Global/GoButton/GoButton'; // Import the new Button component
import Loader from '../../Global/Loader/Loader';

const ItemForm = ({ formData, handleInputChange, handleCheckboxChange, handleSubmit, handleDelete, handleCloseModal, isNew, isProduct, handleFileChange, imagePreview, uploading, saving, fileName, categories }) => {
  const handleCancel = () => {
    handleCloseModal(); // Call the handleCloseModal function passed from the parent
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };


  return (
    <div className='go-form go-form--inventory'>
      {saving ? (
        <Loader insideModal={true} />
      ) : (
        <Form onSubmit={handleSubmit} className='go-form'>
          <Row>
          {!isProduct  ? (
            <Col xs={12} md={6}>
              <Form.Group controlId="item_image">
                <Form.Label>Imagen del producto</Form.Label>
                <div className="file-input-wrapper">
                  <button type="button" className="file-input-button" onClick={triggerFileInput}>
                    {fileName}
                  </button>
                  <input
                    type="file"
                    id="fileInput"
                    name="item_image"
                    onChange={handleFileChange}
                    style={{ display: 'none' }} // Hide the default file input
                  />
                </div>
                {imagePreview && (
                  <div className="image-preview">
                    <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />
                  </div>
                )}
                {uploading && (
                  <Spinner animation="border" role="status" style={{ marginTop: '10px' }}>
                    <span className="sr-only">Subiendo...</span>
                  </Spinner>
                )}
              </Form.Group>
            </Col>
          ): null}
            <Col xs={12} md={6}>
                    <Form.Group controlId="item_name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            name="item_name"
                            value={formData.item_name || ''}
                            onChange={handleInputChange}
                            placeholder='Gatorade 500cc'
                            required
                        />
                    </Form.Group>
            </Col>
            <Col xs={12} md={6}>
                <Form.Group controlId="item_brand">
                    <Form.Label>Marca</Form.Label>
                    <Form.Control
                        type="text"
                        name="item_brand"
                        value={formData.item_brand || ''}
                        onChange={handleInputChange}
                        required
                        placeholder='Adidas, Nike, etc.'
                    />
                </Form.Group>
            </Col>
            {!isProduct  ? (
            <Col xs={12} md={6}>
            <Form.Group controlId="item_category">
                <Form.Label>Categoria</Form.Label>
                <Form.Select
                    name="item_category_id" // This should match the key in your formData state
                    value={formData.item_category_id} 
                    onChange={handleInputChange}
                    required
                >
                    <option value="" disabled>Seleccione una categoría</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.item_category_name}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
            </Col>
            ): null}

            <Col xs={12} md={3}>
                <Form.Group controlId="item_buy_price">
                    <Form.Label>Costo</Form.Label>
                    <Form.Control
                        type="number"
                        name="item_buy_price"
                        value={formData.item_buy_price || null}
                        onChange={handleInputChange}
                        placeholder='Costo del producto'
                        required
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group controlId="item_sell_price">
                    <Form.Label>Precio</Form.Label>
                    <Form.Control
                        type="number"
                        name="item_sell_price"
                        value={formData.item_sell_price || null}
                        onChange={handleInputChange}
                        placeholder='Precio de venta'
                        required
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={6}>
                <Form.Group controlId="item_description">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                        type="textarea"
                        name="item_description"
                        value={formData.item_description || ''}
                        onChange={handleInputChange}
                        placeholder='Descripción del producto'
                    />
                </Form.Group>
            </Col>

            <Col xs={12} md={3}>
                <Form.Group controlId={isProduct ? "item_stock_stock" : "stock" }>
                    <Form.Label>Stock</Form.Label>
                    <Form.Control
                        type="text"
                        name={isProduct ? "item_stock_stock" : "stock" }
                        value={isProduct ? formData.item_stock_stock : formData.stock }
                        onChange={handleInputChange}
                        placeholder='0'
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group controlId={isProduct ? "item_stock_critical_stock" : "critical_stock" }>
                    <Form.Label>Stock crítico</Form.Label>
                    <Form.Control
                        type="text"
                        name={isProduct ? "item_stock_critical_stock" : "critical_stock" }
                        value={isProduct ? formData.item_stock_critical_stock : formData.critical_stock }
                        onChange={handleInputChange}
                        placeholder='0'
                    />
                </Form.Group>
            </Col>
                 
            {!isProduct  ? (
          
            <Col xs={12} md={6}>
                <Form.Group controlId="item_barcode">
                    <Form.Label>Código de barra</Form.Label>
                    <Form.Control
                        type="text"
                        name="item_barcode"
                        value={formData.item_barcode || ''}
                        onChange={handleInputChange}
                        placeholder='128371237ASDGAHABD'
                    />
                </Form.Group>
            </Col>
            ): null}

            <Col xs={12} md={3}>
                <Form.Group controlId="item_favorite">
                    <Form.Check
                        type="checkbox"
                        name="item_favorite"
                        label="Favorito"  
                        checked={formData.item_favorite}
                        onChange={handleCheckboxChange}
                    />
                </Form.Group>
            </Col>
               
               
            {/* <Col className='col-md-3 col-12'>
                <Form.Group controlId="item_bulk">
                    <Form.Check
                        type="checkbox"
                        name="item_bulk"
                        label="Granel"
                        checked={formData.item_bulk}
                        onChange={handleCheckboxChange}
                    />
                </Form.Group>
            </Col> */}
          </Row>

          <Row className={isProduct ? "go-products-buttons" : "mt-4"}>
          
            <Col xs={12} md={4} className='mb-3'>
              <Button type="button" onClick={handleCancel} className="go-btn go-btn--form-cancel">
                Cancelar
              </Button>
            </Col>
            <Col xs={12} md={4} className='mb-3'>
              {!isNew && !isProduct && (
                <Button type="button" onClick={handleDelete} className="go-btn go-btn--delete">
                  Borrar producto
                </Button>
              )}
            </Col>
            <Col xs={12} md={4} >
              <Button type="submit" onClick={handleSubmit} className="go-btn go-btn--form-submit" disabled={saving}>
                {isNew ? 'Crear producto' : 'Guardar cambios'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default ItemForm;
