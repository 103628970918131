import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Table, Button, Badge, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCart } from '../../contexts/GoMarket/CartContext';
import GoButton from '../../components/Global/GoButton/GoButton';
import PaymentMethodModal from './Modals/PaymentMethodModal';
import { createOrder } from '../../utils/GoMarketApi';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBookingStatus } from '../../utils/Functions';
import './CashRegister.scss';
import { CashRegisterContext } from '../../contexts/GoMarket/CashRegisterContext';
import useEnterKeyPress from '../../hooks/useEnterKeyPress';


function CashRegister({ setRefreshBookings }) {
  const { cashRegisters, currentUserId, isRegisterOpen, refreshCashRegisters } = useContext(CashRegisterContext);
  const { cartItems, cartBookings = [], setCartBookings, removeFromCart, handleRemoveBooking, clearCart, total } = useCart();
  const [showModal, setShowModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [amountPaid, setAmountPaid] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePayment = async (paymentStatus) => {
    setLoading(true);
  
    const orderItems = cartItems.map(item => ({ itemId: item.id, quantity: item.quantity }));
    const orderBookings = cartBookings.map(booking => ({ id: booking.id }));
    const getRegisterOpenByCurrentUser = cashRegisters.find(cr => cr.users_permissions_user.id === currentUserId && cr.cash_register_open);
    
    const payload = {
      order_currency: "CLP",
      order_source: paymentMethod,
      order_status: paymentStatus,
      cash_register: getRegisterOpenByCurrentUser?.id,
      order_items: orderItems,
      bookings: orderBookings,
      ...(paymentMethod === 'Efectivo' && { order_cash_payed: amountPaid }),
      ...(selectedUser && {
        order_send_mail_client: {
          user_id: selectedUser.id,
          email: selectedUser.email,
          name: selectedUser.name,
          lastname: selectedUser.lastname,
        }
      })
    };
  
    const response = await createOrder(payload);
    
    if (response) {
      const totalAmount = total + cartBookings.reduce((acc, booking) => acc + booking.booking_price, 0);
  
      if (response.orden.order_status === 'Pendiente') {
        toast.success(
          <>
            Total: <span className="go-link">${totalAmount}</span>, 
            Revisa los detalles en <Link to="/admin/pendientes" className="go-link">pendientes</Link>
          </>, 
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (response.orden.order_status === 'Pagado') {
        toast.success(
          <>
            Método: <span className="go-link">{paymentMethod}</span>, Total: <span className="go-link">${totalAmount}</span>, 
            Vuelto: <span className="go-link">${response.orden.order_change}</span>,
            Revisa los detalles en <Link to="/admin/ventas" className="go-link">ventas</Link>
          </>, 
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
  
      setLoading(false);
      handleClose();
      setAmountPaid('');
      setPaymentMethod('');
      clearCart();
      setCartBookings([]);
      setRefreshBookings(true);
  
      if (response.orden.order_status === 'Pendiente' || response.orden.order_details.bookings.length >= 1) {
        refreshCashRegisters();
      }
    } else {
      toast.error('Error al crear el pedido. Por favor, inténtelo de nuevo.', {
        position: toast.POSITION.TOP_RIGHT
      });
      setAmountPaid('');
      setPaymentMethod('');
      setLoading(false);
    }
  };
  
  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    if (method !== 'efectivo') {
      setAmountPaid(total.toString());
    } else {
      setAmountPaid('');
    }
  };

  const handleNumericInput = (digit) => {
    setAmountPaid(prev => prev + digit);
  };

  const handleAddDenomination = (value) => {
    const currentAmount = parseInt(amountPaid || '0', 10);
    setAmountPaid((currentAmount + value).toString());
  };

  const handleDelete = () => {
    setAmountPaid(prev => prev.slice(0, -1));
  };

  const handleClearCart = () => {
    clearCart();
    setCartBookings([]);
  };

  const handleBackToMethodSelection = () => {
    setPaymentMethod('');
    setAmountPaid('');
    handleClose();
  };

  const isButtonDisabled = !isRegisterOpen || (cartItems.length === 0 && cartBookings.length === 0);

  useEnterKeyPress(
    !isButtonDisabled ? handleOpen : () => {}, 
    !showModal // Only trigger when the modal is not already open
  );
  

  return (
    <>
     <div className='go-shopping-cart'>

        <div className='go-shopping-cart__header'>
          <h4 className='go-text'><img src='/assets/icons/shopping-cart.svg' className='me-3' alt='box'/> Carrito de compras</h4>
        </div>
          
          <div className='go-cash-register-content--tables'>
            {/* Items Table */}
            {cartItems.length > 0 && (
              <Table borderless hover className='go-table go-table--orders'>
                <thead>
                  <tr>
                    <th width={"70%"} className='ps-3'>Producto</th>
                    <th width={"10%"} className='text-center'>#</th>
                    <th width={"25%"}>Precio</th>
                    <th width={"20%"}></th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={index} className='table-row-animate-in'>
                      <td>{item.item_name}</td>
                      <td className='text-center'>{item.quantity}</td>
                      <td>${item.price}</td>
                      <td className='text-end'>
                        <Button variant="warning" className='go-btn go-btn--cash-trash' onClick={() => removeFromCart(item.id, 1)}><img src='/assets/icons/trash.svg' alt='trash'/></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {/* Bookings Table */}
            {cartBookings.length > 0 && (
              <Table borderless hover className='go-table go-table--orders'>
                <thead>
                  <tr>
                    <th style={{ width: '25%' }} className='ps-3'>Reserva</th>
                    <th style={{ width: '30%' }}>Horario</th>
                    <th style={{ width: '20%' }}>Cancha</th>
                    <th style={{ width: '20%' }}>Precio</th>
                    <th style={{ width: '20%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {cartBookings.map((booking, index) => (
                    <tr key={index} className='table-row-animate-in'>
                      <td>
                        <Badge className={"go-badge go-badge-status go-badge-status--" + booking.booking_payment_percentage}>
                          {getBookingStatus(booking.booking_payment_percentage)}
                        </Badge>
                      </td>
                      <td className=''>{booking.schedule_start_time} - {booking.schedule_end_time}</td>
                      <td className=''>{booking.court_name}</td>
                      <td className=''>${booking.booking_price}</td>
                      <td className='text-end'>
                        <Button variant="warning" className='go-btn go-btn--cash-trash' onClick={() => handleRemoveBooking(booking.id)}><img src='/assets/icons/trash.svg' alt='trash'/></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>

          <div className='go-shopping-cart__total'>
            <Table borderless hover className='go-table go-table--orders'>
              <tbody>
                <tr>
                  <td width={"47%"} className='text-start'>
                    <span className="go-text total-sell ps-3">Total</span>
                  </td>
                  <td width={"18.5%"} className='text-start pe-3'>
                    <span className="font-weight-bold go-text go-text-bold">
                      ${total + cartBookings.reduce((acc, booking) => acc + booking.booking_price, 0)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Row className='mb-3'>
              <Col>
                <GoButton variant="secondary" className="go-btn go-btn--close" onClick={handleClearCart}>Cancelar</GoButton>
              </Col>
              <Col>
                <GoButton
                  variant="primary"
                  className={`go-btn go-btn--add ${isButtonDisabled ? "go-disabled" : ""}`}
                  onClick={handleOpen}
                >
                  Pagar
                </GoButton>
              </Col>
            </Row>
          </div>

          <PaymentMethodModal
            showModal={showModal}
            handleClose={handleClose}
            handlePaymentMethodChange={handlePaymentMethodChange}
            paymentMethod={paymentMethod}
            handleNumericInput={handleNumericInput}
            handleAddDenomination={handleAddDenomination}
            amountPaid={amountPaid}
            handleDelete={handleDelete}
            total={total + cartBookings.reduce((acc, booking) => acc + booking.booking_price, 0)}
            setAmountPaid={setAmountPaid}
            handleBackToMethodSelection={handleBackToMethodSelection}
            handlePayment={handlePayment}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            loading={loading}
          />
        </div>
    </>
  );
}

export default CashRegister;
