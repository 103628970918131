import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { getPaymentStatusClass, isScheduleDisabled, canTriggerClick, getHighlightClass, calculateTotalBookings, scheduleStatus } from '../../../utils/Functions.js';
import { possible_schedule_durations } from '../../../utils/Constants.js';

const ScheduleItem = ({ 
    schedule, index, schedules, 
    setHoveredItemId, handleOpenModal, 
    selectedDuration, hoveredItemId, handleOpenSettings, previewSchedule, inModal
}) => {
    const paymentStatusClass = getPaymentStatusClass(schedule);
    const isNextBlocked = previewSchedule ? false : isScheduleDisabled(schedule, schedules[index + 1], selectedDuration, index, schedules);
    const isNextPlusOneBlocked = previewSchedule ? false : isScheduleDisabled(schedule, schedules[index + 2], selectedDuration, index, schedules);
    const isHide = schedule.hide ? 'd-none' : '';

    const highlightClass = previewSchedule ? '' : getHighlightClass(schedule, index, schedules, hoveredItemId, selectedDuration);
    const scheduleClasses = classNames(
        'pdl-schedules-item',
        'go-schedules-blocks-item',
        !schedule.available ? 'go-schedules-duration--' + schedule.duration_int : '',
        !schedule.available ? 'pdl-schedules-item--blocked' : highlightClass,
        paymentStatusClass,
        isHide,
        isNextBlocked,
        selectedDuration === 5 && isNextPlusOneBlocked
    );

    const selectedDurationInt = possible_schedule_durations.find(duration => duration.id === selectedDuration)?.duration_int;

    const allowsClickBasedOnClass = ['p100', 'p50'].includes(schedule?.payment_status) || !schedule?.available || schedule?.bookings?.length !== 0 || schedule?.available;
    const allowsClickBasedOnHighlight = previewSchedule ? false : canTriggerClick(schedules, selectedDuration);
    const allowsClick = ['p100', 'p50'].includes(schedule?.payment_status) || !schedule?.available || schedule?.bookings?.length !== 0 || allowsClickBasedOnHighlight;

    const endTime = useMemo(() => {
        if (highlightClass) {
            return moment(schedule.start_time, "HH:mm").add(selectedDurationInt, 'minutes').format("HH:mm");
        } else if (!schedule.available || paymentStatusClass === "go-schedules-blocks-item--gomatch" ) {
            return schedule.end_time;
        } else {
            return moment(schedule.start_time, "HH:mm").add(30, 'minutes').format("HH:mm");
        }
    }, [schedule.start_time, paymentStatusClass, selectedDurationInt, highlightClass]);

    const scheduleUserName = () => {
        const booking = schedule.bookings.find(booking => booking.payment_percentage !== "p0") || schedule.bookings[0];
        if(inModal){
            return booking.name ? `${booking.name} ${booking.lastname}` : '-';
        }
        else if (!schedule || !schedule.bookings || schedule.bookings.length === 0) {
            return '-';
        } else {
        return booking?.user ? `${booking.user.name} ${booking.user.lastname}` : '-';
        }
    };

    const status = scheduleStatus(schedule);

    const total_slots = () => {
        if (inModal) {
            return schedule.total_slots;
        }
        return schedule.court.total_slots;
    }

    const payer = schedule.bookings.find(booking => booking.payment_percentage !== "p0") || schedule.bookings[0];

    return (
        <div
            key={schedule.id}
            id={schedule.id}
            className={scheduleClasses}
          
            {...(!previewSchedule && {
                onClick: () => {if (!scheduleClasses.includes('pdl-schedules-item--disabled')) {
                    if (allowsClickBasedOnClass || allowsClickBasedOnHighlight) {
                        handleOpenSettings(schedule);
                    }
                }
            },
                onMouseEnter: () => setHoveredItemId(schedule.id),
                onMouseLeave: () => setHoveredItemId(null)
            })}
        >
            <Row className='pdl-schedules-item-info'>
                <Col md={6} xs={6} className='text-start'>
                    <div className={status !== "disponible" ? "status" : "status--available" }>{status}</div>
                </Col>
                <Col md={6} xs={6} className='text-end'>
                    <div className='time'>
                        {schedule.start_time} {status !== 'disponible' || ['highlight-1', 'highlight-2', 'highlight-3'].includes(highlightClass) ? '- '+endTime : ''} 
                        <img src={`/assets/icons/clock-${!schedule.available || ['highlight-1', 'highlight-2', 'highlight-3'].includes(highlightClass) || status === 'gomatch' ? 'white' : 'green'}.svg`} alt='clock' />
                    </div>
                </Col>
                {!schedule.available && (
                    <Col md={6} xs={6} className='text-start mb-2'>
                        <div className='user'>
                            <img src='/assets/icons/profile-circle-white.svg' alt='profile' />
                            {scheduleUserName()}
                        </div>
                    </Col>
                )}
                {!schedule.available && (
                    <Col md={6} xs={6} className='text-end'>
                        <div className='players'>
                            {calculateTotalBookings(schedule.bookings)} / {total_slots()} Jugadores <img src='/assets/icons/people.svg' alt='player' />
                        </div>
                    </Col>
                )}
                {!schedule.available && (
                <Col md={8} xs={8} className='text-start'>
                    <div className='price'>
                        <img src={`/assets/icons/dollar-circle${!schedule.available || status === 'gomatch' || ['highlight-1', 'highlight-2', 'highlight-3'].includes(highlightClass) ? '-white' : ''}.svg`} alt='dollar' /> {schedule.price}
                    </div>
                </Col>
                )}
                {!schedule.available &&
                <Col md={4} xs={4} className='text-end'>
                    <div className='blocked'>
                        <img src='/assets/icons/lock-schedules.svg' alt='lock' />
                    </div>
                </Col>
                }
            </Row>
        </div>
    );
};

export default ScheduleItem;
