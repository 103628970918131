import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import SpinnerOverlay from '../../../components/GoMatch/Spinner/SpinnerOverlay';
import { handlePasswordReset } from '../../../utils/GoMatchApi'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';



const PasswordResetForm = () => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const code = searchParams.get('code'); // Get the code from URL
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
    if (password !== passwordConfirmation) {
      toast.error("Las contraseñas no coinciden"); // Consider using a more user-friendly notification method
      setIsLoading(false); // Stop loading
      return;
    }

    const result = await handlePasswordReset(code, password, passwordConfirmation);

    if (result.ok) {
      toast.success('Contraseña cambiada con éxito!'); 
      navigate('/admin_login'); // Redirect to /admin_login on success
    } else {
      toast.error('Error cambiando la contraseña. Por favor, inténtalo de nuevo.'); 
    }
    setIsLoading(false); // Stop loading
  };


  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <form onSubmit={handleSubmit} className="pdl-form">
      <Row className='justify-content-center'>
        <Col className='col-12 col-md-6'>
          <div className='pdl-info'>
            <Row>
              <Col className='col-1 text-center my-auto'>
                <FontAwesomeIcon icon={faCircleInfo} className='pdl-info__icon'/> 
              </Col>
              <Col className='col-11 my-auto'>
                <p>
                  Ingresa y confirma tu nueva contraseña
                </p>
              </Col>
            </Row>
          
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <div className="form-group">
            <input
              type="password"
              id="password"
              className="pdl-input"
              required
              placeholder='Nueva contraseña'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              id="passwordConfirmation"
              className="pdl-input"
              placeholder='Confirmar nueva contraseña'
              required
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          <Button type="submit" className="btn btn-success pdl-btn w-100" disabled={isLoading}>
            {isLoading ? 'Cambiando...' : 'Cambiar contraseña'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default PasswordResetForm;
