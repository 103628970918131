import { toast } from 'react-toastify';

export function register(config) {
  if ('serviceWorker' in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    navigator.serviceWorker.register(swUrl).then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // Inform the user
              toast.info('Recarga GoMatch para obtener la última versión ✨', {
                onClick: () => window.location.reload(), // Reload on click
                autoClose: false,
              });
            } else {
              // Precache complete
              console.log('Content is cached for offline use.');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    }).catch(error => {
      console.error('Error during service worker registration:', error);
    });
  }
}
