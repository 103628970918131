import React, { useState, useContext } from 'react';
import { Modal, Form, Button, Row, Col, Badge } from 'react-bootstrap';
import { toast } from "react-toastify";
import { openCashRegister, closeCashRegister } from '../../utils/GoMarketApi';
import Loader from '../Global/Loader/Loader';
import { CashRegisterContext } from '../../contexts/GoMarket/CashRegisterContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useEnterKeyPress from '../../hooks/useEnterKeyPress';

function CashRegisterLogic({ showOpenModal, setShowOpenModal }) {
  const { setIsRegisterOpen, cashRegisters, currentUserId, refreshCashRegisters } = useContext(CashRegisterContext);
  const [openCashAmount, setOpenCashAmount] = useState('');
  const [closeCashAmount, setCloseCashAmount] = useState('');
  const [modalAction, setModalAction] = useState('');
  const [selectedCashRegister, setSelectedCashRegisterState] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowOpenModal(false);
    setSelectedCashRegisterState(null);
    setLoading(false);
  };

  const handleOpenRegister = async () => {
    if (selectedCashRegister && openCashAmount) {
      setLoading(true);
      const response = await openCashRegister(selectedCashRegister.id, { cash_register_record_open_cash_user_amount: openCashAmount });
      if (response) {
        toast.success(response.message || 'Caja abierta con éxito.', { position: toast.POSITION.TOP_RIGHT });
        setIsRegisterOpen(true);
        await refreshCashRegisters(); // Reload the context
        setShowOpenModal(false);
        setSelectedCashRegisterState(null);
      }
      setLoading(false);
    }
  };

  const handleCloseRegister = async () => {
    if (selectedCashRegister && closeCashAmount) {
      setLoading(true);
      const response = await closeCashRegister(selectedCashRegister.id, { cash_register_record_close_cash_user_amount: closeCashAmount });
      if (response) {
        toast.success(response.message || 'Caja cerrada con éxito.', { position: toast.POSITION.TOP_RIGHT });
        setIsRegisterOpen(false);
        await refreshCashRegisters(); // Reload the context
        setShowOpenModal(false);
        setSelectedCashRegisterState(null);
      }
      setLoading(false);
    }
  };

  // Use the custom hook to handle Enter key press
  useEnterKeyPress(modalAction === 'open' ? handleOpenRegister : handleCloseRegister);

  return (
    <Modal show={showOpenModal} centered onHide={handleClose} className='go-modal go-modal--payment go-cash-register-modal'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader insideModal={true} />
        ) : !selectedCashRegister ? (
          <div>
            {cashRegisters.length > 0 ? (
              <div>
                <h4 className='title'>Seleccione una caja</h4>
                <Row>
                  {cashRegisters.map(cr => (
                   <Col
                   className={`col-md-12 col-12 px-1 py-1
                     ${
                       (cashRegisters.some(reg => reg.users_permissions_user?.id === currentUserId && reg.cash_register_open) && 
                       !(cr.users_permissions_user?.id === currentUserId && cr.cash_register_open)) ||
                       (cr.cash_register_open && cr.users_permissions_user?.id !== currentUserId && cashRegisters.some(reg => reg.users_permissions_user?.id === currentUserId && reg.cash_register_open))
                         ? "go-disabled" 
                         : ""
                     }`} 
                   key={cr.id}
                 > 
                      <Button 
                        variant="secondary" 
                        className="go-cash go-cash--details w-100" 
                        onClick={() => {
                          setSelectedCashRegisterState(cr);
                          setModalAction(cr.cash_register_open ? 'close' : 'open');
                        }}>
                        <Row className='justify-content-center'>
                          <Col className='col-md-3 col-4 my-auto text-center'>
                            <div className='image-div'>
                              <LazyLoadImage effect='blur' src="/assets/icons/menu/strongbox.svg" alt="products" />
                            </div>
                          </Col>
                          <Col className='col-md-8 col-8 my-auto'>
                            <h4 className='pt-3'>
                              {cr.cash_register_name} 
                              {cr.cash_register_open ? (
                                <span className='go-color--green'>  <Badge className='go-badge go-badge--open'> Abierta </Badge> </span>
                              ) : (
                                <span className='go-color--black'>  <Badge className='go-badge go-badge--closed'> Cerrada </Badge>  </span>
                              )}
                            </h4>
                            <p className='username'><LazyLoadImage effect='blur' src="/assets/icons/profile-circle.svg" className='user' alt="user" />{cr.users_permissions_user?.name} {cr.users_permissions_user?.lastname}</p>
                            <p className='datetime'><LazyLoadImage effect='blur'  src="/assets/icons/clock.svg" className='user me-2' alt="datetime" /> 
                              {cr.cash_register_last_action_datetime.date} - {cr.cash_register_last_action_datetime.timeWithoutSeconds} Hrs
                            </p>
                          </Col>
                        </Row>
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div>
                <h4 className="go-text pb-2">No hay cajas disponibles :O</h4>
                <p>Solicita una contactándonos a: <a href='mailto:gomatch@gomatch.app' className='go-link'>gomatch@gomatch.app</a></p>
              </div>
            )}
          </div>
        ) : (
          <Form className='go-form pb-0 go-cash-register-modal-action'>
            <Form.Group>
              <h4 className='title'>Cantidad en efectivo en caja</h4>
              <p className='pb-0 pt-0'>{modalAction === 'open' ? "Ingrese la cantidad de efectivo con la que se abrirá la caja." : "Ingrese la cantidad de efectivo con la que se cerrará en caja."}</p>
              <Form.Control
                type="number"
                value={modalAction === 'open' ? openCashAmount : closeCashAmount}
                onChange={e => modalAction === 'open' ? setOpenCashAmount(e.target.value) : setCloseCashAmount(e.target.value)}
                placeholder='Ej. 45000'
                autoFocus={true}
                required
              />
            </Form.Group>
            <Row className=''>
              <Col md={4} xs={12} className='ms-auto pb-3 pb-md-0'>
                <Button variant="primary" className='go-btn go-btn--close' onClick={handleClose}>Cancelar</Button>
              </Col>
              <Col md={8} xs={12}>
                <Button variant="primary" className='go-btn go-btn--add' onClick={modalAction === 'open' ? handleOpenRegister : handleCloseRegister}>{modalAction === 'open' ? "Confirmar Apertura" : "Confirmar Cierre"}</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CashRegisterLogic;
