// src/components/GoMarket/Item.js
import React from 'react';
import { Badge, Card, Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeItem = ({ item, onAddToCart }) => {
  return (
    <Col className="col-md-4 col-6 pb-3">
      <div className='gomarket-card'>
        <Card onClick={() => onAddToCart(item)}>
          <div className='gomarket-card-image'>
            <LazyLoadImage
              alt={item.item_name}
              effect="blur"
              src={item.item_image}
              className="card-img-top gomarket-card__image"
            />
            <Badge className='gomarket-card__badge gomarket-card__badge--price'>${item.item_sell_price}</Badge>
          </div>
          <Card.Body>
            {/* <Badge className='gomarket-card__badge'>{item.item_category}</Badge> */}
            <Card.Text className='mb-0 brand'>{item.item_brand}</Card.Text>
            <Card.Text className='mb-0'>{item.item_name}</Card.Text>

          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default HomeItem;
