import LoginScreen from "../../screens/GoMatch/LoginScreen";
import HomeScreen from "../../screens/GoMatch/HomeScreen";
import FeedScreen from "../../screens/GoMatch/FeedScreen";
import CourtScreen from "../../screens/GoMatch/CourtScreen";
import RegisterScreen from "../../screens/GoMatch/RegisterScreen";
import BuildingMatchScreen from "../../screens/GoMatch/BuildingMatchScreen";
import ProfileScreen from "../../screens/GoMatch/ProfileScreen";
import EditProfileScreen from '../../screens/GoMatch/EditProfileScreen';
import BookingsScreen from "../../screens/GoMatch/BookingScreen";
import PaymentScreen from "../../screens/GoMatch/PaymentScreen";
import AdminLoginScreen from "../../screens/Admin/AdminLoginScreen";
import SharedProfileScreen from "../../screens/GoMatch/SharedProfileScreen";
import RankScreen from "../../screens/GoMatch/RankScreen";



// Add all the paths and components that are private (need authentication) to this array
export const privateRoutes = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/register",
    element: <RegisterScreen />,
  },
  {
    path: "/match/:slug",
    element: <BuildingMatchScreen />,
  },
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/user/:username", // :username is a placeholder for the actual user username
    element: <SharedProfileScreen />,
  },
  {
    path: "/edit-profile/:username", // :username is a placeholder for the actual user username
    element: <EditProfileScreen />,
  },
  {
    path: "/feed",
    element: <FeedScreen />,
  },
  {
    path: "/court",
    element: <CourtScreen />,
  },
  {
    path: "/reservas",
    element: <BookingsScreen />,
  },
  {
    path: "/rank",
    element: <RankScreen />,
  },
  {
    path: "/payment/:slug",
    element: <PaymentScreen />,
  },
  {
    path: "/admin_login",
    element: <AdminLoginScreen />,
  },
];
