import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AdminDashboardScreen() {
  const navigate = useNavigate();

  const buttons = [
    { path: "/admin/gocalendar", imgSrc: "/assets/illustrations/calendar.png", title: "GoCalendar", description: "Agendamiento de horas al alcance de todos con GoCalendar", mdClass: "12", xsClass: "12", colImg: "2", colInfo: "9" },
    { path: "/admin/gomarket", imgSrc: "/assets/illustrations/store.png", title: "GoMarket", description: "Vende tus productos con GoMarket, Point of Sale (POS)", mdClass: "6", xsClass: "12", colImg: "4", colInfo: "7" }, 
    { path: "/admin/lista_usuarios", imgSrc: "/assets/illustrations/people.png", title: "Lista usuarios", description: "Agrega, edita y elimina usuarios", mdClass: "6", xsClass: "12", colImg: "4", colInfo: "7" }, 
    { path: "/admin/ventas", imgSrc: "/assets/illustrations/money.png", title: "Ventas", description: "Revisa tus ordenes pagadas", mdClass: "6", xsClass: "12", colImg: "4", colInfo: "7" }, 
    { path: "/admin/pendientes", imgSrc: "/assets/illustrations/chart.png", title: "Pendientes", description: "Revisa tus ordenes pendientes", mdClass: "6", xsClass: "12", colImg: "4", colInfo: "7" }, 
    // { path: "/admin/pagos", imgSrc: "/assets/illustrations/money-bag.png", title: "Pagos", description: "Revisa los datos de pagos abonados, pendientes y confirmados.", mdClass: "6", xsClass: "12", colImg: "4", colInfo: "7" },
    // { path: "/admin/estadisticas", imgSrc: "/assets/illustrations/stadistics.png", title: "Estadísticas", description: "Consulta las estadísticas de tu club", mdClass: "6", colImg: "4", xsClass: "12", colInfo: "7" },
    { path: "/admin/configuracion", imgSrc: "/assets/illustrations/configuration.png", title: "Configuración", description: "Configura los datos más importantes de tu recinto ", mdClass: "6", xsClass: "12", colImg: "4", colInfo: "7" },
    
  ];

  return (
    <div className="container go-container">
      <div className='go-page-title pb-4'>
        <h4 className='go-text'><img src='/assets/icons/menu/home-active.svg' className='go-filter--black' /> Dashboard</h4>
      </div>

      <Row>
        {buttons.map((button, index) => (
          <Col key={index} md={`${button.mdClass} pdl-dashboard-col--small mb-3`} xs={button.xsClass}>
            <Button className="go-btn go-btn--dashboard w-100" onClick={() => navigate(button.path)}>
              <Row>
                <Col md={button.colImg} className='text-center my-auto'>
                  <div className='img-div'>
                  <LazyLoadImage  
                      effect="blur"src={button.imgSrc} alt={button.title} />
                  </div>
                </Col>
                <Col md={button.colInfo} className='text-start my-auto'>
                  <h4>{button.title}</h4>
                  <p>{button.description}</p>
                </Col>
                <Col md={1}  className="text-center my-auto d-none d-md-block">
                  <img src="/assets/icons/arrow.svg" alt="arrow" />
                </Col>
              </Row>
            </Button>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default AdminDashboardScreen;
