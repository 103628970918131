import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleRegister } from '../../../utils/GoMatchApi'; // Adjust path as necessary
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RegisterForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function RegisterForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validate = async () => {
    let tempErrors = {};
    let isValid = true;

    try {
      const result = await handleRegister(name, lastname, email, password);
      if (result.ok) {
          navigate('/login', { state: { registeredEmail: email } });
      } else {
          tempErrors.email = "Correo ya existe";
          toast.error("Correo o Usuario ya existen");

      }
    } catch (error) {
        console.error('Registration error:', error);
        toast.error("Ocurrio un error en el registro");
    }

    if (password.length < 8) {
      tempErrors.password = "Contraseña debe tener al menos 8 caracteres";
      toast.error("Contraseña debe tener al menos 8 caracteres");
      isValid = false;
    }
    
    setErrors(tempErrors);
    return isValid;
  };
  
  return (
    <div className='pdl-section'>

       <input
        className={`pdl-input form-control ${errors.name ? 'is-invalid' : ''}`}
        type="text"
        placeholder="Nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        className={`pdl-input form-control ${errors.lastname ? 'is-invalid' : ''}`}
        type="text"
        placeholder="Apellido"
        value={lastname}
        onChange={(e) => setLastname(e.target.value)}
      />
      <input
        className={`pdl-input form-control ${errors.email ? 'is-invalid' : ''}`}
        type="email"
        placeholder="Correo"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
     <div className="password-input-container">
        <input
          className={`pdl-input form-control ${errors.password ? 'is-invalid' : ''}`}
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
        </span>
      </div>
      <button className="btn btn-success mt-3 w-100 text-center" onClick={validate}>Registrarse</button>
    </div>
  );
}

export default RegisterForm;
