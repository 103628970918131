import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { handleAdminLogin } from "../../../utils/GoMatchApi"; // Adjust path as necessary
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import "./LoginForm.scss";

function LoginForm({ registeredEmail }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // New state for toggling password visibility
  const navigate = useNavigate();

  useEffect(() => {
    if (registeredEmail) {
      setUsername(registeredEmail);
    }
  }, [registeredEmail]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const login = async () => {
    const result = await handleAdminLogin(username, password);
    if (result.ok) {
      navigate("/admin_dashboard");
      window.location.reload();
      return;
    } else {
      toast.error(`Correo y/o contraseña incorrectos`);
    }
  };

  return (
    <div className="go-login-form">
      
      <div className=' my-3'>

      <Form.Group controlId="formBasicEmail">
        <Form.Label>Ingrese su correo</Form.Label>
        <Form.Control type="email" placeholder="Correo" value={username} onChange={(e) => setUsername(e.target.value)} />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Ingrese su contraseña  </Form.Label>
        <Form.Control type={showPassword ? "text" : "password"} placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} className="password"/>
        {/* <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
        </span> */}
      </Form.Group>

    
      <button className="go-btn go-btn--add mt-3 w-100 text-center" onClick={login}>
        Iniciar sesión
      </button>

      <div className="text-center mt-3 go-text">
       ¿Olvidaste tu contraseña?<Link to="/password_reset" className="go-link"> Recuperar contraseña</Link>
      </div>


      </div>

  
    </div>
  );
}

export default LoginForm;
