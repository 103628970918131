import { resizeImage } from './Functions';

const getToken = () => localStorage.getItem('userToken');
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:1337';
const defaultImage = '/assets/illustrations/item.png';

// Items - Inventory

export const fetchItems = async (page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/get_items?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Items');
    }

    const data = await response.json();
    return {
      items: data.items.map(item => ({
        id: item.id,
        item_name: item.item_name,
        item_brand: item.item_brand,
        item_description: item.item_description,
        item_buy_price: item.item_buy_price,
        item_sell_price: item.item_sell_price,
        item_barcode: item.item_barcode,
        item_favorite: item.item_favorite,
        item_bulk: item.item_bulk,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        publishedAt: item.publishedAt,
        item_image: item.item_image?.[0]?.formats.thumbnail.url || '/assets/illustrations/item.png',
        stock: item.item_stock?.item_stock_stock || 0,
        critical_stock: item.item_stock?.item_stock_critical_stock || 0,
        item_category_name: item?.item_category?.item_category_name || 'Sin categoria',
      })),
      pagination: data.pagination,
    };
  } catch (error) {
    console.error('Error fetching items:', error);
    return { items: [], pagination: {} };
  }
};

export const getItemById = async (itemId) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/find_item/${itemId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch item');
    }

    const item = await response.json();

    return {
      id: item.id,
      item_name: item.item_name,
      item_brand: item.item_brand,
      item_description: item.item_description,
      item_buy_price: item.item_buy_price,
      item_sell_price: item.item_sell_price,
      item_barcode: item.item_barcode,
      item_favorite: item.item_favorite,
      item_bulk: item.item_bulk,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      publishedAt: item.publishedAt,
      item_image: item.item_image?.[0]?.formats.thumbnail.url || defaultImage, // Use a default image if none provided
      item_image_id: item.item_image?.[0]?.id || null, // Use a default image if none provided
      stock: item.item_stock.item_stock_stock || 0, // Assuming stock might be part of the response, default to 0 if not present
      critical_stock: item.item_stock.item_stock_critical_stock || 0,
      item_category_name: item?.item_category?.item_category_name || 'Sin categoria',
      item_category_id: item?.item_category?.id || null,
    };
  } catch (error) {
    console.error('Error fetching Item:', error);
    return null; // Return null in case of error
  }
};

export const updateItem = async (id, item) => {
  try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/api/admin/item/update_item/${id}`, {
          method: 'PUT',
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: item }), // Wrap the item in a data object
      });

      if (!response.ok) {
          throw new Error('Failed to update Item');
      }

      return await response.json();
  } catch (error) {
      console.error('Error updating Item:', error);
      return null; // Return null in case of error
  }
};

export const createItem = async (item) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/create_item`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: item }), // Wrap the item in a data object
    });

    if (!response.ok) {
      throw new Error('Failed to create Item');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating Item:', error);
    return null; // Return null in case of error
  }
};

export const deleteItem = async (itemId) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/delete_item/${itemId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete Item');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting Item:', error);
    return null; // Return null in case of error
  }
};

export const createItemByProduct = async (productId, productData) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/create_item_by_product/${productId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: productData }), // Wrap the product in a data object
    });

    if (!response.ok) {
      throw new Error('Failed to create Item by Product');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating Item by Product:', error);
    return null; // Return null in case of error
  }
};

export const searchItemsByString = async (stringToSearch, page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/search_items_by_string/${stringToSearch}?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to search Items');
    }

    const data = await response.json();
    return {
      items: data.items.map(item => ({
        id: item.id,
        item_name: item.item_name,
        item_brand: item.item_brand,
        item_description: item.item_description,
        item_buy_price: item.item_buy_price,
        item_sell_price: item.item_sell_price,
        item_barcode: item.item_barcode,
        item_favorite: item.item_favorite,
        item_bulk: item.item_bulk,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        publishedAt: item.publishedAt,
        item_image: item.item_image?.[0]?.formats.thumbnail.url || defaultImage,
        stock: item.item_stock?.item_stock_stock || 0,
        critical_stock: item.item_stock?.item_stock_critical_stock || 0,
        item_category: item?.item_category?.item_category_name || 'Sin categoria',
      })),
      pagination: data.pagination,
    };

  } catch (error) {
    console.error('Error searching items:', error);
    return { items: [], pagination: {} };
  }
};

export const searchItemsByCategoryId = async (categoryId, page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/search_items_by_category/${categoryId}?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to search Items');
    }

    const data = await response.json();
    return {
      items: data.items.map(item => ({
        id: item.id,
        item_name: item.item_name,
        item_brand: item.item_brand,
        item_description: item.item_description,
        item_buy_price: item.item_buy_price,
        item_sell_price: item.item_sell_price,
        item_barcode: item.item_barcode,
        item_favorite: item.item_favorite,
        item_bulk: item.item_bulk,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        publishedAt: item.publishedAt,
        item_image: item.item_image?.[0]?.formats.thumbnail.url || defaultImage,
        stock: item.item_stock?.item_stock_stock || 0,
        critical_stock: item.item_stock?.item_stock_critical_stock || 0,
        item_category: item?.item_category?.item_category_name || 'Sin categoria',
      })),
      pagination: data.pagination,
    };

  } catch (error) {
    console.error('Error searching items:', error);
    return { items: [], pagination: {} };
  }
};

export const searchItemsByStringAndOrCategoryId = async (stringToSearch, categoryId, page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item/search_items/${stringToSearch}/${categoryId}?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to search Items');
    }

    const data = await response.json();
    return {
      items: data.items.map(item => ({
        id: item.id,
        item_name: item.item_name,
        item_brand: item.item_brand,
        item_description: item.item_description,
        item_buy_price: item.item_buy_price,
        item_sell_price: item.item_sell_price,
        item_barcode: item.item_barcode,
        item_favorite: item.item_favorite,
        item_bulk: item.item_bulk,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        publishedAt: item.publishedAt,
        item_image: item.item_image?.[0]?.formats.thumbnail.url|| defaultImage,
        stock: item.item_stock?.item_stock_stock || 0,
        critical_stock: item.item_stock?.item_stock_critical_stock || 0,
        item_category: item?.item_category?.item_category_name || 'Sin categoria',
      })),
      pagination: data.pagination,
    };

  } catch (error) {
    console.error('Error searching items:', error);
    return { items: [], pagination: {} };
  }
};


// Items - Categoies

export const getItemsCategories = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/item_category/get_categories`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Categories');
    }

    const categories = await response.json();
    return categories.map(category => ({
      id: category.id,
      item_category_name: category.item_category_name,
      item_category_description: category.item_category_description,
      item_category_image: category.item_category_image || defaultImage, // Use a default image if none provided
    }));
  }
  catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
}


// Products

export const fetchProducts = async (page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/product/get_products?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Products');
    }

    const data = await response.json();
    const { products, pagination } = data;

    return {
      products: products.map(product => ({
        id: product.id,
        product_name: product.product_name,
        product_brand: product.product_brand,
        product_description: product.product_description,
        product_barcode: product.product_barcode,
        product_category: product.product_category?.product_category_name || 'Sin categoria',
        product_image: product.product_image?.formats.thumbnail.url || product.product_image?.url || defaultImage,
        product_already_added: product.product_already_added,
      })),
      pagination,
    };
  } catch (error) {
    console.error('Error fetching products:', error);
    return { products: [], pagination: { page, limit, totalProducts: 0, totalPages: 0 } };
  }
};

export const getProductsCategories = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/product_category/get_product_categories`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Categories');
    }

    const categories = await response.json();
    return categories.map(category => ({
      id: category.id,
      product_category_name: category.item_category_name,
      product_category_description: category.item_category_description,
    }));
  }
  catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
}

export const searchProductsByString = async (stringToSearch, page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/product/search_products/${stringToSearch}?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to search Products');
    }

    const data = await response.json();
    const { products, pagination } = data;

    return {
      products: products.map(product => ({
        id: product.id,
        product_name: product.product_name,
        product_brand: product.product_brand,
        product_description: product.product_description,
        product_barcode: product.product_barcode,
        product_category: product.product_category?.product_category_name || 'Sin categoria',
        product_image: product.product_image?.formats.thumbnail.url || product.product_image?.url || defaultImage,
        product_already_added: product.item_already_created,
      })),
      pagination,
    };
  } catch (error) {
    console.error('Error searching products:', error);
    return { products: [], pagination: { page, limit, totalProducts: 0, totalPages: 0 } };
  }
};


// Orders

export const fetchOrders = async (date_sort = 'desc', limit = 3, page = 1, start_date, end_date, status = '') => {
  try {
    const token = getToken();
    const url = new URL(`${BASE_URL}/api/admin/order/get_orders`);
    url.searchParams.append('date_sort', date_sort);
    url.searchParams.append('limit', limit);
    url.searchParams.append('page', page);
    if (start_date) url.searchParams.append('start_date', start_date);
    if (end_date) url.searchParams.append('end_date', end_date);
    if (status) url.searchParams.append('status', status);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Orders');
    }

    const { orders, pagination, sorting, filters } = await response.json();
    return {
      orders: orders.map(order => ({
        id: order.id,
        order_amount: order.order_amount,
        order_currency: order.order_currency,
        order_source: order.order_source,
        order_status: order.order_status,
        order_date: order.order_date.date,
        order_date_time: order.order_date.timeWithoutSeconds,
        item_order_quantities: order?.order_details?.items?.length || "",
        booking_order_quantities: order?.order_details?.bookings?.length || 0,
        bookings: order?.order_details?.bookings || [],
        club: order.club ? { id: order.club.id, name: order.club.name } : null,
        buyer_name: order?.order_send_mail_client?.name,
        buyer_lastname: order?.order_send_mail_client?.lastname,
      })),
      pagination,
      sorting,
      filters,
    };
  } catch (error) {
    console.error('Error fetching orders:', error);
    return {
      orders: [],
      pagination: {},
      sorting: {},
      filters: {},
    };
  }
};

export const getOrderById = async (orderId) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/order/find_order/${orderId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Order');
    }

    const order = await response.json();

    return {
      id: order.id,
      order_amount: order.order_amount,
      order_currency: order.order_currency,
      order_source: order.order_source,
      order_status: order.order_status,
      order_date: order.order_date,
      order_cash_payed: order?.order_cash_payed,
      order_cash_change: order?.order_change,
      order_cash_register: order.order_details?.cash_register?.cash_register_name || '-',
      items: Array.isArray(order.order_details?.items) ? order.order_details.items.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name,
        item_brand: item.item_brand,
        item_quantity: item.item_quantity,
        item_sell_price: item.item_sell_price,
        item_image: item?.item_image_thumbnail || item.item_image || defaultImage,
      })) : [],
      bookings: Array.isArray(order.order_details?.bookings) ? order.order_details.bookings.map(booking => ({
        id: booking.id,
        date: booking.date,
        price: booking.price,
        status: booking.status,
        start_time: booking.start_time,
        end_time: booking.end_time,
        duration_int: booking.duration_int,
        duration_text: booking.duration_text,
        court: booking.court_name,
        schedule_id: booking.schedule_id,
        payment_percentage: booking.payment_percentage,
      })) : [],
      club: order.club ? { id: order.club.id, name: order.club.name } : null,
      buyer_name: order?.order_send_mail_client?.name,
      buyer_lastname: order?.order_send_mail_client?.lastname,
    };
  } catch (error) {
    console.error('Error fetching Order:', error);
    return null; // Return null in case of error
  }
};

export const removePendingBookingOrItemFromOrder = async (bookingsOrItemsToRemove) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/order/remove_pending_booking_or_item_from_order`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingsOrItemsToRemove), // Wrap the bookings or items in a data object
    });

    if (!response.ok) {
      throw new Error(`Failed to remove from Order`);
    }

    return await response.json();
  } catch (error) {
    console.error(`Error removing from Order:`, error);
    return null; // Return null in case of error
  }
};

export const createOrder = async (order) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/order/create_order`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(order), // Wrap the order in a data object
 
    });

    if (!response.ok) {
      throw new Error('Failed to create Order');
    }

    return await response.json();
  }
  catch (error) {
    console.error('Error creating Order:', error);
    return null; // Return null in case of error
  }
}

export const updateOrder = async (orderId, updatedOrder) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/order/update_order/${orderId}`, {
      method: 'PUT', // Use 'PUT' for updating the order
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedOrder), // Send the updated order data in the body
    });

    if (!response.ok) {
      throw new Error('Failed to update Order');
    }

    return await response.json();
  }
  catch (error) {
    console.error('Error updating Order:', error);
    return null; // Return null in case of error
  }
};

export const updatePendingOrderSource = async (orderId, updatedOrder) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/order/update_pending_order_source/${orderId}`, {
      method: 'PUT', // Use 'PUT' for updating the order
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedOrder), // Send the updated order data in the body
    });

    if (!response.ok) {
      throw new Error('Failed to update Order');
    }

    return await response.json();
  }
  catch (error) {
    console.error('Error updating Order:', error);
    return null; // Return null in case of error
  }
};


export const cancelOrderByOrderId = async (orderId) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/order/cancel_order/${orderId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to cancel Order');
    }

    return response;
  }
  catch (error) {
    console.error('Error canceling Order:', error);
    return null; // Return null in case of error
  }
};




// CashRegister

export const getClubCashRegisters = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/cash_register/get_club_cash_registers`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Cash Registers');
    }

    const cashRegisters = await response.json();
    return cashRegisters.map(cashRegister => ({
      id: cashRegister?.id || null,
      cash_register_name: cashRegister?.cash_register_name || null,
      cash_register_open: cashRegister?.cash_register_open || false,
      cash_register_last_action_datetime: cashRegister?.cash_register_last_action_datetime || null,
      user_id: cashRegister?.users_permissions_user?.id || null,
      name: cashRegister?.users_permissions_user?.name || null,
      lastname: cashRegister?.users_permissions_user?.lastname || null,
    }));
  }

  catch (error) {
    console.error('Error fetching cash registers:', error);
    return [];
  }
}

export const getClubCashRegistersRecords = async (page = 1, limit = 10) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/cash_register_record/get_cash_register_records?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch Cash Registers Records: ${response.statusText}`);
    }

    const data = await response.json();
    const { cash_register_records, pagination } = data;

    return {
      records: cash_register_records.map(cashRegisterRecord => {
        const userData = cashRegisterRecord || {};
        return {
          id: cashRegisterRecord.id,
          cash_register_record_open_amount: cashRegisterRecord.cash_register_record_open_cash_user_amount,
          cash_register_record_close_amount: cashRegisterRecord.cash_register_record_close_cash_user_amount,
          cash_register_record_datetime: cashRegisterRecord.cash_register_record_datetime,
          cash_register_record_open: cashRegisterRecord.cash_register_record_open,
          cash_register_record_date: cashRegisterRecord.cash_register_record_datetime.date,
          cash_register_record_time: cashRegisterRecord.cash_register_record_datetime.time,
          cash_register_record_time_whitout_seconds: cashRegisterRecord.cash_register_record_datetime.timeWithoutSeconds,
          cash_register_record_user: userData.cash_register_record_user_data ? `${userData.cash_register_record_user_data.user_name} ${userData.cash_register_record_user_data.user_lastname}` : '-',
          cash_register_record_cash_register: cashRegisterRecord.cash_register?.cash_register_name || '-  ',

          cash_register_record_close_debit_balance: cashRegisterRecord.cash_register_record_close_debit_balance,
          cash_register_record_close_transfer_balance: cashRegisterRecord.cash_register_record_close_transfer_balance,
          cash_register_record_close_credit_balance: cashRegisterRecord.cash_register_record_close_credit_balance,
          cash_register_record_close_cash_balance: cashRegisterRecord.cash_register_record_close_cash_balance,
          cash_register_record_close_cash_backend_balance: cashRegisterRecord.cash_register_record_close_cash_backend_balance,
          cash_register_record_close_reservation_balance: cashRegisterRecord.cash_register_record_close_reservation_balance,
          cash_register_record_close_total_balance: cashRegisterRecord.cash_register_record_close_total_balance,
          cash_register_record_close_cash_balance_diff: cashRegisterRecord?.cash_register_record_close_cash_balance_diff || 0,
          
        };
      }),
      pagination,
    };
  } catch (error) {
    console.error('Error fetching cash registers records:', error);
    return { records: [], pagination: { page, limit, totalRecords: 0, totalPages: 0 } };
  }
};

export const findCashRegisterRecordsFilterByUserId = async (userId, limit = 10, page = 1) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/cash_register_record/find_cash_register_records_by_user_id/${userId}?limit=${limit}&page=${page}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Cash Registers');
    }

    const data = await response.json();
    const { cash_register_records, pagination } = data;

    return {
      records: cash_register_records.map(record => ({
        id: record.id,
        cash_register_record_open_amount: record.cash_register_record_open_cash_user_amount,
        cash_register_record_close_amount: record.cash_register_record_close_cash_user_amount,
        cash_register_record_datetime: record.cash_register_record_datetime,
        cash_register_record_open: record.cash_register_record_open,
        cash_register_record_date: record.cash_register_record_datetime.date,
        cash_register_record_time: record.cash_register_record_datetime.time,
        cash_register_record_user: record.cash_register_record_user_data 
          ? `${record.cash_register_record_user_data.user_name} ${record.cash_register_record_user_data.user_lastname}` 
          : '-',
        cash_register_record_cash_register: record.cash_register?.cash_register_name || '-',
        cash_register_record_close_debit_balance: record.cash_register_record_close_debit_balance,
        cash_register_record_close_transfer_balance: record.cash_register_record_close_transfer_balance,
        cash_register_record_close_credit_balance: record.cash_register_record_close_credit_balance,
        cash_register_record_close_cash_balance: record.cash_register_record_close_cash_balance,
        cash_register_record_close_cash_backend_balance: record.cash_register_record_close_cash_backend_balance,
        cash_register_record_close_total_balance: record.cash_register_record_close_total_balance,
        cash_register_record_close_cash_balance_diff: record?.cash_register_record_close_cash_balance_diff || 0,
      })),
      pagination,
    };
  } catch (error) {
    console.error('Error fetching cash registers:', error);
    return { records: [], pagination: { page, limit, totalRecords: 0, totalPages: 0 } };
  }
};

export const openCashRegister = async (cashRegisterId, cashRegisterData) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/cash_register/open_cash_register/${cashRegisterId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cashRegisterData), 
    });

    if (!response.ok) {
      throw new Error('Failed to open Cash Register');
    }

    return await response.json();
  }

  catch (error) {
    console.error('Error opening cash register:', error);
    return null;
  }

}

export const closeCashRegister = async (cashRegisterId, cashRegisterData) => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/cash_register/close_cash_register/${cashRegisterId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cashRegisterData),
    });

    if (!response.ok) {
      throw new Error('Failed to close Cash Register');
    }

    return await response.json();

  }

  catch (error) {
    console.error('Error closing cash register:', error);
    return null;
  }

}

// Upload file

export const uploadFile = async (originalFile, folder) => {
  try {
      const token = localStorage.getItem('userToken');
      // First, await the resizeImage operation to complete if applicable
      const resizedBlob = await resizeImage(originalFile, 1024, 1024);

      // Prepare the formData with the resized image
      const formData = new FormData();
      formData.append('files', resizedBlob, originalFile.name);
      formData.append('path', folder);

      // Then proceed with the upload
      const response = await fetch(`${BASE_URL}/api/upload`, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: formData,
      });

      if (!response.ok) {
          throw new Error('File upload failed');
      }

      return await response.json();
  } catch (error) {
      console.error('Error uploading file:', error);
      throw error; // Ensure this error is caught and handled in the component
  }
};

// Club

export const getClubAdminUsernames = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/api/admin/club/get_club_admin_usernames`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Club Admin Usernames');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching Club Admin Usernames:', error);
    return [];
  }
}







