import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function TermsAndConditionsScreen() {
  return (
    <>
   

      <section className='go-section go-section--color'>
        <Container>
          <Row className='justify-content-center py-5'>
            <Col xs={12} md={8}>
              <h2 className="mb-3">Términos y Condiciones de GoMatch</h2>
              <p>Bienvenido a GoMatch. Al acceder y usar nuestra plataforma, usted acepta estar sujeto a los siguientes términos y condiciones. Lea cuidadosamente cada sección antes de utilizar nuestros servicios.</p>
              <h3>1. Aceptación de los Términos</h3>
              <p>Al registrarse, acceder o utilizar los servicios de GoMatch, usted confirma su aceptación de estos términos y condiciones y se compromete a cumplirlos. Si no está de acuerdo con algún término, no debe usar o acceder a este servicio.</p>
              <h3>2. Servicios Ofrecidos</h3>
              <p>GoMatch proporciona una plataforma integral para la gestión de espacios deportivos, que incluye:</p>
              <ul>
                <li>Reservas: Sistema de reserva de canchas.</li>
                <li>Inventario: Gestión automatizada de inventarios.</li>
                <li>Torneos: Organización de torneos y competiciones.</li>
                <li>Herramientas de Redes Sociales (RRSS): Funcionalidades para compartir información relevante en redes sociales.</li>
                <li>Automatización: Automatización de procesos de gestión.</li>
                <li>Estadísticas: Acceso a estadísticas relevantes de canchas y clientes.</li>
              </ul>
              <h3>3. Registro y Cuenta</h3>
              <p>Para acceder a ciertos servicios, es necesario crear una cuenta. Usted deberá proporcionar información verídica y completa y mantenerla actualizada. Es responsable de mantener la confidencialidad de su contraseña y de toda actividad que ocurra bajo su cuenta.</p>
              <h3>4. Condiciones de Uso</h3>
              <p>El uso de GoMatch está sujeto a lo siguiente:</p>
              <ul>
                <li>No utilizar el servicio para fines ilícitos.</li>
                <li>No acceder a áreas no autorizadas de la plataforma.</li>
                <li>No interferir en el funcionamiento de la red o en el uso del servicio por otros usuarios.</li>
              </ul>
              <h3>5. Derechos de Propiedad Intelectual</h3>
              <p>Todos los derechos de propiedad intelectual relacionados con GoMatch y su contenido son de nuestra propiedad o están licenciados por nosotros. Estos derechos están protegidos por leyes de propiedad intelectual y tratados internacionales.</p>
              <h3>6. Modificaciones del Servicio y Términos</h3>
              <p>GoMatch se reserva el derecho de modificar o discontinuar el servicio sin previo aviso. También podemos modificar estos términos y condiciones en cualquier momento, y dichas modificaciones entrarán en vigor inmediatamente después de su publicación en el sitio.</p>
              <h3>7. Terminación</h3>
              <p>Podemos terminar o suspender su acceso a nuestro servicio inmediatamente, sin previo aviso ni responsabilidad, por cualquier motivo, incluido el incumplimiento de los Términos.</p>
              <h3>8. Indemnización</h3>
              <p>Usted acuerda indemnizar y eximir a GoMatch, sus filiales, y sus empleados de cualquier reclamación, demanda, pérdida, daño o gasto incurrido por su uso del servicio o por violar estos Términos.</p>
              <h3>9. Limitación de Responsabilidad</h3>
              <p>GoMatch no será responsable por cualquier daño indirecto, incidental, especial o consecuente resultante del uso o la incapacidad de usar nuestros servicios.</p>
              <h3>10. Legislación Aplicable</h3>
              <p>Estos términos se regirán e interpretarán de acuerdo con las leyes del país de origen de GoMatch, sin dar efecto a cualquier principio de conflictos de ley.</p>
              <h3>11. Contacto</h3>
              <p>Si tiene alguna pregunta sobre estos Términos y Condiciones, por favor contáctenos a través de <a href="mailto:gomatchinfo@gmail.com">gomatchinfo@gmail.com</a> o al <a href="tel:+56982895821">+569 82895821</a>.</p>
              <p>Estos términos y condiciones son efectivos a partir de su última actualización el 18 de abril de 2024.</p>
              <p>© 2024 GoMatch. Todos los derechos reservados.</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id='footer' className='go-section go-section--footer'>
        <Container className='pt-5'>
          <Row>
            <Col md={4} className='text-center'>
              <h4><strong>GoMatch</strong></h4>
              <p>© 2024</p>
            </Col>
            <Col md={4} className='text-center'>
              <h4><strong>Información</strong></h4>
              <a href='/terminos-y-condiciones'>Términos y condiciones</a><br/>
            </Col>
            <Col md={4} className='text-center'>
              <h4><strong>Contacto</strong></h4>
              <p className='mb-0'>+569 82895821</p>
              <p>gomatchinfo@gmail.com</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default TermsAndConditionsScreen;
