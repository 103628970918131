import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ScheduleItem from './ScheduleItem';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useMediaQuery from '../../../hooks/useMediaQuery';
import './ScheduleList.scss';

const ScheduleList = ({ schedulesByCourt, setHoveredItemId, handleOpenModal, selectedDuration, hoveredItemId, handleOpenSettings }) => {
    const isMobile = useMediaQuery('(max-width: 768px)');

    return (
        <div className="pdl-schedules-container">
            {isMobile ? (
                <Tabs defaultActiveKey={Object.keys(schedulesByCourt)[0]} id="schedule-tabs" className="go-schedule-tabs-court" fill>
                    {Object.entries(schedulesByCourt).map(([courtId, { courtName, schedules }]) => (
                        <Tab eventKey={courtId} title={courtName} key={courtId} className='go-schedule-tabs-content'>
                            <div className='go-schedules-blocks'>
                                {schedules.map((schedule, index) => (
                                    <ScheduleItem
                                        key={schedule.id}
                                        id={schedule.id}  // Agregar esta línea
                                        schedule={schedule}
                                        index={index}
                                        schedules={schedules}
                                        setHoveredItemId={setHoveredItemId}
                                        handleOpenModal={handleOpenModal}
                                        selectedDuration={selectedDuration}
                                        hoveredItemId={hoveredItemId}
                                        handleOpenSettings={handleOpenSettings}
                                    />
                                ))}
                            </div>
                        </Tab>
                    ))}
                </Tabs>
            ) : (
                <Row className='justify-content-center'>
                    {Object.entries(schedulesByCourt).map(([courtId, { courtName, schedules }]) => (
                        <Col key={courtId} className='col-md-5 col-12'>
                            <Row className='pb-3 go-sticky-content go-sticky-content--courts'>
                                <Col xs={10} md={6}>
                                    <div className='go-schedules-court__name'>
                                        <img src='/assets/icons/court.svg' alt={`Court`} className='pe-2' /> {courtName}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='go-schedules-row'>
                                <Col xs="12">
                                    <div className='go-schedules-blocks'>
                                        {schedules.map((schedule, index) => (
                                            <ScheduleItem
                                                key={schedule.id}
                                                id={schedule.id}  // Agregar esta línea
                                                schedule={schedule}
                                                index={index}
                                                schedules={schedules}
                                                setHoveredItemId={setHoveredItemId}
                                                handleOpenModal={handleOpenModal}
                                                selectedDuration={selectedDuration}
                                                hoveredItemId={hoveredItemId}
                                                handleOpenSettings={handleOpenSettings}
                                            />
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
};


export default ScheduleList;
